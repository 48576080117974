import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

if (!localStorage.getItem('update-plan-ask-flag')) {
    localStorage.setItem('update-plan-ask-flag', 'true')
}

if (!localStorage.getItem('update-plan-ask-action')) {
    localStorage.setItem('update-plan-ask-action', '')
}

if (!localStorage.getItem('remember-user')) {
    localStorage.setItem('remember-user', 'false')
}

if (window.location.pathname.includes('sign-up')) {
    localStorage.setItem('isFree', 'true')
}

ReactDOM.render(<App />, document.getElementById('root'));
