import { postSupport } from '../../../services/api/supportService/supportService';
import { errorHandler, successHandler } from '../../../components/toastify/redux/notifyOperations';
import { supportActions } from './profileAction';

export const setSupport = (data, onSuccess) => dispatch => {
    postSupport(data)
    .then(() => {
        onSuccess && onSuccess()
        dispatch(successHandler('The message has been sent'))
        dispatch(supportActions({...data}))
    })
    .catch(({ message, status }) => {
        dispatch(errorHandler(message));
    })
}
