import './styles.scss';

const SelectQuestion = ({ data, register }) => {
  const { type, answers } = data;

  const getItemOptions = () =>
    answers.map(({ text, id }, index) => (
      <label
        htmlFor={`'SelectQuestion'${index}`}
        className={`select-question__label`}
        key={`'SelectQuestion'${index + id}`}
      >
        <input
          {...register(type + data.id,{ required: true })}
          type="radio"
          id={`'SelectQuestion'${index}`}
          className="select-question__label__item"
          name={type + data.id}
          value={id}
        />
        <p>{text}</p>
      </label>
    ));

  return <div className="select-question">{getItemOptions()}</div>;
};
export default SelectQuestion;
