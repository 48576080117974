const initState = Object.freeze({
    displayingDate: null,
})

export const myPlanReducer = (state = initState, {type, value}) => {
    switch (type) {
        case '[MYPLAN] SET_DISPLAY_DATE':
            return {
                ...state, displayingDate: value
            }
        default:
            return state
    }
}
