import './styles.scss';

const ChekBoxQuestion = ({ data, register }) => {
  const { type, answers } = data;

  const getItem = () =>
    answers.map(({ text, id }, index) => (
      <label
        htmlFor={`'scales'${index}`}
        className="chekBox_question__label"
        key={`'scales'${index + id}`}
      >
        <input
          type="checkbox"
          id={`'scales'${index}`}
          name="id"
          className="chekBox_question__label__input"
          {...register(type +'/'+ id)}
        />
       <p>{text}</p>
      </label>
    ));
  return <div className={`chekBox_question ${answers.length <= 4 ?  'chekBox_question_small' : ''}`}>{getItem()}</div>;
};
export default ChekBoxQuestion;
