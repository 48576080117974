import './styles.scss';
import {useEffect, useState} from "react";

const IdealHeightWeightQuestion = ({data}) => {
    const [input, setInputValue] = useState('')

    const changeWeightHandler = ({target: {value}}) => {

        if (value.match(/^([1-9][0-9]?[0-9]?)?$/)) {
            setInputValue(value)
        }
    }

    useEffect(() => {
        localStorage.setItem('idealw', input)
    }, [input])

    return (
        <div className={"ideal-weight"}>
            <div className={"ideal-weight-input-wrapper"}>
                <span>lbs</span>
                <input required={true} value={input} onChange={changeWeightHandler} type={'number'} placeholder="0"/>
            </div>
        </div>
    )
}

export default IdealHeightWeightQuestion
