import {
  finishLoaderQuestion,
  setActiveQuestion, setAnswer,
  setPercentQuestion,
  setQuestionList,
  startLoaderQuestion,
} from './questionActions';
import { getQuiz, postQuiz } from '../../../services/api/quizService/quizService';
import { errorHandler } from '../../../components/toastify/redux/notifyOperations';

export const getQuestionList = () => dispatch => {
  getQuiz()
    .then(({ items = [] }) => {
      const questionList = items.map((item, index) => {
        item.index = index;
        return item;
      });
      dispatch(setQuestionList({ questionListLength: questionList.length - 1, questionList: questionList }));
      dispatch(selectActiveQuestion());
    })
    .catch(({ message, status }) => {
      dispatch(errorHandler(message));
    })
    .finally();
};

export const countPercentProgress = () => (dispatch, store) => {
  const { questionList = [] } = store().question;
  const finisQuestion = questionList.reduce((acum, item) => {
    if (item.status) {
      return acum + 1;
    }
    return acum;
  }, 0);
  dispatch(setPercentQuestion((questionList.length / 100) * finisQuestion));
};
export const selectActiveQuestion = finisQuestion => (dispatch, store) => {
  dispatch(countPercentProgress());
  const { questionList = [] } = store().question;
  const activeQuestion = questionList.find(({ status }) => !status);
  if (finisQuestion) {
    dispatch(setActiveQuestion(finisQuestion));
  } else if (activeQuestion) {
    dispatch(setActiveQuestion(activeQuestion));
  } else {
    dispatch(finishQuestion());
  }
  dispatch(finishLoaderQuestion());
};
export const postQuestions = data => dispatch => {
  dispatch(startLoaderQuestion());
  dispatch(editStatusQuestionList(data)); //  метод в post Questions in .then
};
export const editStatusQuestionList = data => (dispatch, store) => {
  const questionList = store().question.questionList;
  const newList = questionList.map(item => {
    if (item.index === data.index) {
      item.status = true;
      return item;
    }
    return item;
  });
  dispatch(setQuestionList(newList));
  dispatch(selectActiveQuestion());
};
export const finishQuestion = () => dispatch => {
  dispatch(selectActiveQuestion({ type: 'pereparingPlan' }));
  dispatch(postAnswer());
};
export const saveAnswer = (item) => (dispatch, store) => {
  const { answerList } = store().question;
  dispatch(setAnswer(answerList.concat(item)));
};

export const postAnswer = () => (dispatch, store) => {

  const { answerList } = store().question;
  postQuiz({ answers: answerList }).then((item) => {
    localStorage.setItem('question', 'done');

    setTimeout(() => {
      dispatch(selectActiveQuestion({ type: 'FinishPage' }));
    }, 1000);
  }).catch(({ message }) => {
    setTimeout(() => {
      dispatch(selectActiveQuestion({ type: 'FinishPage' }));
    }, 1000);
    dispatch(errorHandler(message));
  });
};
