import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {withTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import {Link, useNavigate} from 'react-router-dom';

import {Button, Input, CheckBox} from '../../../../components/ui-core';
import routerBook from '../../../../routes/routerBook';
import {loginSchema} from './login.schema';

import './styles.scss';

import {useDispatch} from 'react-redux';
import logo from "../../../../assets/images/header/logo.svg";
import {authorizeUser} from "../../redux/authenticationOperations";
import {errorHandler} from "../../../../components/toastify/redux/notifyOperations";
import { withDefaultUser } from "../../../../hocs/withDefaultUser";

const Login = ({t}) => {
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: {errors, isValid},
    } = useForm({
        mode: 'onChange',
        defaultValues: {},
        resolver: yupResolver(loginSchema),
    });

    const dispatch = useDispatch();

    useEffect(() => {
        const subscription = watch(({email}, {name}) => {
            if (name === 'email' && email.length && email.includes(' ')) {
                setValue('email', email.replace(' ', ''));
            }
        });
        return () => subscription.unsubscribe();
    }, [setValue, watch]);

    const handleFormSubmit = data => {
        let _data = data

        if (_data.remember) {
            localStorage.setItem('remember-user', 'true')
        }

        delete _data.remember;

        dispatch(authorizeUser(_data, null, ({message}) => {
            dispatch(errorHandler(message))
        }));
    };

    return (
        <div className='login-page'>

            <img className={'login-page-logo'} src={logo} alt={'levl_logo'}/>

            <form onSubmit={handleSubmit(handleFormSubmit)}>

                <span className='login-page__title'>{t('login_title')}</span>

                <Input inputChangeOptions={register('email')} errors={errors?.email?.message}
                       options={{
                           placeholderText: `${t('login_email_placeholder')}`,
                           placeholderIcon: 'email',
                       }}
                />

                <Input inputChangeOptions={register('password')} errors={errors?.password?.message}
                       options={{
                           placeholderText: `${t('login_password_placeholder')}`,
                           placeholderIcon: 'password',
                           type: 'password',
                       }}
                />

                <Link className="login-page__forgot-password" to={routerBook.forgotPassword}>
                    {t('login_forgot_password')}
                </Link>

                <div className='login-page__remember-me'>

                   {/* <CheckBox inputChangeOptions={register('remember')}/>

                    <span className='login-page__remember-me__title'>{t('login_remember_me')}</span>*/}

                </div>

                <Button className={'login-page_login-button'} disabled={!isValid}
                        options={{text: `${t('login_button')}`, type: 'submit'}}
                />

                <div className='login-page__to-registration'>

                    <span className='login-page__to-registration-title'>{t('login_to_registration')}</span>

                    <Link className='login-page__to-registration-btn' to={routerBook.registration}>
                        {t('registration_button')}
                    </Link>

                </div>

            </form>

        </div>
    );
};
export default withDefaultUser(withTranslation()(Login));
