import {withTranslation} from "react-i18next";
import {useState} from "react";
import arrow from '../../../assets/images/vectors/arrow-grey.svg'
import Day from './day';

const Week = ({daysList, index, currentWeek, selectWorkoutToDo}) => {
    const [isOpen, setIsOpen] = useState(currentWeek === index)

    return (
        <div className={`${isOpen ? 'view_plan__open' : ''} view_plan__weeks_list__week`} key={index}>
            <div onClick={setIsOpen.bind(null, !isOpen)} className='view_plan__weeks_list__week_header'>
                <p>Week {index + 1}</p>
                <img className={`${isOpen ? 'view_plan__open' : ''}`} src={arrow} alt={'arrow'}/>
            </div>
            {isOpen && (
                <div className={`view_plan__weeks_list__week__days_list`}>
                    {daysList?.map((day) => <Day selectWorkoutToDo={selectWorkoutToDo} dayData={day}/>)}
                </div>
            )}

        </div>
    )
}

export default withTranslation()(Week);
