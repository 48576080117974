import { useEffect, useState } from 'react';

import './styles.scss';


const Loader = () => {
	const [isShowLoader, setIsShowLoader] = useState(false)


	useEffect(() => {
		const timeoutID = setTimeout(() => {
			setIsShowLoader(true)
		}, 500)
		return () => clearTimeout(timeoutID)
	}, [])

	return (
		<div className='player-loader__actions-blocking-container'>
			{isShowLoader &&
				<div className='player-loader__backdrop'>
					<div className='player-loader__container'>
						<div className="player-loader__dot-flashing"></div>
					</div>
				</div>
			}
		</div>
	);
};


export default Loader;