import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { useEffect, useState } from 'react';
import {
    getCurrentProgress,
    getWeekData,
    getWeeksData,
    updatePlanFromDay,
} from '../../services/api/planService/planService';
import { withAuthUser } from '../../hocs/withAuthUser';
import { PlayerModal } from '../workouts/player/playerModal';
import { changeDifficulty, getWorkoutById } from '../../services/api/workoutsService/workoutsService';
import { errorHandler } from '../../components/toastify/redux/notifyOperations';
import { useDispatch, useSelector } from 'react-redux';
import Week from './components/week';
import { isPlanIsCustom } from '../../utils/isPlanIsCustom';
import { openModal } from '../../components/modal/redux/modalOperations';
import constModal from '../../components/modal/types';
import modalType from '../../components/modal/types';

const reloadTimeOut = 1500;

const ViewPlan = ({ t }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data } = useSelector(store => store.authState);
    const [weekData, setWeekData] = useState({});

    const [preview, setPreview] = useState(false);
    const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
    const [workoutData, setWorkoutData] = useState(null);
    const [weeksList, setWeekList] = useState(null);
    const [progress, setProgress] = useState([]);
    const [showRatePopup, setShowRatePopup] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);

    const actionsHandler = (action) => {
        switch (action) {
            case 'INCREASE':
            case 'DECREASE':
                changeDifficulty(action)
                    .then(r => setTimeout(() => {
                        window.location.reload();
                    }, reloadTimeOut))
                    .catch(error => dispatch(errorHandler(error)));
                break;
            case 'SHOW_INCREASE':
                dispatch(
                    openModal({
                        type: modalType.DEFAULT_MODAL,
                        data: {
                            callback_button_ok: () => changeDifficulty('INCREASE')
                                .then(r => setTimeout(() => {
                                    window.location.reload();
                                }, reloadTimeOut))
                                .catch(error => dispatch(errorHandler(error))),
                            callback_button_cancel: () => {
                                setTimeout(() => {
                                    window.location.reload();
                                }, reloadTimeOut);
                            },
                            title: 'Would you like to increase the difficulty for future workouts?',
                            button_ok_text: 'Yes',
                            button_cancel_text: 'No',
                        },
                    }),
                );
                break;
            case 'SHOW_DECREASE':
                dispatch(
                    openModal({
                        type: modalType.DEFAULT_MODAL,
                        data: {
                            callback_button_ok: () => changeDifficulty('DECREASE')
                                .then(r => {
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, reloadTimeOut);
                                })
                                .catch(error => dispatch(errorHandler(error))),
                            callback_button_cancel: () => {
                                setTimeout(() => {
                                    window.location.reload();
                                }, reloadTimeOut);
                            },
                            title: 'Would you like to decrease the difficulty for future workouts?',
                            button_ok_text: 'Yes',
                            button_cancel_text: 'No',
                        },
                    }),
                );
                break;
            default:
                setTimeout(() => {
                    window.location.reload();
                }, reloadTimeOut);
        }
    };

    useEffect(() => {
        getWeekData().then(response => {
            setWeekData({
                ...response,
            });
        }).catch(error => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (showRatePopup && !isPlayerModalOpen) {
            dispatch(openModal({
                type: constModal.RATE_MODAL,
                data: {
                    callback: actionsHandler,
                    day: selectedDay.day,
                    workoutId: workoutData._id,
                },
            }));
        }
    }, [showRatePopup, isPlayerModalOpen]);

    useEffect(() => {
        getCurrentProgress().then(response => {
            setProgress(response);
        }).catch(error => console.log(error));
    }, []);

    useEffect(() => {
        getWeeksData().then(data => setWeekList(data)).catch(console.log);
    }, [showRatePopup, isPlayerModalOpen]);

    const handleOpenModalWindow = (selectedDay) => {
        getWorkoutById(selectedDay.workoutId).then(res => {
            setWorkoutData(res);
            setSelectedDay(selectedDay);
            if (selectedDay.status === 'PASSED')
                setPreview(true);
            setIsPlayerModalOpen(true);
        }).catch(error => dispatch(errorHandler(error.message)));
    };

    const updatePlanHandler = (day) => {
        updatePlanFromDay(day).then(() => {
            sessionStorage.clear()
            navigate('/dashboard?action=playAfterRefresh&workoutType=main');
        }).catch((error) => dispatch(errorHandler(error)));
    };

    const openUpdatePlanModalHandler = (selectedDay) => {
        dispatch(openModal({
            type: constModal.BEFORE_VIDEO_MODAL,
            data: {
                currentDay: weekData.currentDay,
                selectedDay: selectedDay.day,
                _continue: () => {
                    setPreview(true)
                    handleOpenModalWindow(selectedDay);
                },
                _updatePlan: () => updatePlanHandler(selectedDay.day),
            },
        }));
    };

    const selectWorkoutToDo = (selectedDay, preview = false) => {
        const askFlag = localStorage.getItem('update-plan-ask-flag');
        const askAction = localStorage.getItem('update-plan-ask-action');

        if (!(+weekData.currentDay === +selectedDay.day)) {
            if (weekData.currentDay < selectedDay.day) {
                if (askFlag === 'false') {
                    switch (askAction) {
                        case 'continue':
                            setPreview(true)
                            handleOpenModalWindow(selectedDay);
                            break;
                        case 'updatePlan':
                            updatePlanHandler(selectedDay.day);
                            break;
                        default:
                            openUpdatePlanModalHandler(selectedDay);
                            break;
                    }
                } else openUpdatePlanModalHandler(selectedDay);
            } else {
                handleOpenModalWindow(selectedDay);
            }
        } else {
            sessionStorage.clear()
            navigate('/dashboard?action=playAfterRefresh&workoutType=main');
        }

        setPreview(false)
    };

    return (
        <>
            {isPlayerModalOpen && <PlayerModal
                closeModal={setIsPlayerModalOpen.bind(null, false)}
                id={workoutData._id}
                title={workoutData.title}
                link={workoutData.video.link}
                parts={workoutData.video.parts}
                progressId={progress[0]?._id}
                setShowRatePopup={setShowRatePopup}
                currentDay={selectedDay}
                preview={preview}
                closeModalButtonText={'Close workout'}
            />}
            <div className={'content-container'}>
                <div className={'view_plan'}>
                    <div className='view_plan__header'>
                        <b>{isPlanIsCustom(weeksList?.description, data?.name) ? 'Custom Plan' : weeksList?.title}</b>
                        <div className={'view_plan__header__description'}>
                            <small>{weeksList?.description || 'Description'}</small>
                        </div>
                    </div>
                    <div className={'view_plan__weeks_list'}>
                        {weeksList?.weeksPlan?.map((week, index) => <Week selectWorkoutToDo={selectWorkoutToDo}
                                                                          currentWeek={weeksList.indexCurrentWeek}
                                                                          daysList={week} index={index} />)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default withAuthUser(withTranslation()(ViewPlan));
