import { Link } from 'react-router-dom';
import './noSubscription.scss'
import routerBook from '../../routes/routerBook';
import logo from '../../assets/images/loader/logo.svg'
import { withAuthUser } from '../../hocs/withAuthUser';

export const NoSubscription = withAuthUser(() => {
    return (
        <div className={'no-subscription'}>
            <span/>
            <div className={'no-subscription__content'}>
                <img src={logo} alt={'levl-logo'}/>
                <h2>You don't have active subscriptions.<br/>If you want to use all the features, you need to <Link
                    to={routerBook.account}>subscribe</Link></h2>
            </div>
            <span/>
        </div>
    );
})
