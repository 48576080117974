import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';

import WeekNavigation from '../../components/weekNavigation/weekNavigation';
import HeaderInfo from './component/headerInfo/headerInfo';
import ActiveDay from './component/days/activeDay/activeDay';
import CompletedDay from './component/days/completedDay/completedDay';

import './styles.scss';
import AnswerToQuestions from './component/answerToQuestions/answerToQuestions';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProgress, getWeekData } from '../../services/api/planService/planService';
import DayOff from './component/days/dayOff/dayOff';
import dayjs from 'dayjs';
import { withAuthUser } from '../../hocs/withAuthUser';
import { Loader } from '../../components/loader/loader';
import { openModal } from '../../components/modal/redux/modalOperations';
import constModal from '../../components/modal/types';
import { closeModal } from '../../components/modal/redux/modalActions';
import { getHintsStatus, putHintsStatus } from '../../services/api/profileService/profileService';
import { Navigate, useNavigate } from 'react-router-dom';
import routerBook from '../../routes/routerBook';
import { errorHandler } from '../../components/toastify/redux/notifyOperations';

const MyPlan = ({ t }) => {
    const navigate = useNavigate();
    const { data } = useSelector(store => store.authState);
    const dispatch = useDispatch();
    const isQuizPassed = useMemo(() => data?.quizPassed || data?.userAnswers?.length, [data]);
    const [weekData, setWeekData] = useState({});
    const [isWeekDataLoading, setIsWeekDataLoading] = useState(true);
    const [hintsStatus, setHintsStatus] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isPlayerClosed, setIsPlayerClosed] = useState(false);
    const [progress, setProgress] = useState([]);
    const [selectedDay, setSelectedDay] = useState({});
    const [completedDayId, setCompletedDayId] = useState(null);
    const [completedWorkoutType, setCompletedWorkoutType] = useState();
    const [showRatePopup, setShowRatePopup] = useState(false);
    const [isUserIdle, setIsUserIdle] = useState(false);
    const [needToUpdate, setNeedToUpdate] = useState(false);
    const [watchDataAction, setWatchDataAction] = useState(null);

    const idleTimeout = useRef(null);

    useEffect(() => {
        if (!isUserIdle || needToUpdate) {
            getCurrentProgress().then(response => {
                setProgress(response);
            }).catch(error => console.log(error)).finally(() => {
                setNeedToUpdate(false);
            });
        }
    }, [isUserIdle, needToUpdate]);

    useEffect(() => {
        if (needToUpdate) {
            setIsCompleted(false);
            setIsPlayerClosed(false);
            setShowRatePopup(false);
            setCompletedDayId(null);
            setNeedToUpdate(false);
            dispatch(closeModal())
        }
    }, [needToUpdate]);

    useEffect(() => {
        if (!isUserIdle || needToUpdate) {
            getWeekData().then(response => {
                setWeekData({
                    ...response,
                });
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setIsWeekDataLoading(false);
                setNeedToUpdate(false);
            });
        }
    }, [isUserIdle, needToUpdate]);

    const onMouseMove = () => {
        if (isPlayerClosed) {
            if (isUserIdle) setIsUserIdle(false);

            if (idleTimeout?.current) {
                clearTimeout(idleTimeout.current);
            }

            idleTimeout.current = setTimeout(() => {
                setIsUserIdle(true);
            }, 30000);
        }
    };

    useEffect(() => {
        if (!isPlayerClosed && idleTimeout?.current) {
            clearTimeout(idleTimeout.current);
        }
    }, [isPlayerClosed, idleTimeout]);


    useEffect(() => {
        window.scrollTo(0, 0);
        window.document.addEventListener('mousemove', onMouseMove);

        return () => window.document.removeEventListener('mousemove', onMouseMove);
    }, []);

    useEffect(() => {
        getHintsStatus().then(data => {
            setHintsStatus({
                weekData: data.weekData,
                player: data.player,
                altWorkout: data.altWorkout,
                viewPlan: data.viewPlan,
                ratePopup: data.ratePopup,
            });
        });
    }, []);

    const getDetailedDayInfo = () => {
        if (progress.length) {
            const [planInfo] = progress.filter(el => {
                return el.planId === weekData.planId;
            });

            const [day] = planInfo?.days.filter(day => {
                return day.day === selectedDay.day;
            });

            return day;
        }
    };

    const getCurrentProgressInfo = () => {
        if (progress.length) {
            const [planInfo] = progress.filter(el => {
                return el.planId === weekData.planId;
            });

            return planInfo
        }

        return undefined
    };

    const hintsHandler = (hintName) => {
        if (isQuizPassed || weekData?.planId) {
            let _newStatus = { ...hintsStatus };

            Object.keys(_newStatus).forEach((key, index) => {
                if (key === hintName) _newStatus[key] = false;
                if (Object.keys(_newStatus)[index - 1] === hintName) _newStatus[key] = true;
            });

            putHintsStatus({ ..._newStatus }).then(data => {
                setHintsStatus({
                    weekData: data.weekData,
                    player: data.player,
                    altWorkout: data.altWorkout,
                    viewPlan: data.viewPlan,
                    ratePopup: data.ratePopup,
                });
            });
        }
    };

    const handleRedirectToCurrentDay = useCallback(() => {

        if (isCompleted && isPlayerClosed && (completedDayId === selectedDay.day)) {
            return <CompletedDay watchDataAction={watchDataAction} setNeedToUpdate={setNeedToUpdate}
                                 setShowRatePopup={setShowRatePopup} showRatePopup={showRatePopup}
                                 completedWorkoutType={completedWorkoutType}
                                 selectedDay={selectedDay} showStats={false} />;
        }

        if (isWeekDataLoading) {
            return <Loader />;
        }

        if (isQuizPassed || weekData?.planId) {
            switch (selectedDay.status) {
                case 'DAY_OF':
                    return <DayOff />;
                case 'SKIPPED':
                case 'NO_PASSED':
                    return <ActiveDay setIsPlayerClosed={setIsPlayerClosed} isCompleted={isCompleted}
                                      setIsCompleted={setIsCompleted} showViewHint={hintsStatus?.viewPlan}
                                      showAltHint={hintsStatus?.altWorkout} showPlayerHint={hintsStatus?.player}
                                      hintHandler={hintsHandler} selectedDay={selectedDay} weekData={weekData}
                                      showWeekHint={hintsStatus?.weekData}
                                      setCompletedDayId={setCompletedDayId}
                                      setCompletedWorkoutType={setCompletedWorkoutType}
                                      setShowRatePopup={setShowRatePopup}
                                      setWatchDataAction={setWatchDataAction}
                                      progress={getCurrentProgressInfo()}

                    />;
                case 'PASSED':
                    return <CompletedDay setIsPlayerModalOpen={setIsPlayerClosed} planId={weekData.planId} progress={progress} hintHandler={hintsHandler}
                                         setNeedToUpdate={setNeedToUpdate}
                                         showRatePopUpHint={hintsStatus?.ratePopup}
                                         detailedDayInfo={getDetailedDayInfo()} selectedDay={selectedDay} />;
                default:
                    dispatch(errorHandler('You are not bound to any plan'));
                    navigate(routerBook.challenges);
            }
        } else {
            return <AnswerToQuestions />;
        }
    }, [setIsPlayerClosed, isWeekDataLoading, isCompleted, setIsCompleted, hintsStatus, hintsHandler, selectedDay, weekData, isPlayerClosed]);

    const getDate = () => {
        switch (selectedDay.status) {
            case 'DAY_OF':
            case 'SKIPPED':
            case 'NO_PASSED':
                return dayjs(new Date(Date.now())).format('MMMM D');
            case 'PASSED':
                return dayjs(new Date(selectedDay?.completedDate)).format('MMMM D');
            default:

        }
    };

    useEffect(() => {
        if (selectedDay?.day) {
            sessionStorage.setItem('selectedDay', JSON.stringify(selectedDay));
            sessionStorage.setItem('planId', JSON.stringify(weekData?.planId));
        }
    }, [selectedDay, isCompleted]);

    useEffect(() => {
        let [element] = document.getElementsByClassName('hint');
        if (element) {
            window.scrollTo(0, element.getBoundingClientRect().y - element.getBoundingClientRect().height / 2);
        } else window.scrollTo(0, 0);
    }, [hintsStatus]);

    useEffect(() => {
        if (weekData?.weekPlan) {

            let dayToSetup = {};

            const _savedSelectedDay = JSON.parse(sessionStorage.getItem('selectedDay'));

            const _planId = JSON.parse(sessionStorage.getItem('planId'));

            if (_planId === weekData.planId) {
                if (_savedSelectedDay?.day) {
                    const [_dayData] = weekData?.weekPlan?.filter(day => day.day === _savedSelectedDay?.day);
                    dayToSetup = { ..._dayData };
                }

                if (dayToSetup?.day) {
                    setSelectedDay({ ...dayToSetup });
                } else {
                    const [_dayData] = weekData?.weekPlan?.filter(day => day.currentDay);
                    setSelectedDay({ ..._dayData });
                }
            } else {
                const [_dayData] = weekData?.weekPlan?.filter(day => day.currentDay);
                setSelectedDay({ ..._dayData });
            }


        }
    }, [weekData]);


    return (
        <div className={'content-container'}>
            <div className='my-plan__date'>
                <p>{getDate()}</p>{' '}
                <span className='my-plan__date_today'>{t('header_tertiary_today')}</span>
            </div>
            <div className='my-plan'>
                {selectedDay.status && (
                    <div className='my-plan__header'>
                        <HeaderInfo description={selectedDay.status && '30 Day Challenge'} userData={data}
                                    activeDay={selectedDay} isCompleted={isCompleted} />
                        {isQuizPassed || weekData?.planId ? (
                            <WeekNavigation showHint={hintsStatus?.weekData}
                                            hintHandler={hintsHandler.bind(null, 'weekData')} selectDay={setSelectedDay}
                                            weekData={weekData} activeData={selectedDay} />
                        ) : null}
                    </div>
                )}
                <div className={'my-plan__day_content'}>
                    {handleRedirectToCurrentDay()}
                </div>
            </div>
        </div>
    );
};

export default withAuthUser(withTranslation()(MyPlan));
