import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useLocation} from 'react-router-dom';
import logOut from '../../assets/images/profile/logOut.svg';
import routerBook from '../../routes/routerBook';
import AccountMenu from './component/accountMenu/accountMenu';
import Account from './component/account/account';
import ContactUs from './component/contactUs/contactUs';
import './styles.scss';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser} from '../authentication/redux/authenticationOperations';
import {withTranslation} from 'react-i18next';
import AboutMe from './component/aboutMe/aboutMe';
import {withAuthUser} from '../../hocs/withAuthUser';
import Device from './component/device/device';
import {getLocalAccessToken} from '../../services/api/tokenService';

const getDeviseID = () => {
    const a = localStorage.getItem('DeviseID');
    if (a) {
        return a;
    }
    const newDeviseId = Math.random() * 10e16 + '';
    localStorage.setItem('DeviseID', newDeviseId);
    return newDeviseId;
};

const Profile = ({t}) => {
    const [avatar, setAvatar] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();

    const {data} = useSelector(store => store.authState);

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({
                result: reader.result,
                reader: reader,
            });
            reader.onerror = error => reject(error);
        });
    };

    const avatarInputHandler = ({target: {files: [image]}}) => {
        setAvatar(image);
    };

    const sendUpdateAvatarRequest = () => {
        let formData = new FormData();

        const link = process.env.REACT_APP_ENV === 'production' ?
            'https://backend.levlmethod.com/api/v1' :
                'https://backend-pre-dev.lvlmethod.lampawork.com/api/v1'

        formData.append('avatar', avatar);
        axios.put(`${link}/user/me`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getLocalAccessToken(),
                deviceId: getDeviseID(),
                platform: 'WEB'
            },
        }).then(res => window.location.reload()).catch(error => console.log(error));
    };

    const AvatarSettings = () => {
        if (!avatar) return null;

        return (
            <div className={'profile__left__top_avatar__settings'}>
                <button onClick={sendUpdateAvatarRequest}>Save</button>
                <small onClick={setAvatar.bind(null, null)}>Cancel</small>
            </div>
        );
    };

    const Avatar = () => {
        if (avatar) return <img src={URL.createObjectURL(avatar)} alt='avatar'/>;

        if (data?.avatar) return <img src={data?.avatarUrl} alt='avatar'/>;

        return <span>{data?.name[0]}</span>;
    };
    const getContent = () => {
        switch (location.pathname) {
            case routerBook.account:
                return <Account/>;
            case routerBook.contactUs:
                return <ContactUs/>;
            case routerBook.aboutMe:
                return <AboutMe/>;
            case routerBook.device:
                return <Device/>;
            default: {
                return <AccountMenu/>;
            }
        }
    };
    return (
        <div className='profile'>
            <div className='profile__left'>
                <div className='profile__left__top'>
                    <div className='profile__left__top_avatar'>
                        <input onChange={avatarInputHandler} type='file' id='avatar' name='avatar'

                               accept='image/png, image/jpeg'/>
                        <Avatar/>
                    </div>
                    <AvatarSettings/>
                    <p className='profile__left__top_name'>{data?.name}</p>
                    <p className='profile__left__top_email'>{data?.email}</p>
                </div>
                <div className='profile__left-button' onClick={() => {
                    dispatch(logoutUser());
                }}>
                    <Link to={routerBook.main}>
                        <p>{t('profile_logout')}</p>
                        <img src={logOut} alt='logOut'/>
                    </Link>
                </div>
            </div>
            <div className='profile__right'>{getContent()}</div>
        </div>
    );
};
export default withAuthUser(withTranslation()(Profile));
