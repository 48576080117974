const getDeviseID = () => {
    const a = localStorage.getItem('DeviseID');
    if (a) {
        return a;
    }
    const newDeviseId = Math.random() * 10e16 + '';
    localStorage.setItem('DeviseID', newDeviseId);
    return newDeviseId;
};
const jsonHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
    'X-TIMEZONE': -new Date().getTimezoneOffset() / 60,
    deviceId: getDeviseID(),
    platform: 'WEB',
};

const formDataHeaders = {
    accept: '*/*',
    ContentType: 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
};

const defautCustomOptions = {
    includeAuthHeader: true,
    includeCredentials: true,
};

export async function post(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
) {
    const request = await fetchEnriched(
        'POST',
        path,
        JSON.stringify(body),
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );
    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        } else {
            const type = request.headers.get('Content-Type');

            //temporary solution, it is necessary to change the returned text
            if (request.url.includes('send-confirm-code')) {
                return request;
            }

            switch (type) {
                case 'application/json; charset=utf-8':
                    return request.json();
                case 'text/plain; charset=utf-8':
                    return request;
                default:
                    return request;
            }
        }
    } catch (err) {
        console.log('api err', err);
        throw err;
    }
}

export async function postFormData(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
) {
    const request = await fetchEnriched(
        'POST',
        path,
        body,
        getHeaders(formDataHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }
        return request.json();
    } catch (err) {
        throw err;
    }
}

export async function putFormData(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
) {
    const request = await fetchEnriched(
        'PUT',
        path,
        body,
        getHeaders(formDataHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        } else {
            if (typeof request === 'string') {
                return request.json();
            } else {
                return request.json();
            }
        }
    } catch (err) {
        throw err;
    }
}

export async function get(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
) {
    const request = await fetchEnriched(
        'GET',
        path,
        null,
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }

        return request.json();
    } catch (err) {
        throw err;
    }
}

export async function patch(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
) {
    const request = await fetchEnriched(
        'PATCH',
        path,
        JSON.stringify(body),
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }

        try {
            return await request.json()
        } catch (e) {
            return request
        }

    } catch (err) {
        throw err;
    }
}

export async function put(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
    isConvertToJSON = true,

) {
    const request = await fetchEnriched(
        'PUT',
        path,
        isConvertToJSON ? JSON.stringify(body) : body,
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );

    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }
        return request.json();
    } catch (err) {
        throw err;
    }
}

export async function remove(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
) {
    const request = await fetchEnriched(
        'DELETE',
        path,
        JSON.stringify(body),
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );
    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }
        return true;
    } catch (err) {
        throw err;
    }
}

export async function removeWithResponse(
    path,
    body = {},
    headers = {},
    options = {},
    customOptions = defautCustomOptions,
) {
    const request = await fetchEnriched(
        'DELETE',
        path,
        null,
        getHeaders(jsonHeaders, headers),
        options,
        customOptions,
    );
    try {
        if (!request.ok) {
            const errRes = await request.json();
            const err = Error(errRes.error);
            err.status = request.status;
            throw err;
        }
        return request.json();
    } catch (err) {
        throw err;
    }
}

function fetchEnriched(method, path, body, headers, options) {
    const endpointUrl = getApiUrl(path);
    addAuthHeader(headers);
    return fetch(endpointUrl, {
        method: method,
        credentials: 'include',
        headers,
        body: body,
        ...options,
    });
}

export function getApiUrl(path) {
    const environment = process.env.REACT_APP_ENV;

    const developmentLink = 'https://backend-pre-dev.lvlmethod.lampawork.com/api/v1';
    const productionLink = 'https://backend.levlmethod.com/api/v1';
    const baseLink =
        environment === 'production' ? productionLink : developmentLink;

    return baseLink + path;
}

function getHeaders(defaultHeaders, headers) {
    return {
        ...defaultHeaders,
        ...headers,
    };
}

function addAuthHeader(headers) {
    const data = localStorage.getItem('auth');
    if (data !== null) {
        const {accessToken} = JSON.parse(data);
        if (accessToken) {
            headers['Authorization'] = 'Bearer ' + accessToken;
        }
    }
}

export async function mockResponse(response) {
    return response;
}
