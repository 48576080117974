import React, { useRef } from 'react';
import Slider from 'react-slick';
import { defaultConfig } from './default_config';
import './styles.scss';
import { withTranslation } from 'react-i18next';

const SliderPayment = ({ t }) => {
    const ref = useRef();
    const _next = () => {
        ref.current.slickNext();
    };
    const _previous = () => {
        ref.current.slickPrev();
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplaySpeed: 500000,
        autoplay: true,
        nextArrow: <div className='home-slider-not-show' />,
        prevArrow: <div className='home-slider-not-show' />,
        appendDots: () => <div />,
        afterChange: current => {
        },
    };

    const getListSlides = () => {
        return defaultConfig.map(({ name, city, comment }, index) => (
            <div className='payment-slider__item' key={name + index}>
                <div>
                    <div className='payment-slider__item-comment-item'>
                        <div
                            className='payment-slider__item__key payment-slider__item__key-left'
                            onClick={_previous}
                        />

                        <div className='payment-slider__item__container'>
                            <p className='payment-slider__item-name'>{name}</p>
                            <p> {city}</p>
                        </div>
                        <p className='payment-slider__item-comment'>{comment}</p>
                        <div
                            className='payment-slider__item__key payment-slider__item__key-right'
                            onClick={_next}
                        />
                    </div>
                </div>
            </div>
        ));
    };
    return (
        <div className='payment-slider'>
            <h3> Start Your 30-Day Free Trial </h3>
            <h4>You will have unlimited access to trainer-led workouts!</h4>
            <Slider {...settings} ref={ref}>
                {getListSlides()}
            </Slider>
        </div>
    );
};

export default withTranslation()(SliderPayment);
