import { Navigate } from 'react-router-dom';

import Layout from '../components/layout/layout';
import Landing from '../pages/landing/landing';
import Login from '../pages/authentication/component/login/login';
import Registration from '../pages/authentication/component/registration/registration';
import CheckCode from '../pages/authentication/component/checkCode/checkCode';
import ResetPassword from '../pages/authentication/component/resetPassword/resetPassword';
import ForgotPassword from '../pages/authentication/component/forgotPassword/forgotPassword';
import PaymentPage from '../pages/paymentPage/paymentPage';
import ViewPlan from '../pages/viewPlan/viewPlan';
import Workouts from '../pages/workouts/workouts';
import Challenges from '../pages/challenges/challenges';
import ChallengesItem from '../pages/challengesItem/challengesItem';
import Saved from '../pages/saved/saved';
import Question from '../pages/question/question';
import MyPlan from '../pages/myPlan/myPlan';
import Profile from '../pages/profile/profile';
import { NoSubscription } from '../pages/noSubscription/noSubscription';
import Contact from '../pages/contact/contact';
import { PrivacyPolicy } from '../pages/privacy-policy/PrivacyPolicy';
import { TermsOfService } from '../pages/termsOfService/TermsOfService';

export const routerSchema = [{
    path: '/',
    element: <Layout/>,
    children: [
        {
            path: 'how-it-works',
            element: <Landing />,
        },
        {
            path: 'login',
            element: <Login />,
        },
        {
            path: 'registration',
            element: <Registration />,
        },
        {
          path: 'sign-up',
          element: <Navigate to={'/registration'}/>
        },
        {
            path: 'forgot-password',
            children: [
                {
                    path: 'check-code/:id',
                    element: <CheckCode />,
                },
                {
                    path: 'reset/:id',
                    element: <ResetPassword />,
                },
                {
                    index: true,
                    element: <ForgotPassword />,
                },
                {
                    path: '*',
                    element: <Navigate to={'/forgot-password'} replace={true}/>
                }
            ],
        },
        {
            path: 'contact',
            element: <Contact />,
        },
        {
            path: 'privacy-policy',
            element: <PrivacyPolicy />,
        },
        {
            path: 'terms-of-service',
            element: <TermsOfService />,
        },
        {
            path: 'dashboard',
            children: [
                {
                  path: 'no-sub',
                  element: <NoSubscription/>
                },
                {
                    path: 'profile',
                    children: [
                        {
                            path: 'about-me',
                            element: <Profile />,
                        },
                        {
                            path: 'account',
                            element: <Profile />,
                        },
                        {
                            path: 'contact-us',
                            element: <Profile />,
                        },
                        {
                            path: 'device',
                            element: <Profile />,
                        },
                        {
                            index: true,
                            element: <Profile />,
                        },
                        {
                            path: '*',
                            element: <Navigate to={ '/dashboard/profile'} replace={true}/>
                        },
                    ],
                },
                {
                    path: 'payment-page',
                    element: <PaymentPage />,
                },
                {
                    path: 'plan-view',
                    element: <ViewPlan />,
                },
                {
                    path: 'workouts',
                    children: [{
                        path: ':bodyPart',
                        element: <Workouts/>
                    }, {
                            index: true,
                            element: <Workouts />,
                        }
                    ]
                },
                {
                    path: 'challenges',
                    element: <Challenges />,
                },
                {
                    path: 'challenge',
                    element: <ChallengesItem />,
                },
                {
                    path: 'saved',
                    element: <Saved />,
                },
                {
                    path: 'question',
                    element: <Question />,
                },
                {
                    index: true,
                    element: <MyPlan />,
                },
                {
                    path: '*',
                    element: <Navigate to={'/dashboard'}/>
                }
            ],
        },
        {
            index: true,
            element: <Landing />,
        },
        {
            path: '*',
            element: <Navigate to={'/'}/>
        }
    ],
}];
