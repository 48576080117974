import React from 'react';

import './styles.scss';

const PercentCircle = ({textPadding = '0 10px 5px 0', percent, height = 80, width = 80, x = 5, className = '' }) => {
    return (
        <div className={`percent-circle ${className}`}>
            <span style={{padding: textPadding}} className='percent-circle__text'>{percent}%</span>
            <svg width={width} height={height} viewBox='0 0 40 40' className='percent-circle__chart color'>
                <path
                    className='percent-circle__background' d='M18 2 a 15 15 0 0 1 0 32 a 15 15 0 0 1 0 -32'
                />
                <path
                    className='percent-circle__body' strokeDasharray={`${percent}, 100`} d='M18 2 a 15 15 0 0 1 0 32 a 15 15 0 0 1 0 -32'
                />
            </svg>
        </div>
    );
};

export default PercentCircle;
