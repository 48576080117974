import {withTranslation} from 'react-i18next';
import React, {useState} from 'react';
import dayOffIcon from '../../assets/images/myPlan/dayOff.svg'
import completedDayIcon from '../../assets/images/myPlan/dayCompleted.svg'
import './styles.scss';
import { useEffect, useRef } from 'react';
import {useDispatch} from "react-redux";
import {setDisplayDate} from "../../pages/myPlan/store/actions";

const WeekNavigation = ({weekData, selectDay, activeData, t, hintHandler, showHint}) => {
    const dispatch = useDispatch()
    const navRef = useRef(null)

    useEffect(() => {
        if (navRef?.current && showHint) {
            window.scrollTo(0, navRef.current.getBoundingClientRect().y)
        }
    }, [navRef, showHint])

    const dayClickHandler = (selectedDay) => {
        if (selectedDay.status === 'PASSED') {
            dispatch(setDisplayDate(selectedDay.completedDate))
        } else dispatch(setDisplayDate(null))
        selectDay(selectedDay)
    }

    return (
        <div ref={navRef} className={`week-navigation${showHint ? ' hint week-navigation__hint' : ''}`} data-after={t('hint_week')}>
            {showHint && <span className={'next_hint'} onClick={hintHandler}>Next</span>}
            {weekData?.weekPlan?.map((day) => {

                const _dayClickHandler = () => dayClickHandler(day)


                const className = `week-navigation__item ${activeData.day === day.day ? 'selected' : ''}`

                return (
                    <div className={className} onClick={_dayClickHandler} key={day.day}>
                        <p>{day.day}</p>
                        {day.currentDay && <span/>}
                        {day.status === 'DAY_OF' && <img src={dayOffIcon} alt={'day_off_icon'}/>}
                        {day.status === 'PASSED' && <img src={completedDayIcon} alt={'completed_day_icon'}/>}
                    </div>
                )
            })}
        </div>
    );
};

export default withTranslation()(WeekNavigation);
