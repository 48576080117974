import React, {useState} from 'react';
import './styles.scss';
import video from '../../assets/video/howItWorks/v6-transcode.mp4';
import {withTranslation} from 'react-i18next';
import routerBook from '../../routes/routerBook';
import {Link, useNavigate} from 'react-router-dom';
import SecondaryHeader from '../../components/layout/headerNavigationPanel/secondaryHeader/secondaryHeader';

import fitLabelText from '../../assets/images/Landing/fit-text-label.svg';

import twb1 from '../../assets/images/Landing/three-weeks-block/1.png';
import twb2 from '../../assets/images/Landing/three-weeks-block/2.png';
import twb3 from '../../assets/images/Landing/three-weeks-block/3.png';
import twb4 from '../../assets/images/Landing/three-weeks-block/4.png';
import twb5 from '../../assets/images/Landing/three-weeks-block/5.png';

import pb1 from '../../assets/images/Landing/program-block/1.jpg';
import pb2 from '../../assets/images/Landing/program-block/2.jpg';
import pb3 from '../../assets/images/Landing/program-block/3.jpg';
import pb4 from '../../assets/images/Landing/program-block/4.jpg';
import pb5 from '../../assets/images/Landing/program-block/5.jpg';
import pb6 from '../../assets/images/Landing/program-block/6.jpg';
import pb7 from '../../assets/images/Landing/program-block/7.jpg';
import pb8 from '../../assets/images/Landing/program-block/8.jpg';

import db1 from '../../assets/images/Landing/difference-block/phone.gif';

import pbp1 from '../../assets/images/Landing/performance-block/img.gif';

import tb1 from '../../assets/images/Landing/tracker-block/img.gif';

import cb1 from '../../assets/images/Landing/coaches-block/img.png';

import pmsb1 from '../../assets/images/Landing/programs-block/basics.jpg'
import pmsb2 from '../../assets/images/Landing/programs-block/low.jpg'
import pmsb3 from '../../assets/images/Landing/programs-block/lower.jpg'
import pmsb4 from '../../assets/images/Landing/programs-block/quick.jpg'
import pmsb5 from '../../assets/images/Landing/programs-block/upper.jpg'
import pmsb6 from '../../assets/images/Landing/programs-block/waistline.jpg'

import instagramIcon from '../../assets/images/Landing/footer-block/insta.svg';
import facebookIcon from '../../assets/images/Landing/footer-block/fb.svg';
import youtubeIcon from '../../assets/images/Landing/footer-block/yt.svg';

const reviewsList = [{
    name: 'Phyllis M.',
    location: 'Denver, CO',
    title: 'Helps me recharge!',
    comment: 'I love the variety of workouts, it really helps me recharge. It is nice and refreshing to sometimes see the beach when working out.',
}, {
    name: 'Aimee S.',
    location: 'New York, NY',
    title: 'Thank You!!',
    comment: 'This program is awesome!! I\'ve tried many fitness classes and normally quit after a few weeks. The plan you created for me was the right level of difficulty - I was able to push past my normal stopping point. I love that I can now subtlety brag that I workout 5x a week!',
}, {
    name: 'Ally M.',
    location: 'Madison, WI',
    title: 'Very cool!',
    comment: 'It\'s really cool seeing your plan adjust as you progress. You never wonder about what you should be doing next, it has that figured out for you. You just sit back and next thing you know you see your waistline shrink.',
}, {
    name: 'Joy V.',
    location: 'Los Angeles, CA',
    title: 'Fits my schedule!',
    comment: 'Once I hit my 30\'s it was hard for me to maintain my ideal weight. So I normally yo yo and only workout when I feel like I need to. You changed this for me. Your workouts are quick and effective so I knock them out and get on with my day. I love it.',
}];

const Landing = ({t}) => {
    const [imagesArray, setImagesArray] = useState([pb1, pb2, pb3, pb4, pb5, pb6, pb7, pb8]);

    const [programsArray, setProgramsArray] = useState([{
        img: pmsb1,
        title: 'The Basics'
    }, {
        img: pmsb2,
        title: 'Low Impact'
    }, {
        img: pmsb3,
        title: 'Lower Body Tone'
    }, {
        img: pmsb4,
        title: 'Quick Workouts',
    }, {
        img: pmsb5,
        title: 'Upper Body Tone'
    }, {
        img: pmsb6,
        title: 'Waistline Slimming'
    }])

    const rotateArray = () => {
        const _newArray = [...imagesArray];

        _newArray.push(_newArray.shift());

        setImagesArray([..._newArray]);
    };

    return (
        <div className='landing'>
            <SecondaryHeader/>
            <div className='landing__intro-banner'>
                <video
                    className='landing__intro-banner__video'
                    autoPlay
                    muted
                    loop
                    controls={false}
                    id='myVideo'
                >
                    <source src={video} type='video/mp4'/>
                </video>
                <span className='landing__intro-banner__gradient'/>
                <div className={'landing__intro-banner__text-desktop'}>
                    <div className={'landing__intro-banner__text-desktop__first-line'}>
                        <b>30 DAY</b>
                        <small>Take the free challenge and <br/>see results in 3 weeks!</small>
                    </div>
                    <div className={'landing__intro-banner__text-desktop__second-line'}>
                        <img src={fitLabelText} alt={'fit-label-text'}/>
                        <b>CHALLENGE</b>
                    </div>
                </div>
                <div className={'landing__intro-banner__text-mobile'}>
                    <div className={'landing__intro-banner__text-mobile__first-line'}>
                        <b>30 DAY</b>
                    </div>
                    <div className={'landing__intro-banner__text-mobile__second-line'}>
                        <img src={fitLabelText} alt={'fit-label-text'}/>
                        <b>CHALLENGE</b>
                    </div>
                    <div className={'landing__intro-banner__text-mobile__third-line'}>
                        <p>Take the free challenge and see results in 3 weeks!</p>
                    </div>
                </div>
                <Link className={'landing__intro-banner__start-button'} to={routerBook.registration}>
                    <p>Start free <br/> 30 days</p>
                    <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M2 2H27V27' stroke='white' strokeWidth='3'/>
                        <path d='M27 2L2 27' stroke='white' strokeWidth='3'/>
                    </svg>
                </Link>
            </div>

            <section className={'landing__content'}>
                <div className={'landing__content__three-weeks'}>
                    <div className={'landing__content__three-weeks__header'}>
                        <div className={'landing__content__three-weeks__header__result'}>
                            <p>See the results in</p>
                            <b>3 Weeks</b>
                        </div>
                        <p className={'landing__content__three-weeks__header__description'}>
                            We specialize in full-body workouts designed for women. Each of our programs balances
                            strength
                            training, sculpting techniques and body weight exercises to achieve long, lean muscles that
                            will
                            last.
                        </p>
                    </div>
                    <div className={'landing__content__three-weeks__photos__desktop'}>
                        <div className={'landing__content__three-weeks__photos__desktop__image'}>
                            <span>Barre</span>
                            <img src={twb1} alt={'twb1'}/>
                        </div>
                        <div className={'landing__content__three-weeks__photos__desktop__image'}>
                            <span>Sculpt</span>
                            <img src={twb2} alt={'twb2'}/>
                        </div>
                        <div className={'landing__content__three-weeks__photos__desktop__image'}>
                            <span>Cardio</span>
                            <img src={twb3} alt={'twb3'}/>
                        </div>
                        <div className={'landing__content__three-weeks__photos__desktop__image'}>
                            <span>Pilates</span>
                            <img src={twb4} alt={'twb4'}/>
                        </div>
                        <div className={'landing__content__three-weeks__photos__desktop__image'}>
                            <span>Bodyweight</span>
                            <img src={twb5} alt={'twb5'}/>
                        </div>
                    </div>

                    <div className={'landing__content__three-weeks__photos__mobile'}>
                        <div className={'landing__content__three-weeks__photos__mobile__left'}>
                            <div className={'landing__content__three-weeks__photos__mobile__image'}>
                                <span>Barre</span>
                                <img src={twb1} alt={'twb1'}/>
                            </div>
                            <div className={'landing__content__three-weeks__photos__mobile__image'}>
                                <span>Sculpt</span>
                                <img src={twb2} alt={'twb2'}/>
                            </div>
                            <div className={'landing__content__three-weeks__photos__mobile__image'}>
                                <span>Cardio</span>
                                <img src={twb3} alt={'twb3'}/>
                            </div>
                        </div>
                        <div className={'landing__content__three-weeks__photos__mobile__right'}>
                            <p className={'landing__content__three-weeks__photos__mobile__right__description'}>
                                We specialize in full-body workouts designed for women. Each of our programs balances
                                strength training, sculpting techniques and body weight exercises to achieve long, lean
                                muscles that will last.
                            </p>
                            <div className={'landing__content__three-weeks__photos__mobile__image'}>
                                <span>Pilates</span>
                                <img src={twb4} alt={'twb4'}/>
                            </div>
                            <div className={'landing__content__three-weeks__photos__mobile__image'}>
                                <span>Bodyweight</span>
                                <img src={twb5} alt={'twb5'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='landing__content__program'>
                    <div className={'landing__content__program__header'}>
                        <b>Not your average<br/> workout program</b>
                        <p>Thousands have already experienced our difference</p>
                    </div>
                    <div onAnimationIteration={event => {
                        rotateArray();
                    }} className={'landing__content__program__photos move_left'}>
                        {imagesArray.map(img => {
                            return <img key={img} src={img} alt={'image'}/>;
                        })}
                    </div>
                </div>
                <div id={'how-it-works'} className='landing__content__difference'>
                    <div className='landing__content__difference__description'>
                        <b>Our Difference</b>
                        <p>Our innovative approach learns you and adjusts to your needs.</p>
                        <strong>
                            See how we get to learn you so you get the your <span>best</span>results
                        </strong>
                    </div>
                    <img src={db1} alt={'phone'}/>
                </div>
                <div className='landing__content__performance'>
                    <img src={pbp1} alt={'pbp1'}/>
                    <b>
                        Based on your performance, if the workouts, are too <span>hard</span> or <span>easy</span>,
                        we <span>automatically</span> change your workouts.
                    </b>
                </div>
                <div className='landing__content__tracker'>
                    <b>
                        Using an Activity Tracker, we can track your performance and adjust so nothing
                        is <span>too hard</span> or <span>too easy</span>.
                    </b>
                    <img src={tb1} alt={'tb1'}/>
                </div>
                <div className={'landing__content__download'}>
                    <a>
                        <svg width='34' height='40' viewBox='0 0 34 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M32.9687 31.0229C32.3918 32.3677 31.6791 33.65 30.8416 34.85C29.7229 36.4479 28.8041 37.552 28.1 38.1666C27.0062 39.1708 25.8312 39.6875 24.575 39.7166C23.675 39.7166 22.5875 39.4604 21.3208 38.9395C20.05 38.4208 18.8833 38.1666 17.8145 38.1666C16.6958 38.1666 15.4958 38.4208 14.2104 38.9395C12.9271 39.4604 11.8896 39.7333 11.0958 39.7583C9.89372 39.8104 8.69163 39.2812 7.49372 38.1666C6.72913 37.5 5.77288 36.3541 4.62497 34.7333C3.3958 33.0062 2.38538 30.9958 1.59372 28.7104C0.745801 26.2375 0.320801 23.8458 0.320801 21.5291C0.320801 18.8771 0.893718 16.5875 2.04163 14.6708C2.90986 13.1655 4.15111 11.9092 5.6458 11.0229C7.11969 10.1389 8.80223 9.66286 10.5208 9.64372C11.4791 9.64372 12.7354 9.93955 14.2916 10.5229C15.8479 11.1062 16.8479 11.4021 17.2833 11.4021C17.6125 11.4021 18.7187 11.0541 20.6021 10.3646C22.3791 9.72497 23.8791 9.46038 25.1083 9.56455C28.4416 9.8333 30.9437 11.1458 32.6083 13.5125C29.6291 15.3187 28.1562 17.8458 28.1854 21.0896C28.2104 23.6166 29.1291 25.7187 30.9291 27.3875C31.7249 28.149 32.6534 28.7584 33.6687 29.1854C33.4479 29.825 33.2146 30.4354 32.9687 31.0229ZM25.3291 0.958301C25.3291 2.93747 24.6041 4.78747 23.1646 6.49788C21.4229 8.53122 19.3187 9.7083 17.0375 9.52288C17.0076 9.27401 16.993 9.02354 16.9937 8.77288C16.9937 6.8708 17.8187 4.83747 19.2916 3.17288C20.025 2.33122 20.9583 1.62913 22.0895 1.0708C23.2187 0.520801 24.2854 0.216634 25.2896 0.164551C25.3166 0.431217 25.3291 0.695801 25.3291 0.958301Z'
                                fill='white'/>
                        </svg>
                        <p>Download App</p>
                    </a>
                    <a>
                        <svg version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 299.679 299.679'>
                            <path fill='#FFF'
                                  d='M181.122,299.679c10.02,0,18.758-8.738,18.758-18.758v-43.808h12.525c7.516,0,12.525-5.011,12.525-12.525V99.466H74.749v125.123c0,7.515,5.01,12.525,12.525,12.525H99.8v43.808c0,10.02,8.736,18.758,18.758,18.758c10.019,0,18.756-8.738,18.756-18.758v-43.808h25.051v43.808C162.364,290.941,171.102,299.679,181.122,299.679z'/>
                            <path fill='#FFF'
                                  d='M256.214,224.589c10.02,0,18.756-8.737,18.756-18.758v-87.615c0-9.967-8.736-18.75-18.756-18.75c-10.021,0-18.758,8.783-18.758,18.75v87.615C237.456,215.851,246.192,224.589,256.214,224.589z'/>
                            <path fill='#FFF'
                                  d='M43.466,224.589c10.021,0,18.758-8.737,18.758-18.758v-87.615c0-9.967-8.736-18.75-18.758-18.75c-10.02,0-18.756,8.783-18.756,18.75v87.615C24.71,215.851,33.446,224.589,43.466,224.589z'/>
                            <path fill='#FFF'
                                  d='M209.899,1.89c-2.504-2.52-6.232-2.52-8.736,0l-16.799,16.743l-0.775,0.774c-9.961-4.988-21.129-7.479-33.566-7.503c-0.061,0-0.121-0.002-0.182-0.002h-0.002c-0.063,0-0.121,0.002-0.184,0.002c-12.436,0.024-23.604,2.515-33.564,7.503l-0.777-0.774L98.516,1.89c-2.506-2.52-6.232-2.52-8.736,0c-2.506,2.506-2.506,6.225,0,8.729l16.25,16.253c-5.236,3.496-9.984,7.774-14.113,12.667C82.032,51.256,75.727,66.505,74.86,83.027c-0.008,0.172-0.025,0.342-0.033,0.514c-0.053,1.125-0.078,2.256-0.078,3.391H224.93c0-1.135-0.027-2.266-0.078-3.391c-0.008-0.172-0.025-0.342-0.035-0.514c-0.865-16.522-7.172-31.772-17.057-43.487c-4.127-4.893-8.877-9.171-14.113-12.667l16.252-16.253C212.405,8.115,212.405,4.396,209.899,1.89z M118.534,65.063c-5.182,0-9.383-4.201-9.383-9.383c0-5.182,4.201-9.383,9.383-9.383c5.182,0,9.383,4.201,9.383,9.383C127.917,60.862,123.716,65.063,118.534,65.063z M181.145,65.063c-5.182,0-9.383-4.201-9.383-9.383c0-5.182,4.201-9.383,9.383-9.383c5.182,0,9.383,4.201,9.383,9.383C190.528,60.862,186.327,65.063,181.145,65.063z'/>
                        </svg>
                        <p>Download App</p>
                    </a>
                </div>
                <div id={'about-us'} className={'landing__content__programs'}>
                    <div className='landing__content__programs__header'>
                        <b>Programs</b>
                        <p>Want a more self guided approach. Try one of our flexible programs of choose from of our
                            hundreds of videos.</p>
                    </div>
                    <div className={'landing__content__programs__photos'}>
                        {programsArray.map(({img, title}) => {
                            return <div className={'landing__content__programs__photos__photo'}>
                                <img key={img} src={img} alt={'image'}/>
                                <span>{title}</span>
                            </div>;
                        })}
                    </div>
                </div>
                <div className='landing__content__coaches'>
                    <div className='landing__content__coaches__description'>
                        <div className='landing__content__coaches__description__header'>
                            <b>Our Coaches Your Success</b>
                            <img src={cb1} alt={cb1}/>
                            <p>We provide you with a coach to get you through your first 30 days. Have any questions
                                about form, or just need some motivation, our coaches are there for you.</p>
                        </div>
                        <Link to={routerBook.registration}>
                            <p>START FREE <br/> 30 DAYS</p>
                            <svg width='29' height='29' viewBox='0 0 29 29' fill='none'
                                 xmlns='http://www.w3.org/2000/svg'>
                                <path d='M2 2H27V27' stroke='white' strokeWidth='3'/>
                                <path d='M27 2L2 27' stroke='white' strokeWidth='3'/>
                            </svg>
                        </Link>
                        <small>See the results you can get!</small>
                    </div>
                    <img className={'landing__content__coaches__image'} src={cb1} alt={cb1}/>
                </div>
                <div className='landing__content__reviews'>
                    <strong>Here's what our users are saying...</strong>
                    <div className={'landing__content__reviews__content'}>
                        {reviewsList.map(({name, title, location, comment}, index) => {
                            return (
                                <div key={index} className={'landing__content__reviews__content__tile'}>
                                    <div className={'landing__content__reviews__content__tile__header'}>
                                        <b>{name}</b>
                                        <p>{location}</p>
                                    </div>
                                    <div className={'landing__content__reviews__content__tile__content'}>
                                        <b>{title}</b>
                                        <p>{comment}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <div className={'landing__outro-banner'}>
                <span className='landing__outro-banner__gradient'/>
                <div className={'landing__outro-banner__text'}>
                    <div className='landing__outro-banner__text__header'>
                        <p>Cancel anytime</p>
                        <b>YOUR FIRST 30 DAYS FREE!</b>
                    </div>

                    <Link className={'landing__outro-banner__text__start-button'} to={routerBook.registration}>
                        <p>START FREE <br/> 30 DAYS</p>
                        <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M2 2H27V27' stroke='white' strokeWidth='3'/>
                            <path d='M27 2L2 27' stroke='white' strokeWidth='3'/>
                        </svg>
                    </Link>
                </div>

                <div className='landing__outro-banner__footer'>
                    <div className={'landing__outro-banner__footer__links'}>
                        <Link to={routerBook.contact}>Contact</Link>
                        <Link to={routerBook.privacyPolicy}>Privacy Policy</Link>
                        <Link to={routerBook.termsOfService}>Terms Of Service</Link>
                    </div>
                    <div className={'landing__outro-banner__footer__social'}>
                        <a><img src={instagramIcon} alt={'social__icon'}/></a>
                        <a><img src={facebookIcon} alt={'social__icon'}/></a>
                        <a><img src={youtubeIcon} alt={'social__icon'}/></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(Landing);
