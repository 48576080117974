import {useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {weakChallengeList} from '../../services/api/challengesService/challengesService';
import './styles.scss';
import {withAuthUser} from '../../hocs/withAuthUser';
import {fromSecondsToMinutes} from '../../utils/globalUtils';

const ChallengesItem = () => {
    const {search} = useLocation();
    const [body, setBody] = useState({});
    const [activeWeak, setActiveWeak] = useState(0);

    useEffect(() => {
        if (search) {
            weakChallengeList(search.split('?')[1]).then(r => {
                setBody(r);
                console.log(r);
            });
        }
    }, [search]);

    const getWeek = (items) => (
        items.map(({
                       duration,
                       thumbnail,
                       title,
                       workoutId,
                   }, index) => (
            <div className={'challenge__item'}>
                <img src={thumbnail} className={'challenge__item_img'}/>
                <div className={'challenge__item_body'}>
                    <div className={'challenge__item_top'}>
                        <p>{title}</p>
                    </div>
                    <div className={'challenge__item_bottom'}>
                        <p className={'challenge__item_bottom_time'}>{fromSecondsToMinutes(duration || 0)}</p>
                        <p className={'challenge__item_bottom_day'}>day {index + 1}</p>
                    </div>
                </div>
            </div>))
    );
    const getContent = () => {
        if (body.weeksPlan) {
            return body.weeksPlan.map((item, index) => {
                return <Week getWeek={getWeek} item={item} index={index}/>
            });
        }
    };
    return (
        <div className={'content-container'}>
            <div className={'challenge'}>
                <div className='challenge__head'>
                    <h1>{body?.title}</h1>
                    <p>{body?.description}</p>
                </div>
                <div className={'challenge__body'}>
                    {getContent()}
                </div>
            </div>
        </div>
    );
};

const Week = ({getWeek, item, index}) => {
    const [isOpen, setIsOpen] = useState(index === 0);


    return (
        <div className={'challenge__weak'} key={index + 'weeksPlan'}>
            <div className={`challenge__weak_title ${isOpen ?
                'challenge__weak_title_active' :
                'challenge__weak_title_disabled'}`} onClick={() => {
                setIsOpen(!isOpen);
            }}><h2>Week {index + 1}</h2></div>
            <div
                className={`challenge__weak_body ${isOpen ?
                    'challenge__weak_body_active' :
                    'challenge__weak_body_disabled'}`}>
                {getWeek(item)}
            </div>
        </div>
    )
}

export default withAuthUser(ChallengesItem);
