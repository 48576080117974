import s from './privacy-policy.module.scss';

import logo from '../../assets/images/header/logo.svg';
import {useEffect} from "react";

export const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <section className={s.wrapper}>
            <div className={s.wrapper__header}>
                <img src={logo} alt={'logo'} />
            </div>
            <hr />

            <div>
                <p><strong>LEVL Privacy Policy</strong></p>
                <p>At LEVL (“LEVL”), we respect the privacy of our users and the importance of the information they
                    entrust to us. With this in mind, we provide you this privacy policy (the “Policy”) to help you
                    understand the kinds of information we may gather about you when you visit the Site or use any of
                    our Services, how we may use and disclose the information, and how you can control, correct and/or
                    update the information. By visiting the LEVL website or using LEVL’s mobile applications or
                    services, you are accepting the policies and practices described in this Policy. Each time you visit
                    the LEVL website or mobile applications or use the LEVL services, you agree and expressly consent to
                    our collection, use and disclosure of the information that you provide as described in this
                    Policy.</p>
                <p>THE LEVLMETHOD.COM PRIVACY POLICY WAS LAST UPDATED ON: MARCH 19, 2019.</p>

                <b>Information We May Collect</b>
                <p>We may collect information that can identify you (“personal information”), such as your name, email
                    address, age, weight, personal fitness profile, and comments, photographs, video submissions or
                    likeness when you provide it to us when using our website (e.g., by creating a user login on our
                    site), or in some other manner, e.g., by posting or submitting it to our website or mobile
                    applications social features, to social websites such as Facebook using our website or mobile
                    application or directly to our Facebook page. We may also collect information from (i) from our
                    business partners, and (ii) from other third parties as we further describe in this Policy. In
                    addition to this information, we may also automatically collect information from your Facebook
                    friends when they reply to Facebook comments you have uploaded to our site (e.g., their name,
                    comment and photo). We may combine the personal information that we receive from different
                    sources.</p>
                <p>We also may collect other types of information in the following ways when you visit our LEVL website
                    or engage with one of LEVL’s mobile applications:</p>
                <p>Our server logs automatically collect information, such as your IP address, your browser type and
                    language, and the date and time of your visit, which helps us track users’ movements around our site
                    and understand trends.</p>
                <p>We collect information from and about the device(s) you use to access our services, including:
                    information on your wireless and mobile network connection, like your service provider and signal
                    strength, hardware and software information such as IP address, device type, app crashes, app
                    version, advertising IDs (such as Google’s AdvertisingID and Apple’s IDFA, both of which are
                    randomly generated numbers that you can reset by going into your device’s settings), browser type,
                    version and language, operating system, time zones, identifiers associated with cookies or other
                    technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC
                    address).</p>
                <p>We may assign your computer one or more cookies which may collect information to facilitate access to
                    our website and to personalize your online experience.</p>
                <p>We may use standard Internet and mobile tools, such as web beacons, which collect information that
                    tracks your use of our website and/or mobile application and enables us to customize our services
                    and advertisements.</p>
                <p>We use third party analytics tools, such as Google Analytics, to collect and analyze certain user
                    information and behavior. This information may include demographic and interest information, such as
                    age, gender and users interests. We use this information for internal business purposes on an
                    aggregated, anonymous basis. To opt-out of Google Analytics data collection, you can visit the
                    following site and follow the opt-out procedures: https://tools.google.com/dlpage/gaoptout/</p>
                <p>We may also collect certain information, such as the URL of the last web page you visited before
                    coming to our Site, your preferences and settings (e.g., time zone), the ads you clicked on or
                    viewed on our Site (if any), how long you stayed on our Site and what services and features you
                    used.</p>
                <p>You may be able to use your Facebook or Google account to login to create an account. When you use
                    this feature, we may receive any of your information you provide or permit to be provided to us via
                    a social network or public forum provider like Facebook or Google. This data may include your use of
                    our app on such public forums and/or social networks, including with whom you share our app. For
                    further information as to how and for what purpose the social network or public forum provider
                    collects, uses and shares your data, as well as how you can set your privacy preferences on their
                    site or apps, please see their privacy policy.</p>
                <p>We may receive information about you from our partners, for instance where our ads are published on a
                    partner’s website or platform (in which case they may pass along details on a campaign’s
                    success).</p>
                <p>If you purchase a subscription in our apps, neither Google nor Apple provide us with any personal
                    information of the subscription purchaser (like name, email or payment information). Only anonymized
                    data gets passed to LEVL.</p>
                <p>LEVL is part of the IAC group of businesses and we share your information with other IAC Group
                    companies to:</p>
                <p>assist us in technical processing operations, such as data hosting and maintenance, finance, legal,
                    HR and accounting assistance, securing our data and systems and fighting against spam, abuse, fraud,
                    infringement and other wrongdoings.</p>
                <p>for legitimate business purposes such as corporate audit, analysis and consolidated reporting as well
                    as compliance with applicable laws.</p>
                <p>For corporate transactions, we may transfer your information if we are involved, whether in whole or
                    in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution,
                    bankruptcy or other change of ownership or control.</p>
                <p>We do not knowingly collect personal information from children under the age of 13. Should we ever do
                    so, we will comply with the Children’s Online Privacy Protection Act.</p>

                <b>Information Others May Collect</b>
                <p>We may allow third-party advertising companies and ad networks, including content recommendation
                    engines, to display advertisements on our LEVL websites or related applications, and to track user
                    activity on our Site for the purpose of determining what ads to display. These companies may also
                    use information collected on our Site in order to better serve ads (ad retargeting).</p>
                <p>We do not provide any personal information to these companies.</p>
                <p>These companies may use tracking tools, such as cookies, web beacons, tracking pixels and other
                    technologies to collect information about computers and user browsing activity to determine which
                    advertisements to display to users.</p>
                <p>Some of these companies may be members of the Network Advertising Initiative, which offers consumers
                    the ability to opt out of ad targeting by all member companies
                    (www.networkadvertising.org/choices).</p>
                <p>These advertisers, advertising networks, and behavioral targeting companies sometimes collect
                    information that tracks your use of our Site in order to measure ad performance, track which ads you
                    clicked on and display ads targeted specifically to you, including displaying ads after your leave
                    our Site on other third party sites that are within their ad network.</p>
                <p>If you post information about yourself or others, or communicate with others using our website,
                    please note that we cannot control who reads your postings or what they do with the information you
                    provide. We encourage you to use caution in posting personal information.</p>
                <b>Apple Health Information</b>
                <p>For your convenience, you may choose to connect to Apple Health through certain of our mobile
                    applications. We only write data to the Apple Health database on your Apple device. You can turn
                    this feature off at any time.</p>

                <b>How We May Use Information We Collect</b>
                <ul>
                    <li>Provide our products and services;</li>
                    <li>Ensure technical functionality of our products and services;</li>
                    <li>Understand how you access and use our Services so that we can improve and customize your
                        experience ;
                    </li>
                    <li>Analyze your use of and interactions with our services so that we can improve our website and
                        mobile applications and build new features and products for our customers
                    </li>
                    <li>Send newsletters to our users and make special offers;</li>
                    <li>Offer products and services that may be of interest to you;</li>
                    <li>Process payment for products or services you order via our website;</li>
                    <li>Customize the advertising and/or content that you see on our website;</li>
                    <li>Facilitate your use of our website and mobile applications;</li>
                    <li>Publicly display feedback and comments posted by you on our properties (e.g., use your name,
                        photograph, video submissions and comments when you post feedback on our site or to Facebook);
                    </li>
                    <li>Display information about your friends (e.g., their name, photograph and comments) that is
                        automatically uploaded when they comment on Facebook messages that you post to our site;
                    </li>
                    <li>Manage your account and your preferences;</li>
                    <li>Analyze use of and improve our website, mobile application, products and services;</li>
                    <li>Identify and protect against fraudulent transactions and other misuses of our website;</li>
                    <li>Enforce our Terms of Use.</li>
                </ul>
                <p>If you register for one of our mobile applications, for your convenience, we will port over your
                    profile data to other mobile applications that you purchase from us.</p>
                <p>Some of our mobile applications may offer you the ability to register and login using your third
                    party social media account details (like your Facebook or Google logins). Where you choose to do
                    this, we will receive certain profile information about you from your social media provider. The
                    profile information we receive may vary depending on the app you are using, and on the social media
                    provider concerned, but may often include your name, e-mail address, mobile phone number and friends
                    list. If you login using your Facebook account, your e-mail or your phone number then we may receive
                    a unique ID in connection with this login. This is solely used for confirmation purposes and is kept
                    separate from all other online identifiers. We will use the information we receive only for the
                    purposes that are described in this Privacy Policy or that are otherwise made clear to you in the
                    app you are using. Please note that we do not control, and are not responsible for, other uses of
                    your personal information by your third party social media provider. We recommend that you see their
                    privacy policy to understand how they collect, use and share your information, and how you can set
                    your privacy preferences on their sites and apps.</p>

                <b>With Whom We May Share Information We Collect</b>
                <p>We will not share your personal information with others except as indicated below, or except when we
                    inform you in advance and give you the opportunity to opt out. We may share personal information
                    with:</p>
                <ul>
                    <li>Service providers, such as credit-card payment processors, performing services on our behalf;
                    </li>
                    <li>Other IAC businesses as provided above;</li>
                    <li>Other businesses with which we partner or which we carefully select to offer you products,
                        services,
                        and promotions through our website or offline
                    </li>
                    <li>We may use customer information for Facebook Custom and Lookalike Audiences in order to
                        deliver relevant advertisements to customers and users on Facebook. You may learn more about
                        Facebook Custom Audiences by visiting https://www.facebook.com/business/help/341425252616329 and
                        https://www.facebook.com/business/help/164749007013531. If you’d like to opt-out of our use of
                        your
                        information through Facebook Custom Audiences, please contact info@LEVLmethod.com from the email
                        address you want to opt-out and request to opt-out of Facebook Custom Audience. We will remove
                        your
                        email address from our list within thirty (30) days of receiving your request.
                    </li>
                    <li>Other third parties in limited circumstances, such as reporting suspected criminal activity,
                        complying with legal requirements, preventing fraud, and protecting the safety of our users.
                    </li>
                    <li>We may share aggregated, anonymous information in any of the above situations and also with
                        service
                        providers, advertisers and others for our own marketing purposes and for purposes of improving
                        the
                        manner in which, and to whom, we offer our services.
                    </li>
                </ul>

                <b>Your Choices</b>
                <p>You may choose:</p>
                <ul>
                    <li>Not to provide personal information, although that may result in your inability to obtain certain
                        services or use certain features of our website;</li>
                    <li>To stop receiving promotional emails or newsletters from us by sending an email to the contact
                        address at the bottom of this policy;</li>
                    <li>To delete or decline cookies by changing your browser settings, although if you do so, some of the
                        features or services of our website may not function properly;</li>
                    <li>To review and update your personal information by contacting us at the email address below or, if you
                        have an online account on our website, by logging into that account.</li>
                    <li>You may opt-out of any Google Analytics-related collection by visiting the following site and
                        following the opt-out procedures: https://tools.google.com/dlpage/gaoptout/.</li>
                    <li>You may use the following link to help you opt-out of remarketing pixels and technologies:
                        http://www.networkadvertising.org/choices. Canadian users can also visit youradchoices.ca. For
                        additional information about the collection and use of information about your online activities for
                        advertising purposes, you can visit aboutads.info.</li>
                </ul>

                <b>Security</b>
                <p>We take appropriate commercially reasonable physical, electronic, and other security measures to help
                    safeguard personal information from unauthorized access, alteration, or disclosure.</p>

                <b>Third-Party Links</b>
                <p>The LEVL website may link to websites operated by third parties that we do not control. We do not
                    monitor, control, or endorse the information collection or privacy practices of any third parties.
                    We strongly encourage you to become familiar with the privacy practices of every website you visit
                    and to contact them if you have any questions about their respective privacy policies and practices.
                    This Policy applies solely to information collected by us through the LEVL website or services and
                    does not apply to these third-party websites. The ability to access information of third parties
                    from the LEVL website or services, or links to other websites or locations, is for your convenience
                    only and does not signify our endorsement of such third parties, their products, services, websites,
                    locations, or their content.</p>

                <b>Notice for California Users</b>
                <p>Under the California Shine The Light law, California residents have the right to request from us a
                    list of all third parties to which we have disclosed personal information during the preceding year
                    for direct marketing purposes. We do not currently engage in the type of sharing covered by that law
                    and so no such list exists. For questions about this policy, please contact us at
                    info@LEVLmethod.com. We do not obey Do Not Track signals from a user’s Internet browser. We make no
                    representations concerning third parties that do not collect personal information directly through
                    the Website. These disclosures are made under Cal. Bus. &amp; Prof. Code § 22575.</p>

                <b>Privacy Policy Changes</b>
                <p>LEVL reserves the right to change, modify, add, or remove portions of this Policy at any time and
                    without prior notice, and any changes will become effective immediately upon being posted unless we
                    advise you otherwise. However, we will not use your Personal Information in a way that is materially
                    different than the uses described in this Policy without giving you notice through contact
                    information we have on file for you. Your continued use of the LEVL website or services after this
                    Policy has been amended shall be deemed to be your continued acceptance of the terms and conditions
                    of the Policy, as amended. We encourage you to bookmark this Web page and review this Policy
                    regularly.</p>

                <b>How to Contact Us</b>
                <p>If you have any questions about this Policy, or our information practices, please contact us by the
                    email below:</p>
                <p>info@LEVLmethod.com</p>
                <p>©2019 LEVL, Inc. All rights reserved.</p>

                <b>Linked information:</b>
                <b>Cookies:</b>
                <p>A cookie is a small text file that is stored on a user’s computer for record keeping purposes.
                    Cookies can be either session cookies or persistent cookies. A session cookie expires when you close
                    your browser and is used to make it easier for you to navigate our website. A persistent cookie
                    remains on your hard drive for an extended period of time.</p>
                <p>For example, when you sign in to our website, we will record your user or member ID and the name on
                    your user or member account in the cookie file on your computer. We also may record your password in
                    this cookie file. For security purposes, we will encrypt any usernames, passwords, and other user or
                    member account-related data that we store in such cookies. In the case of sites and services that do
                    not use a user or member ID, the cookie will contain a unique identifier. We may allow our
                    authorized service providers to serve cookies from our website to allow them to assist us in various
                    activities, such as doing analysis and research on the effectiveness of our site, content and
                    advertising.</p>
                <p>You may delete or decline cookies by changing your browser settings. (Click “Help” in the toolbar of
                    most browsers for instructions.) If you do so, some of the features and services of our website may
                    not function properly.</p>
                <p>We may allow third-parties, including IAC companies, advertising companies, and ad networks, to
                    display advertisements on our site. These companies may use tracking technologies, such as cookies,
                    to collect information about users who view or interact with their advertisements. Our website does
                    not provide any personal information to these third parties, but they may collect information about
                    where you, or others who are using your computer, saw and/or clicked on the advertisements they
                    deliver, and possibly associate this information with your subsequent visits to the advertised
                    websites. They also may combine this information with personal information they collect from you.
                    The collection and use of that information is subject to the third-party’s privacy policy. This
                    information allows them to deliver targeted advertisements and gauge their effectiveness. Some of
                    these third-party advertising companies may be advertising networks that are members of the Network
                    Advertising Initiative, which offers a single location to opt out of ad targeting from member
                    companies (www.networkadvertising.org).</p>
                <b>Web Beacons:</b>
                <p>Web beacons (also known as clear gifs, pixel tags or web bugs) are tiny graphics with a unique
                    identifier, similar in function to cookies, and are used to track the online movements of web users
                    or to access cookies. Unlike cookies which are stored on the user’s computer hard drive, web beacons
                    are embedded invisibly on the web pages (or in email) and are about the size of the period at the
                    end of this sentence.</p>
                <p>Web beacons may be used to deliver or communicate with cookies, to count users who have visited
                    certain pages and to understand usage patterns. We also may receive an anonymous identification
                    number if you come to our site from an online advertisement displayed on a third-party website.</p>

                <b>How Long Do We Store Your Information</b>
                <p>We retain your information only for as long as is necessary for the purposes set out in this policy,
                    for as long as your account is active or as needed to provide the services to you. We know that our
                    customers lead busy active lives and sometimes may need to turn off our service for a while and then
                    join back at a later time. For your convenience we may retain your profile information so that you
                    can be up and running quickly when you are ready to return. Unless you have opted out, we may
                    continue to keep you on our email list to make you aware of improvements to our features and special
                    offers. You can opt-out of those emails at any time. We will also retain and use your information to
                    the extent necessary:</p>
                <ul>
                    <li>to comply with applicable law;</li>
                    <li>to evidence our compliance with applicable law;</li>
                    <li>if there is an outstanding issue, claim or dispute requiring us to keep the relevant information
                        until it is resolved;</li>
                    <li>if the information must be kept for our legitimate business interests, such as enforcing our terms or
                        fraud prevention. For example, information may need to be kept to prevent a user who was banned for
                        security incidents from opening a new account.</li>
                </ul>

                <p>Keep in mind that even though our systems are designed to carry out data deletion processes according
                    to the above guidelines, we cannot promise that all data will be deleted within a specific timeframe
                    due to technical constraints.</p>

            </div>

            <small>@ LEVL. All Rights Reserved, {new Date(Date.now()).getFullYear()}</small>
        </section>
    );
};
