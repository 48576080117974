import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import routerBook from '../../../../routes/routerBook';
import { Button, Input, CheckBox } from '../../../../components/ui-core';
import { registrationSchema } from './registration.schema';
import './styles.scss';
import logo from '../../../../assets/images/header/logo.svg';
import { signUpUser } from '../../redux/authenticationOperations';
import { withDefaultUser } from '../../../../hocs/withDefaultUser';
import { errorHandler, successHandler } from '../../../../components/toastify/redux/notifyOperations';

const Registration = ({ t }) => {
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {},
        resolver: yupResolver(registrationSchema),
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleFormSubmit = data => {
        delete data.agreement;
        dispatch(signUpUser({ ...data }, () => {
        }, ({ message }) => {
            dispatch(errorHandler(message));
        }));
    };
    useEffect(() => {
        const subscription = watch(({ email }, { name }) => {
            if (name === 'email' && email.length && email.includes(' ')) {
                setValue('email', email.replace(' ', ''));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    return (
        <div className='registration-page'>
            <img className={'registration-page-logo'} src={logo} alt={'levl_logo'} />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <span className='registration-page__title'>
            {t('registration_title')}
          </span>
                <div className='registration-page__form'>
                    <Input
                        inputChangeOptions={register('email')}
                        errors={errors?.email?.message}
                        options={{
                            placeholderText: `${t('registration_email_placeholder')}`,
                            placeholderIcon: 'email',
                        }}
                    />
                    <Input
                        inputChangeOptions={register('name')}
                        errors={errors?.name?.message}
                        options={{
                            placeholderText: `First Name`,
                            placeholderIcon: 'user',
                        }}
                    />
                    <Input
                        inputChangeOptions={register('password')}
                        errors={errors?.password?.message}
                        options={{
                            className: 'registration-page__form__password_wrapper',
                            placeholderText: `${t('registration_password_placeholder')}`,
                            placeholderIcon: 'password',
                            type: 'password',
                        }}
                    />
                    <span className={'registration-page__form__password__hint'}>Password's length should be at least 8 characters and contain at least one digit, one upper-case and one lower-case letter.</span>
                </div>
                <Button
                    className={'registration-page_signup_button'}
                    disabled={!isValid}
                    options={{ text: `${t('registration_button')}`, type: 'submit' }}
                />
            </form>
            <div className='registration-page__to-registration'>
          <span className='registration-page__to-registration-title'>
            {t('registration_to_login')}
          </span>
                <Link
                    className='registration-page__to-registration-btn'
                    to={routerBook.login}
                >
                    {t('login_button')}
                </Link>
            </div>
        </div>
    );
};
export default withDefaultUser(withTranslation()(Registration));














