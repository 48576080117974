import { PercentCircle } from '../../../../components/ui-core';
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';

const PereparingPlan = ({ progressRef }) => {
    const intervalRef = useRef(null);
    const [percent, updatePercent] = useState(10);
    useEffect(() => {
        progressRef.current.value = 10;
        changePercent();
    }, []);
    let a = 0;
    const changePercent = () => {
        if (a < 90) {
            a += 1;

            setTimeout(() => {
                updatePercent(e => {
                    progressRef.current.value = e + 1;
                    return e + 1;
                });
                changePercent();
            }, 15);
        }
    };
    return (
        <div className='pereparing-plan'>
            <PercentCircle textPadding={"0 0 5px 5px"} percent={percent} x={percent === 100 ? 5 : 7} />

            <h3 className='pereparing-plan__title'>Preparing your plan!</h3>

            <p className='pereparing-plan__description'>
                Did you know that people who do at least 6 workouts in the first 2 weeks
                start to build the habit of exercising and are 3x as likely to get
                result. Be sure you really focus during the first 2 weeks.
            </p>
        </div>
    );
};
export default PereparingPlan;
