import { object, string } from 'yup';

export const loginSchema = object().shape({
  email: string()
    .required('This field is required')
    .email('This field should be login@email.com'),
  password: string()
    .required('This field is required')
    .min(8, 'This field should be at least 8 characters'),
});
