import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import Router from '../routes/router';
import {store} from '../store/store';
import '../assets/styles/globalStyles.scss';
import '../localization/i18n';
import '../assets/styles/_fonts.scss';
import Modal from './modal/modal';
import './styles.scss';
import Onboarding from './onboarding/onboarding';
import Toastify from './toastify/toastify';
import {InstallPWA} from "../pages/question/component/InstallPWA/InstallPWA";

function App() {

    return (
        <>
            <InstallPWA/>
            <Provider store={store}>
                <BrowserRouter>
                    <Router/>
                    <Modal/>
                    <Onboarding/>
                    <Toastify/>
                </BrowserRouter>
            </Provider>
        </>
    );
}

export default App;
