import {post, get, put, remove} from '../apiService';

export const createFreeSubscription = () => {
    return post('/payment/stripe/free')
}

export const getSubscribes = () => {
    return get('/payment/stripe/subscribes')
}

export const getUserSubscription = () => {
    return get('/payment/stripe')
}

export const getApiKey = () => {
    return get('/payment-setting/stripe/public-key')
}

export const attachPaymentMethod = (paymentMethodId) => {
    return post('/payment/stripe/payment-method', {paymentMethodId})
}

export const detachPaymentMethod = (paymentMethodId) => {
    return remove('/payment/stripe/payment-method', {paymentMethodId})
}

export const getUsersPaymentMethod = () => {
    return get('/payment/stripe/payment-method')
}

export const subscribe = ({priceId}) => {
    return post('/payment/stripe', {priceId})
}

export const unsubscribe = (subscribeId, status) => {
    return put('/payment/stripe', {subscribeId})
}
