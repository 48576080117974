import './styles.scss';
import {useEffect, useRef, useState} from 'react';
import {getLocalAccessToken} from '../../../services/api/tokenService';
import loader from '../../../assets/images/myPlan/watch_loader.svg';
import {useSelector} from 'react-redux';
import {createQueryParams} from '../../../utils/requestsUtils';
import {markPartAsCompleted, markPartAsUncompleted} from '../../../services/api/planService/planService';
import playButtonIcon from '../../../assets/images/player/play_button.png';
import pauseButton from '../../../assets/images/player/pause.svg';
import nextIcon from '../../../assets/images/player/next_icon.png';
import fullscreenIcon from '../../../assets/images/player/full-screen.svg';
import {useFullScreen} from 'react-hooks-full-screen';

import Loader from '../component/loader/loader';
import {isIOS} from "react-device-detect";
import {useKeyPressCombination} from "../../../hooks/useKeyPressCombination";


const difficult = {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
};

export const PlayerModal = ({
                                progressId,
                                link,
                                parts, id,
                                closeModal,
                                onClose,
                                currentDay,
                                setCompletedDayId,
                                setIsWorkoutCompleted,
                                setShowRatePopup,
                                setWatchDataAction,
                                preview = false,
                            }) => {

    const {data} = useSelector(store => store.authState);
    const [isStartWorkoutModalOpen, setIsStartWorkoutModalOpen] = useState(false);
    const [isEndWorkoutModalOpen, setIsEndWorkoutModalOpen] = useState(false);
    const [isWorkoutEnd, setIsWorkoutEnd] = useState(false);
    const [watchData, setWatchData] = useState(null);
    const [isSSEConnected, setIsSSEConnected] = useState(false);
    const [prevPart, setPrevPart] = useState(null);
    const [currentPart, setCurrentPart] = useState({partId: 0, difficult: 'MEDIUM'});
    const [completedParts, setCompletedParts] = useState([])
    const [_isWorkoutCompleted, _setIsWorkoutCompleted] = useState(false)
    const playerWrapperRef = useRef(null);
    const sseRef = useRef(null)

    console.log(preview)

    //PLAYER STATES
    const [isPlayerReady, setIsPlayerReady] = useState(false)
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isPlayerControlsShown, setIsPlayerControlsShown] = useState(false);
    const [playerVolume, setPlayerVolume] = useState(50);
    const [isVideoLoading, setIsVideoLoading] = useState(false)
    const cursorTimeout = useRef(null);
    const playerRef = useRef(null);

    const [showFullScreen, setShowFullScreen] = useState(false);

    useFullScreen(playerWrapperRef.current, showFullScreen);

    const onMouseMove = () => {
        if (!isPlayerControlsShown) setIsPlayerControlsShown(true);

        if (cursorTimeout?.current) {
            clearTimeout(cursorTimeout.current);
        }

        cursorTimeout.current = setTimeout(() => {
            setIsPlayerControlsShown(false);
        }, 1500);
    };

    //ON PLAYER IS OPEN
    useEffect(() => {
        const hideBar = () => {
            document.body.setAttribute('style', 'height: calc(100vh + 10px)');
            window.scrollTo(0, 0)

            setTimeout(() => {
                window.scrollTo(0, window.scrollY + 10);
                document.body.setAttribute('style', 'height: calc(100vh + 10px); overflow: hidden');
            }, 1000)
        }

        hideBar()

        document.body.setAttribute('style', 'height: calc(100vh + 10px)');
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('touchstart', onMouseMove)
        window.addEventListener("orientationchange", hideBar);

        return () => {
            document.body.removeAttribute('style')
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('touchstart', onMouseMove);
            window.removeEventListener("orientationchange", hideBar);
        }
    }, []);

    //ON PLAYER CLOSE
    useEffect(() => {
        return () => {
            setIsStartWorkoutModalOpen(false);
            setIsEndWorkoutModalOpen(false);
            setIsWorkoutEnd(false);
            setWatchData(null);
            setIsSSEConnected(false);
            setPrevPart(null);
            setCurrentPart({partId: 0, difficult: 'MEDIUM'});
            setIsPlayerReady(false)
            setIsVideoPlaying(false);
            setIsPlayerControlsShown(false);
            setPlayerVolume(50);
            setIsPlayerReady(false)
            setCompletedParts([])
        };
    }, []);

    const playButtonHandler = () => {
        if (playerRef?.current?.player) {
            setIsVideoLoading(true)
            playerRef.current.player.play().then(() => {
                setIsVideoPlaying(true);
                setIsVideoLoading(false)
            });
        }
    };

    const pauseButtonHandler = () => {
        if (playerRef?.current?.player) {
            playerRef.current.player.pause().then(() => {
                setIsVideoPlaying(false);
            });
        }
    };

    const gotoFullScreen = () => {
        setShowFullScreen(!showFullScreen);
    };

    const goToNextPartClickHandler = () => {
        setPrevPart(currentPart);
    };

    const goToPrevPartClickHandler = () => {
        if (+currentPart.partId - 1 >= 0) {
            if (!preview) {
                const _prevPart = completedParts.filter(({partId}) => +partId === +currentPart.partId - 1)

                markPartAsUncompleted(+currentDay.day, +currentPart.partId - 1, id)

                setWatchData(null)
                setPrevPart(null)

                setCurrentPart(_prevPart?.length ? {partId: _prevPart[0].partId, difficult: _prevPart[0].difficult, force: true} : {
                    partId: currentPart.partId - 1,
                    difficult: 'MEDIUM',
                    force: true
                });
            } else {
                setPrevPart(null)

                setCurrentPart({
                    partId: currentPart.partId - 1,
                    difficult: 'MEDIUM',
                    force: true
                });
            }
        }
    };

    //HANDLER FOR END WORKOUT BUTTON
    const endWorkoutButtonHandler = () => {
        sseRef?.current?.close()

        setIsWorkoutEnd(true);

        setShowFullScreen(false);

        onClose && onClose();
        closeModal();
    };

    //ON PLAYER VOLUME CHANGE
    useEffect(() => {
        if (playerRef?.current?.player) {
            playerRef.current.player.setVolume(playerVolume / 100);
        }
    }, [playerRef, playerVolume]);

    //ON WORKOUT COMPLETE
    useEffect(() => {
        if (isWorkoutEnd && playerRef?.current?.player) {
            pauseButtonHandler();
        }
    }, [isWorkoutEnd, playerRef]);

    //CREATING SSE CONNECTION IF WATCH CONNECTED
    useEffect(() => {
        if (data?.watch?.id && progressId && currentDay.day && !isSSEConnected && !preview && ((parts.length - completedParts.length) >= 1)) {
            if (completedParts.length === 0) {
                setIsStartWorkoutModalOpen(true);
            }

            const params = {
                startFromPart: currentPart.partId,
                workoutId: id,
                dayNumber: +currentDay.day,
                progressId,
                token: getLocalAccessToken(),
            };

            const sseUrl = process.env.REACT_APP_ENV === 'production' ? 'https://backend.levlmethod.com/api/v1' : 'https://backend-pre-dev.lvlmethod.lampawork.com/api/v1';

            sseRef.current = new EventSource(`${sseUrl}/workout/watch${createQueryParams(params)}`);

            sseRef.current.onopen = function (event) {
                setIsSSEConnected(true);
                setIsStartWorkoutModalOpen(false);
            };

            sseRef.current.onerror = function (error) {
                sseRef.current.close()
                setIsStartWorkoutModalOpen(false);
                setIsSSEConnected(false);
            };

            sseRef.current.onmessage = function (datalist, ...args) {
                if (datalist.type === 'close') {
                    sseRef.current.close()
                    setIsSSEConnected(false);
                } else {
                    setWatchData({timeStamp: datalist.timeStamp, data: JSON.parse(datalist.data)});
                }
            };
        }
    }, [data?.watch?.id, isSSEConnected]);

    //INIT VIMEO PLAYER AND FILL PLAYER WITH CHECKPOINTS
    useEffect(() => {
        if (playerRef?.current) {

            //eslint-disable-next-line
            playerRef.current.player = new Vimeo.Player('vimeo-player', {
                url: `https://player.vimeo.com/video/${link?.match(/[0-9]+/)}`,
                transparent: true,
                color: '#FE6D02',
                controls: false,
                keyboard: false,
            });

            playerRef.current.player.ready().then(() => {

                setIsPlayerReady(true)

                parts && parts.forEach(({nodes}, partIndex) => {
                    nodes?.forEach(({timeEnd, difficult, timeStart}, index) => {
                        if (index === nodes?.length - 1) {
                            playerRef.current.player.addCuePoint(timeEnd - 1, {
                                customKey: `${partIndex}_${difficult.toUpperCase()}`,
                            });
                        } else {
                            playerRef.current.player.addCuePoint(timeEnd, {
                                customKey: `${partIndex}_${difficult.toUpperCase()}`,
                            });
                        }
                    });
                });
            });
        }
    }, [playerRef]);

    //ASSIGNING EVENT ON CHECKPOINT REACH
    useEffect(() => {
        if (playerRef?.current?.player && currentPart) {
            playerRef.current.player.on('cuepoint', ({data: {customKey}}) => {
                const [partId, difficult] = customKey.split('_');
                if (+partId === +currentPart.partId) {
                    if (difficult === currentPart.difficult) {
                        setPrevPart({partId: +partId, difficult});
                    }
                }
            });
        }

    }, [currentPart, playerRef?.current?.player]);

    //SET PLAYBACK POSITION ON CURRENT PART
    useEffect(() => {
        if (playerRef?.current?.player && currentPart) {

            playerRef.current.player.getCurrentTime().then(seconds => {
                const _currentPart = parts[currentPart.partId];
                const [_currentNode] = _currentPart.nodes.filter(({difficult}) => difficult === currentPart.difficult);

                if (seconds > _currentNode.timeEnd || seconds < _currentNode.timeStart) {
                    setIsVideoLoading(true)
                    playerRef.current.player.setCurrentTime(_currentNode.timeStart).finally(() => setIsVideoLoading(false));
                }
            });
        }

    }, [currentPart, playerRef?.current?.player]);

    useEffect(() => {
        if (preview) {
            //DETECTING IF PREVIOUS PART EXIST AND ITS STILL NOT COMPLETED
            if (prevPart) {

                const {partId} = prevPart;

                if (parts.length - 1 > +partId) {
                    setCurrentPart({
                        partId: +partId + 1,
                        difficult: 'MEDIUM',
                    });
                } else {
                    //IS NEXT PART IS ABSENT CLOSE PLAYER MODAL WINDOW
                    endWorkoutButtonHandler();
                }
            }
            setPrevPart(null);
        }
    }, [prevPart]);



    //ASSIGNING EVENT ON CURRENT PART CHANGE REACH IF WATCHES IS CONNECTED
    useEffect(() => {
        if (isSSEConnected && !preview) {
            //DETECTING IF PREVIOUS PART EXIST AND ITS STILL NOT COMPLETED

            if (prevPart) {
                const {partId, difficult} = prevPart;

                let nextDifficult = 'MEDIUM';

                //IF WATCH DATA IS ACTUAL
                if (+watchData?.data?.partIndex !== prevPart.partId) {
                    //IF NEXT PART AVAILABLE SETTING CURRENT PART ACCORDING TO RESPONSE
                    if (watchData?.data?.available) {
                        const {difficult} = watchData?.data;
                        nextDifficult = difficult;
                    }
                }

                const completedIndex = completedParts.findIndex(p => +p.partId === +partId)

                if (completedIndex === -1) {
                    setCompletedParts([...completedParts, {...prevPart}])
                } else {
                    let newCompletedParts = [...completedParts]

                    newCompletedParts.splice(completedIndex, 1, {...prevPart})

                    setCompletedParts([...newCompletedParts])
                }

                //SENDING PART COMPLETE REQUEST
                markPartAsCompleted(+currentDay.day, +partId, id, difficult, nextDifficult).then(({
                                                                                                      isPopupShow,
                                                                                                      status,
                                                                                                      nextPart,
                                                                                                  }) => {

                    //IF STATUS IS PASSED MARKING WHOLE DAY AS COMPLETED
                    setIsWorkoutCompleted(status === 'PASSED');
                    setCompletedDayId(+currentDay.day);
                    setShowRatePopup(isPopupShow);

                    //IS NEXT PART IS ABSENT CLOSE PLAYER MODAL WINDOW
                    if (!nextPart.available) {
                        endWorkoutButtonHandler();
                    }
                    else {
                        //IF WATCH DATA IS PRESENT
                        if (watchData) {

                            //IF WATCH DATA HAS INFORMATION ABOUT PARTS
                            if (watchData.data?.difficult) {


                                //IF WATCH DATA IS ACTUAL
                                if (+watchData.data.partIndex !== prevPart.partId) {

                                    //IF NEXT PART AVAILABLE SETTING CURRENT PART ACCORDING TO RESPONSE
                                    if (watchData.data.available) {
                                        const {partIndex, difficult} = watchData.data;
                                        setCurrentPart({partId: partIndex, difficult});
                                    }
                                } else {
                                    //IF WATCH DATA IS OUTDATED MAKE NEXT PART MANUALLY
                                    const {partId} = prevPart;

                                    //IF WATCH DATA IS ABSENT MAKE NEXT PART MANUALLY
                                    if (parts.length - 1 > +partId) {
                                        const completedIndex = completedParts.findIndex(p => +p.partId === partId + 1)

                                        if (completedIndex === -1) {
                                            setCurrentPart({
                                                partId: +partId + 1,
                                                difficult: 'MEDIUM',
                                            });
                                        } else {
                                            setCurrentPart(completedParts[completedIndex])
                                        }
                                    } else {
                                        //IS NEXT PART IS ABSENT CLOSE PLAYER MODAL WINDOW
                                        endWorkoutButtonHandler();
                                    }
                                }
                            }
                        } else {
                            const partId = +prevPart?.part || +prevPart?.partId;

                            //IF WATCH DATA IS ABSENT MAKE NEXT PART MANUALLY
                            if (parts.length - 1 > +partId) {
                                const completedIndex = completedParts.findIndex(p => +p.partId === partId + 1)

                                if (completedIndex === -1) {
                                    setCurrentPart({
                                        partId: +partId + 1,
                                        difficult: 'MEDIUM',
                                    });
                                } else {
                                    setCurrentPart(completedParts[completedIndex])
                                }
                            } else {
                                //IS NEXT PART IS ABSENT CLOSE PLAYER MODAL WINDOW
                                endWorkoutButtonHandler();
                            }
                        }
                        setWatchData(null)
                    }
                })

                setPrevPart(null);
            }
        }
    }, [prevPart, isSSEConnected]);

    //ASSIGNING EVENT ON CURRENT PART CHANGE REACH IF WATCHES IS NOT CONNECTED
    useEffect(() => {
        if (!isSSEConnected && !preview) {

            //DETECTING IF PREVIOUS PART EXIST AND ITS STILL NOT COMPLETED
            if (prevPart) {
                const {partId, difficult} = prevPart;

                //SENDING PART COMPLETE REQUEST
                markPartAsCompleted(+currentDay.day, +partId, id, difficult).then(({
                                                                                       isPopupShow,
                                                                                       status,
                                                                                       nextPart,
                                                                                   }) => {

                    //IF STATUS IS PASSED MARKING WHOLE DAY AS COMPLETED
                    setIsWorkoutCompleted(status === 'PASSED');
                    setCompletedDayId(+currentDay.day);
                    setShowRatePopup(isPopupShow);
                    //IF NEXT PART AVAILABLE SETTING CURRENT PART ACCORDING TO RESPONSE
                    if (nextPart.available) setCurrentPart({partId: nextPart.index, difficult: nextPart.node});

                    //IS NEXT PART IS ABSENT CLOSE PLAYER MODAL WINDOW
                    else endWorkoutButtonHandler();
                }).catch(error => {
                    //IF REQUEST FAILED MAKE NEXT PART MANUALLY
                    if (parts.length - 1 > +partId) {
                        setCurrentPart({
                            partId: +partId + 1,
                            difficult: 'MEDIUM',
                        });
                    } else {

                        //IS NEXT PART IS ABSENT CLOSE PLAYER MODAL WINDOW
                        endWorkoutButtonHandler();
                    }
                });
            }
            setPrevPart(null);
        }
    }, [prevPart, isSSEConnected]);



    //ASSIGNING EVENT ON WATCH DATA ACTION RECEIVE
    useEffect(() => {
        if (watchData?.data?.action) {
            setWatchDataAction(watchData.data.action);
        }
    }, [watchData]);

    const getNextNodeDifficult = () => {
        if (isSSEConnected) {

            //IF WATCH DATA IS ACTUAL
            if (watchData?.data?.partIndex && (+watchData?.data?.partIndex !== prevPart?.partId)) {

                if (difficult[watchData.data.difficult] > difficult[currentPart.difficult])
                    return 'high';

                if (difficult[watchData.data.difficult] < difficult[currentPart.difficult])
                    return 'low';
            }
        }

        return 'none';
    };

    const continueModalButtonHandler = () => {
        setIsStartWorkoutModalOpen(false);
    };

    const [isDebugShown, setIsDebugShown] = useState(false);

    useKeyPressCombination(['Backspace', 'd'], (e) => {
        setIsDebugShown(!isDebugShown)
    })

    return (
        <section data-in-full-screen={showFullScreen} className={`player_modal`}>
            <div data-in-full-screen={showFullScreen} id={'player-wrapper'} ref={playerWrapperRef}
                 className={'player_modal__content'}>
                {isStartWorkoutModalOpen && (
                    <div className={'player_modal__watch-modal'}>
                        <div className={'player_modal__watch-modal__wrapper'}>
                            <img src={loader} alt={'watch-loader'}/>
                            <p>Waiting for response from the connected watch</p>
                            <button onClick={continueModalButtonHandler}>Continue without my watch</button>
                        </div>
                    </div>
                )}
                {data && (
                    <>

                        {isDebugShown && (
                            <div className={'player_modal__config'}>
                                <p>Is next node available: <span>{`${watchData?.data?.available}`}</span></p>
                                <p>Next part index: <span>{watchData?.data?.partIndex}</span></p>
                                <p>Next difficulty: <span>{watchData?.data?.difficult}</span></p>
                                <hr/>
                                <p>User HR above: <span>{watchData?.data?.userHrConfig?.above}</span></p>
                                <p>User HR below: <span>{watchData?.data?.userHrConfig?.below}</span></p>
                                <hr/>
                                <p>Default HR above: <span>{watchData?.data?.defaultHrConfig?.above}</span></p>
                                <p>Default HR below: <span>{watchData?.data?.defaultHrConfig?.below}</span></p>
                                <hr/>
                                <p>Current Node HR: <span>{watchData?.data?.targetHR}</span></p>
                                <p>Node HR top range border: <span>{watchData?.data?.nodeHrRange?.above}</span></p>
                                <p>Node HR bottom range border: <span>{watchData?.data?.nodeHrRange?.below}</span>
                                </p>
                                <hr/>
                                <p>Current HR: <span>{watchData?.data?.userHR}</span></p>
                                <p>Target difficulty: <span>{watchData?.data?.targetDifficult}</span></p>
                                <p>Current difficulty: <span>{currentPart?.difficult}</span></p>
                                <p>Current part id: <span>{currentPart?.partId}</span></p>
                                <hr/>
                                <p>Completed Parts: <span>{completedParts.map(({partId, difficult}) => `${partId}-${difficult}`).join(' ')}</span></p>
                            </div>
                        )}


                        <div className={'player_modal__video'} id={'vimeo-player'} ref={playerRef}>
                            {!isStartWorkoutModalOpen && !isEndWorkoutModalOpen && !isWorkoutEnd && isPlayerReady && (
                                <>
                                    {isSSEConnected && (
                                        <div className={'player_modal__watch-data'}>
                                            <span
                                                className={`player_modal__watch-data_marker_${getNextNodeDifficult()}`}/>
                                        </div>
                                    )}
                                    {isVideoLoading && <Loader/>}
                                    <div className={'player_modal__video__controls'}>
                                        <span style={{opacity: isPlayerControlsShown ? '1' : '0'}}
                                              className={'player_modal__video__controls__play'}>
                                            {isVideoPlaying ?
                                                <img onClick={pauseButtonHandler} src={pauseButton}
                                                     alt={'pauseButtonIcon'}/> :
                                                <img onClick={playButtonHandler} src={playButtonIcon}
                                                     alt={'playButtonIcon'}/>}
                                        </span>
                                        <div style={{opacity: isPlayerControlsShown ? '1' : '0'}}
                                             className={'player_modal__video__controls__parts'}>
                                            {currentPart?.partId > 0 &&
                                                <img onClick={goToPrevPartClickHandler} src={nextIcon}
                                                     alt={'prevIcon'}/>}
                                            {currentPart?.partId < parts.length - 1 &&
                                                <img onClick={goToNextPartClickHandler} src={nextIcon}
                                                     alt={'nextIcon'}/>}
                                        </div>
                                        {!isIOS && (
                                            <div style={{opacity: isPlayerControlsShown ? '1' : '0'}}
                                                 className={'player_modal__video__controls__fullscreen'}>
                                                <button onClick={gotoFullScreen}>
                                                    <img src={fullscreenIcon} alt={'fullScreen'}/>
                                                </button>
                                            </div>
                                        )}
                                        <span style={{opacity: isPlayerControlsShown ? '1' : '0'}}
                                              className={`player_modal__close_modal`}
                                              onClick={endWorkoutButtonHandler}>X</span>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};
