import {
    forgotPasswordActions,
    forgotPasswordCodeActions,
    removeUserData,
    saveUserData,
    setLoadingStatus
} from "./authenticationActions";
import {
    getUserInfo, postForgotPassword, postForgotPasswordCode,
    postLogoutUser, postResetPassword,
    postSignIn, postSignUp
} from "../../../services/api/authenticationService/authenticationService";
import {
    getLocalAccessToken,
    rememberLocalAuthTokens,
    removeLocalAuthTokens,
} from '../../../services/api/tokenService';
import routerBook from "../../../routes/routerBook";
import {getUserSubscription} from '../../../services/api/paymentService/paymentService';

export const signUpUser = (data, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))

        postSignUp(data).then(({auth, user}) => {
            let dateEnd = new Date(new Date().getTime() + Number.parseInt(auth.expiresIn));

            rememberLocalAuthTokens({...auth, dateEnd})

            /*window.location.reload()*/

            onSuccess && onSuccess()
        }).catch(error => {
            onError && onError(error)
        }).finally(() => dispatch(setLoadingStatus(false)))
    }
}

export const authorizeUser = (credentials, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))

        postSignIn(credentials).then(({user, quizPassed, auth, paymentInformation, isFreeSubscribeAvailable}) => {

            rememberLocalAuthTokens(auth)

            getUserSubscription().then(subscriptionData => {
                dispatch(saveUserData({...user, quizPassed, paymentInformation, isFreeSubscribeAvailable, subscriptionData, isUserSubscribed: true}))
            }).catch(() => dispatch(saveUserData({...user, quizPassed, isFreeSubscribeAvailable, paymentInformation})))

            onSuccess && onSuccess()
        }).catch(error => {
            onError && onError(error)
        }).finally(() => dispatch(setLoadingStatus(false)))
    }
}

export const requestUserData = (onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))

        getUserInfo().then(({user, userAnswers, paymentInformation, isFreeSubscribeAvailable}) => {

            getUserSubscription().then((subscriptionData = []) => {
                dispatch(saveUserData({...user, isFreeSubscribeAvailable, userAnswers, paymentInformation, subscriptionData, isUserSubscribed: true}))
            }).catch(() => dispatch(saveUserData({...user, isFreeSubscribeAvailable, userAnswers, paymentInformation})))

            onSuccess && onSuccess()
        }).catch(error => {
            if (getLocalAccessToken()) {
                localStorage.clear()
                window.location.reload()
            }
            onError && onError(error)
        }).finally(() => dispatch(setLoadingStatus(false)))
    }
}

export const logoutUser = (onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))
        sessionStorage.clear()
        localStorage.clear()
        window.location.reload()
    }
}

export const requestForgotUserPassword = (data, navigate, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))

        postForgotPassword(data).then(({id, code}) => {
            dispatch(forgotPasswordActions({id, code}));
            onSuccess && onSuccess()
            navigate(routerBook.checkCode.replace(':id', id));
        }).catch((error) => {
            onError && onError(error)
        }).finally(() => dispatch(setLoadingStatus(false)))
    };
}

export const requestForgotUserPasswordCode = (data, navigate, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true))

        postForgotPasswordCode(data).then(() => {
            dispatch(forgotPasswordCodeActions({...data}));
            onSuccess && onSuccess()
            navigate(routerBook.resetPassword.replace(':id', data.id));
        }).catch((error) => {
            onError && onError(error)
        }).finally(() => dispatch(setLoadingStatus(false)))
    }
}


export const requestResetUserPassword = (data, navigate, onSuccess, onError) => {
    return dispatch => {
        postResetPassword(data).then(() => {
            onSuccess && onSuccess()
            navigate(routerBook.login);
        }).catch((error) => {
            onError && onError(error)
        }).finally(() => dispatch(setLoadingStatus(false)))
    }
}

