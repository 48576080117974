import FromCreditCard from './component/fromCreditCard/fromCreditCard';
import SliderPayment from './component/sliderPayment/sliderPayment';
import './styles.scss';
import {withTranslation} from 'react-i18next';
import logo from "../../assets/images/header/logo.svg";
import {withAuthUser} from "../../hocs/withAuthUser";
import {useNavigate} from "react-router-dom";
import routerBook from "../../routes/routerBook";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Loader} from "../../components/loader/loader";
import {createFreeSubscription} from "../../services/api/paymentService/paymentService";
import {errorHandler} from "../../components/toastify/redux/notifyOperations";

const PaymentPage = ({t}) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()

    const {isFreeSubscribeAvailable} = useSelector(store => store.authState.data)

    useEffect(() => {
        if (isFreeSubscribeAvailable && localStorage.getItem('isFree') === 'true') {
            createFreeSubscription().then(res => {
                localStorage.removeItem('isFree')
                window.location.reload();
            }).catch( error => {
                localStorage.removeItem('isFree')
                dispatch(errorHandler(error.message))
                setIsLoading(false)
            })
        } else {
            setIsLoading(false)
        }
    }, [isFreeSubscribeAvailable])



    return isLoading ? <Loader/> : (
        <div className="payment-page">
            <img onClick={navigate.bind(null, routerBook.main)} className={'payment-page-logo'} src={logo} alt={'levl_logo'}/>
            <SliderPayment/>
            <FromCreditCard setIsLoading={setIsLoading}/>
        </div>
    );
};
export default withAuthUser(withTranslation()(PaymentPage));
