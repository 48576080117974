import React from 'react';
import { withTranslation } from 'react-i18next';
import routerBook from '../../../../../routes/routerBook'

import background from '../../../../../assets/images/myPlan/dayOffBackground.png';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

const DayOff = ({ t }) => {
    const navigate = useNavigate();
    return (
        <div className='off-day'>
            <div className='off-day__content'>
                <img className='off-day__content-img' src={background} alt='day_off_background' />
            </div>
            <button onClick={navigate.bind(null, routerBook.viewPlan)}
                    className='off-day__view-plan'>{t('plan_view')}</button>
        </div>
    );
};

export default withTranslation()(DayOff);
