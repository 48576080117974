import React from 'react';
import {useForm} from 'react-hook-form';
import {withTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigate, useParams} from 'react-router-dom';

import {Button, Input} from '../../../../components/ui-core';
import {checkCodeSchema} from './checkCode.schema';

import './styles.scss';
import {useDispatch} from 'react-redux';
import {requestForgotUserPasswordCode} from "../../redux/authenticationOperations";
import { withDefaultUser } from "../../../../hocs/withDefaultUser";
import { errorHandler, successHandler } from '../../../../components/toastify/redux/notifyOperations';
import logo from '../../../../assets/images/header/logo.svg';

const CheckCode = ({t}) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isValid},
    } = useForm({
        mode: 'onChange',
        defaultValues: {},
        resolver: yupResolver(checkCodeSchema),
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {id} = useParams();

    const handleFormSubmit = data => {
        dispatch(requestForgotUserPasswordCode({...data, id}, navigate, () => {
        }, ({message}) => {
            dispatch(errorHandler(message))
        }));
    };

    return (
        <div className="check-page">
            <img className={'login-page-logo'} src={logo} alt={'levl_logo'}/>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <span className="check-page__title">{t('check_code_title')}</span>
                <Input
                    inputChangeOptions={register('code')}
                    errors={errors?.code?.message}
                    options={{
                        placeholderText: `${t('check_code_placeholder')}`,
                    }}
                />
                <Button
                    disabled={!isValid}
                    options={{text: `${t('check_code_button')}`, type: 'submit'}}
                />
            </form>
        </div>
    );
};
export default withDefaultUser(withTranslation()(CheckCode));
