import React from 'react';
import './styles.scss';

const AgeQuestion = ({data, register}) => {
    const {type, id} = data;

    const getData = () => data.enum.map((item, index) => {
        return (
            <option key={'item' + index} value={item}>
                {item}
            </option>
        )
    })

    return (
        <select className="age-select-question" {...register(type + id, {required: true})}>
            {getData()}
        </select>
    );
};
export default AgeQuestion;
