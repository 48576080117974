import { Button } from '../../../../components/ui-core';
import { useNavigate } from 'react-router-dom';
import routerBook from '../../../../routes/routerBook';
import './styles.scss';
import { withTranslation } from 'react-i18next';
import arrow from '../../../../assets/images/myPlan/arrow.svg'

const AnswerToQuestions = ({ t }) => {
    const navigate = useNavigate();
    const _redirectToChallenges = () => {
        navigate(routerBook.challenges);
    };
    const _redirectToQuestion = () => {
        navigate(routerBook.question);
    };
    return (
        <div className='answer-to-questions'>
            <div className='answer-to-questions__left'>
                <p onClick={navigate.bind(null, routerBook.challenges)} className='answer-to-questions__left-title'>
                    {t('plan_answer_questions_challenge')}
                    <img src={arrow} alt={'arrow'}/>
                </p>
            </div>
            <hr />
            <div className='answer-to-questions__right'>
                <h3 className='answer-to-questions__right-title'>
                    Answer a few questions and we will create a challenge for you.
                </h3>
                <Button
                    className='answer-to-questions__right-button'
                    onClick={_redirectToQuestion}
                >
                    {t('plan_answer_questions_button')}
                </Button>
                <p className='answer-to-questions__right-description'>
                    Or go to the{' '}<span onClick={_redirectToChallenges}>Challenges</span>{' '}page and choose from one of our great pre-built programs.
                </p>
            </div>
        </div>
    );
};
export default withTranslation()(AnswerToQuestions);
