import { object, string, date, number, mixed} from 'yup';

export const aboutMeSchema = object().shape({
    email: string()
        .email('This field should be login@email.com')
        .required('This field is required'),
    name: string().required('This field is required'),
    age: mixed(),
    gender: string().required('This field is required'),
    height: string().matches(/^([0-9+]{0,2})\/([0-9+]{0,3})?$/, 'Height must match pattern: ft/inch'),
    weight: string().required('This field is required'),
});
