import {useDispatch, useSelector} from "react-redux";
import {Navigate} from 'react-router-dom'
import {getLocalAccessToken} from "../services/api/tokenService";
import {requestUserData} from "../pages/authentication/redux/authenticationOperations";
import { Loader } from '../components/loader/loader';

export const withDefaultUser = Component => {
    return props => {
        const dispatch = useDispatch()

        const {data, isLoading} = useSelector(store => store.authState)

        if (!data && !getLocalAccessToken()) return <Component {...props}/>

        if (!data && getLocalAccessToken() && !isLoading) dispatch(requestUserData())

        if (isLoading) return <Loader/>

        return <Navigate to={'/dashboard'}/>
    }
 }
