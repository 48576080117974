import {useEffect, useLayoutEffect, useRef} from 'react';
import _ from 'lodash'

export const useKeyPressCombination = (keys, callback, node) => {
    const callbackRef = useRef(callback);

    useLayoutEffect(() => {
        callbackRef.current = callback;
    });

    useEffect(() => {
        const targetNode = node?.current ?? document;

        let pressedKeys = []

        const handleCombinationPress = event => {
            const isKeyInPressed = pressedKeys.includes(event.key.toLowerCase())

            const isKeyInKeysList = keys.map(k => k.toLowerCase()).includes(event.key.toLowerCase())

            if (!isKeyInPressed && isKeyInKeysList) pressedKeys.push(event.key.toLowerCase())
        }

        const handleKeyUp = event => {
            if (_.isEqual(pressedKeys, keys.map(k => k.toLowerCase()))) callbackRef.current(event)

            pressedKeys = []
        }

        targetNode && targetNode.addEventListener('keydown', handleCombinationPress);
        targetNode && targetNode.addEventListener('keyup', handleKeyUp);

        return () => {
            targetNode && targetNode.removeEventListener('keydown', handleCombinationPress);
            targetNode && targetNode.removeEventListener('keyup', handleKeyUp);
        };

    }, [node]);
};
