import types from "./workoutsTypes";

export const saveWorkoutsList = (items, count) => {
    return {type: types.SAVE_WORKOUTS_LIST, payload: {items, count}}
}

export const clearWorkoutsList = () => {
    return {type: types.CLEAR_WORKOUTS_LIST}
}

export const setWorkoutsLoadingStatus = (isLoading) => {
    return {type: types.SET_IS_LOADING, payload: isLoading}
}