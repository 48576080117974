import { object, string, bool } from 'yup';

export const registrationSchema = object().shape({
  email: string()
    .email('This field should be login@email.com')
    .required('This field is required'),
  name: string().required('This field is required'),
  password: string()
    .required('')
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          " "
      ),
});
