import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactUsSchema } from './contactUsSchema';
import { Button, Input } from '../../../../components/ui-core';
import Textarea from '../../../../components/ui-core/Textarea/textarea';
import './styles.scss';
import exit from '../../../../assets/images/profile/exti.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { setSupport } from '../../redux/profileOperations';
import { openModal } from '../../../../components/modal/redux/modalOperations';
import modalType from '../../../../components/modal/types';
import { closeModal } from '../../../../components/modal/redux/modalActions';
import { withAuthUser } from '../../../../hocs/withAuthUser';

const ContactUs = ({ t }) => {
    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'all',
        defaultValues: {},
        resolver: yupResolver(contactUsSchema),
    });

    const dispatch = useDispatch();

    const handleFormSubmit = data => {
        dispatch(setSupport(data, () => {
            reset()
        }));
    };

    return (
        <div className='contact-us'>
            <div className='contact-us__content'>
                <div
                    className='contact-us__content-back'
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <img alt={'img'} src={exit} className='account__title-image' />
                    <p className='account__title-text'>
                        {t('profile_contact_us_contact')}
                    </p>
                </div>
                <p className='contact-us__content-title'>
                    {t('profile_contact_us_title')}
                </p>
                {/*<p className="contact-us__content-description">*/}
                {/*    {t('profile_contact_us_description')}*/}
                {/*</p>*/}
            </div>
            <form
                onSubmit={handleSubmit(handleFormSubmit)}
                className='contact-us__form'
            >
                <Input
                    inputChangeOptions={register('name')}
                    errors={errors?.name?.message}
                    options={{
                        styleType: 'contact',
                        labelText: t('profile_contact_us_placeholder_name'),
                    }}
                />
                <Input

                    inputChangeOptions={register('email')}
                    errors={errors?.email?.message}
                    options={{
                        styleType: 'contact',
                        labelText: t('profile_contact_us_placeholder_email'),
                    }}
                />

                <Textarea options={{
                    styleType: 'contact',
                    labelText: t('profile_contact_us_placeholder_comment'),
                }}
                          errors={errors?.comment?.message}
                          inputChangeOptions={register('comment')}
                />

                <Button options={{ type: 'submit', renderType: 'black' }}>
                    {t('profile_contact_us_send')}
                </Button>
            </form>
        </div>
    );
};

export default withAuthUser(withTranslation()(ContactUs));
