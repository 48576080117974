import {Button} from '../../../../components/ui-core';
import {useNavigate} from 'react-router-dom';
import routerBook from '../../../../routes/routerBook';
import banner1 from '../../../../assets/images/questions/carousel/1.jpg'
import banner2 from '../../../../assets/images/questions/carousel/2.jpg'
import banner3 from '../../../../assets/images/questions/carousel/3.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import './styles.scss'
import {Carousel} from "react-responsive-carousel";

const FinishPage = ({data}) => {
    const navigation = useNavigate();

    const calculateTime = () => {
        let d = new Date()
        let time = Math.ceil((data[0].answer[2].text - localStorage.getItem('idealw')) / 2.5) * 7
        if (time < 30) time = 30
        d.setDate(d.getDate() + time)
        return `${d.toLocaleString('en', {month: 'long'})} ${d.getDate()}`
    }

    return (
        <div className='finish-page'>
            <div className={'finish-page__content'}>
                <div className={'finish-page__prediction mobile'}>
                    <p>Based on your answers, you'll be...</p>
                    <span>{localStorage.getItem('idealw')} lbs by {calculateTime()}</span>
                </div>
                <Carousel autoPlay={true} className={'finish-page__content__carousel'} showThumbs={false} showArrows={false}
                          showStatus={false}>
                    <div className={'finish-page__content__carousel__banner__image-wrapper'}>
                        <img src={banner1} alt={'banner1'}/>
                    </div>
                    <div className={'finish-page__content__carousel__banner__image-wrapper'}>
                        <img src={banner2} alt={'banner2'}/>
                    </div>
                    <div className={'finish-page__content__carousel__banner__image-wrapper'}>
                        <img src={banner3} alt={'banner3'}/>
                    </div>
                </Carousel>
                <div className={'finish-page__content__text'}>
                    <div className={'finish-page__prediction desktop'}>
                        <p>Based on your answers, you'll be...</p>
                        <span>{localStorage.getItem('idealw')} lbs by {calculateTime()}</span>
                    </div>
                    <hr className={'desktop'}/>
                    <h3>Start your journey to getting <br/> the results you have always wanted!</h3>
                </div>
            </div>


            <Button onClick={() => window.location.reload()} className="constructor-question-got">
                Get Started
            </Button>
        </div>
    );
};
export default FinishPage;
