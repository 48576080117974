import { Route, Routes } from 'react-router-dom';
import { routerSchema } from './routerSchema';

const Router = () => {
    return (
        <Routes>{routerSchema.map(RouteElement)}</Routes>
    );
};

const RouteElement = ({ children, parentKey = '', ...args }, index) => {
    return (
        <Route key={`${parentKey}${index}`}
               children={children?.map((route, _index) => {
                   return RouteElement({
                       ...route, parentKey: `${parentKey}${index}`,
                   }, _index);
               })}
               {...args}
        />
    );
};

export default Router;
