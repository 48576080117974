import { Button } from '../../../../components/ui-core';
import HeightWeightQuestion from '../heightWeightQuestion/heightWeightQuestion';
import AgeQuestion from '../ageQuestion/ageQuestion';
import ChekBoxQuestion from '../chekBoxQuestion/chekBoxQuestion';
import InfoQuestion from '../infoQuestion/infoQuestion';
import SelectQuestion from '../selectQuestion/selectQuestion';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    postQuestions, saveAnswer,
} from '../../redux/questionOperations';
import PereparingPlan from '../pereparingPlan/pereparingPlan';
import { useForm } from 'react-hook-form';
import FinishPage from '../finishPage/finishPage';
import routerBook from '../../../../routes/routerBook';
import { useNavigate } from 'react-router-dom';
import { openModal } from '../../../../components/modal/redux/modalOperations';
import modalType from '../../../../components/modal/types';
import { checkOnboarding } from '../../../../components/onboarding/redux/onboardingActions';
import IdealHeightWeightQuestion from '../idealWeightQuestion/idealHeightWeightQuestion';
import { useCallback, useEffect, useRef, useState } from 'react';

const ConstructorQuestion = ({ data }) => {
        const { type, title, description, subtitle } = data;
        const { questionListLength, activeQuestion, answerList } = useSelector(state => (state.question));
        const { handleSubmit, register, control } = useForm({ mode: 'onChange', defaultValues: {} });
        const [weight, setWeight] = useState({
            ideal: 0,
            actual: 0,
        });
        const progressRef = useRef(null);
        const dispatch = useDispatch();
        const navigate = useNavigate();

        const getProgressValue = useCallback(() => {
            return type === 'pereparingPlan' ? 100 : (100 / (questionListLength + 1)) * activeQuestion.index;
        }, [type, questionListLength, activeQuestion]);

        const getContent = useCallback(() => {
            switch (type) {
                case 'INPUT':
                    return <IdealHeightWeightQuestion data={data} />;
                case 'MULTI_INPUT':
                    return <HeightWeightQuestion data={data} register={register}
                                                 control={control} />;
                case 'DROPDOWN':
                    return <AgeQuestion data={data} register={register} />;
                case 'SELECT':
                    return <SelectQuestion data={data} register={register} />;
                case 'INFO_SLIDE':
                    return <InfoQuestion data={data} register={register} />;
                case 'MULTI_SELECT':
                    return <ChekBoxQuestion data={data} register={register} />;
                case 'pereparingPlan':
                    return <PereparingPlan register={register} progressRef={progressRef} />;
                case 'FinishPage':
                    dispatch(checkOnboarding(true));
                    return <FinishPage data={answerList} />;
                default:
                    return <p>Oooops :(</p>;
            }
        }, [type, register, control, dispatch, data]);

        const gotIt = useCallback(() => {
            dispatch(postQuestions(data));
        }, [data, dispatch]);

        const closeQuestion = useCallback(() => {
            localStorage.setItem('isQuestionSkipped', true);
            navigate(routerBook.dashboard);
        }, [navigate]);

        const skip = useCallback(() => {
            dispatch(
                openModal({
                    type: modalType.DEFAULT_MODAL,
                    data: {
                        title: 'modal_leave_questions_title',
                        description: 'modal_leave_questions_description',
                        button_ok_text: 'modal_leave_questions_ok',
                        button_cancel_text: 'modal_leave_questions_cancel',
                        callback_button_ok: closeQuestion,
                    },
                }),
            );
        }, [dispatch, gotIt, activeQuestion, closeQuestion]);

        const onSubmit = useCallback(e => {
            if (activeQuestion.save) {
                let answer = null;
                if (activeQuestion.type === 'DROPDOWN') answer = [{ text: e['DROPDOWN' + activeQuestion.id].toString() }];
                else if (activeQuestion.type === 'SELECT') answer = [{ id: e['SELECT' + activeQuestion.id].toString() }];
                else if (activeQuestion.type === 'MULTI_SELECT') {
                    answer = Object.keys(e).reduce((acum, item) => {
                        if (e[item]) {
                            let a = item.split('/')[1];
                            acum.push({ id: parseInt(a).toString() });
                            return acum;
                        }
                        return acum;
                    }, []);
                } else {
                    answer = activeQuestion.answers.map(({ id, unit }) => ({ id: id, text: parseInt(e[unit]).toString() }));
                }
                if (answer && (Array.isArray(answer) ? answer.length : true)) {
                    gotIt();
                    dispatch(saveAnswer({ questionId: activeQuestion.id, answer }));
                }
            } else {
                if (activeQuestion.type === 'MULTI_SELECT') {
                    const answer = Object.keys(e).reduce((acum, item) => {
                        if (e[item]) {
                            let a = item.split('/')[1];
                            acum.push({ id: parseInt(a).toString() });
                            return acum;
                        }
                        return acum;
                    }, []);

                    if (answer?.length) gotIt()
                } else if (activeQuestion.type === 'SELECT') {
                    const answer = [{ id: e['SELECT' + activeQuestion.id].toString() }];

                    if (answer?.length) gotIt()
                } else {
                    gotIt()
                }
            }

        }, [activeQuestion, gotIt, dispatch]);

        const getButton = useCallback(() => {
            if ('pereparingPlan' === type || 'FinishPage' === type) return null;

            if (questionListLength !== activeQuestion.index) {
                return (
                    <div className='constructor-question-buttons'>
                        <Button options={{ type: 'submit' }} className='constructor-question-got'>
                            {'Continue'}
                        </Button>
                        <Button className='constructor-question-skip' onClick={skip}>Skip</Button>
                    </div>);
            } else {
                return (
                    <div className='constructor-question-buttons'>
                        <p className='constructor-question-buttons-thank'>Thank You!</p>
                        <Button options={{ type: 'submit' }} className='constructor-question-got'>
                            See Results
                        </Button>
                    </div>
                );
            }

        }, [questionListLength, activeQuestion.index, skip, gotIt, type]);

        const questionTextReplacement = () => {
            if (+activeQuestion.id === +3) {
                return activeQuestion.description
                    .replace(/20s/, `&&&&`)
                    .replace(/47 lbs/, `&&&&`)
                    .replace(/57 lbs/, `&&&&`).split('&&&&').map((text, index) => {
                        if (index === 0) return text
                        if (index === 1) return (
                            <>
                                <span> {answerList[1]?.answer[0]?.text} </span>
                                {text}
                            </>
                        )
                        if (index === 2) return (
                            <>
                                <span>{`${+localStorage.getItem('idealw') - 5} lbs`}</span>
                                {text}
                                <span>{`${+localStorage.getItem('idealw') + 5} lbs`}</span>
                            </>
                        )

                        return text
                    })
            }
            return activeQuestion.description;
        };

        return (
            <form className='constructor-question' onSubmit={handleSubmit(onSubmit)}>

                {type !== 'FinishPage' && (
                    <progress className='constructor-question-progress-bar'
                              id='file' max='100' value={getProgressValue()} ref={progressRef}>
                    </progress>
                )}

                <div className='constructor-question-body'>
                    {title && <h2 className='constructor-question-title'>{title}</h2>}

                    {subtitle && <h2 className='constructor-question-subtitle'>{subtitle}</h2>}

                    {description && <p className='constructor-question-description'>{questionTextReplacement()}</p>}

                    {getContent()}
                </div>

                {getButton()}

            </form>
        );
    }
;
export default ConstructorQuestion;
