import React from 'react';
import './styles.scss';

const Textarea = ({
  options = {},
  inputChangeOptions = {},
  errors = false,
}) => {
  const { labelText = '', placeholderText = '', approved = false } = options;

  return (
    <label className="textarea">
      <p className="textarea-label">{labelText}</p>
      <textarea
        maxLength={250}
        readOnly={approved || approved === null}
        {...inputChangeOptions}
        placeholder={placeholderText}
        className={`${
          errors ? 'textarea-error' : 'textarea-fine'
        } : textarea-item`}
      />
      <p className="textarea-errors">{errors}</p>
    </label>
  );
};
export default Textarea;
