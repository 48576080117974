import headerLogo from '../../../../assets/images/Landing/header-logo.svg';
import { Link, NavLink, useLocation } from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import routerBook from '../../../../routes/routerBook';
import {useSelector} from "react-redux";

const SecondaryHeader = ({ t }) => {
    const { hash } = useLocation();

    const { data, isLoading } = useSelector(store => store.authState);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const [isScrollingDown, setIsScrollingDown] = useState(false)

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.scrollY;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.scrollY;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setIsScrollingDown(scrollY > lastScrollY);

            lastScrollY = scrollY > 0 ? scrollY : 0;

            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [isScrollingDown]);

    return (
        <header data-hide={isScrollingDown} className='landing-header'>
            <Link className={'landing-header__logo'} to={'#'}><img src={headerLogo} alt={'header-logo'} /></Link>
            <div className={'landing-header__links'}>
                <a className={hash === '' ? 'landing-header__links__active' : ''} href={'#'}>Home</a>
                <a className={hash === '#how-it-works' ? 'landing-header__links__active' : ''} href={'#how-it-works'}>How
                    it works</a>
                <a className={hash === '#about-us' ? 'landing-header__links__active' : ''} href={'#about-us'}>About
                    Us</a>
                {!data && <NavLink className='landing-header__links__login-button' to={routerBook.login}>Login</NavLink>}
                <NavLink className='landing-header__links__start-button' to={routerBook.registration}>Start Free
                    Challenge</NavLink>
            </div>
            <div className='landing-header__mobile-menu'>
                <button data-is-open={isMobileMenuOpen} onClick={setIsMobileMenuOpen.bind(null, !isMobileMenuOpen)} className='landing-header__mobile-menu__burger'>
                    <svg width='21' height='15' viewBox='0 0 21 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M0.0833282 1.24992C0.0833282 0.973651 0.193075 0.7087 0.388425 0.513349C0.583776 0.317999 0.848728 0.208252 1.12499 0.208252H19.875C20.1513 0.208252 20.4162 0.317999 20.6116 0.513349C20.8069 0.7087 20.9167 0.973651 20.9167 1.24992C20.9167 1.52619 20.8069 1.79114 20.6116 1.98649C20.4162 2.18184 20.1513 2.29159 19.875 2.29159H1.12499C0.848728 2.29159 0.583776 2.18184 0.388425 1.98649C0.193075 1.79114 0.0833282 1.52619 0.0833282 1.24992Z'
                            fill='black' />
                        <path
                            d='M0.0833282 7.53337C0.0833282 7.2571 0.193075 6.99215 0.388425 6.7968C0.583776 6.60145 0.848728 6.4917 1.12499 6.4917H19.875C20.1513 6.4917 20.4162 6.60145 20.6116 6.7968C20.8069 6.99215 20.9167 7.2571 20.9167 7.53337C20.9167 7.80963 20.8069 8.07459 20.6116 8.26994C20.4162 8.46529 20.1513 8.57503 19.875 8.57503H1.12499C0.848728 8.57503 0.583776 8.46529 0.388425 8.26994C0.193075 8.07459 0.0833282 7.80963 0.0833282 7.53337Z'
                            fill='black' />
                        <path
                            d='M1.12499 12.7749C0.848728 12.7749 0.583776 12.8847 0.388425 13.08C0.193075 13.2754 0.0833282 13.5403 0.0833282 13.8166C0.0833282 14.0928 0.193075 14.3578 0.388425 14.5531C0.583776 14.7485 0.848728 14.8582 1.12499 14.8582H19.875C20.1513 14.8582 20.4162 14.7485 20.6116 14.5531C20.8069 14.3578 20.9167 14.0928 20.9167 13.8166C20.9167 13.5403 20.8069 13.2754 20.6116 13.08C20.4162 12.8847 20.1513 12.7749 19.875 12.7749H1.12499Z'
                            fill='black' />
                    </svg>
                </button>

                <div onClick={setIsMobileMenuOpen.bind(null, false)} data-is-open={isMobileMenuOpen} className='landing-header__mobile-menu__links'>
                    <a className={hash === '' ? 'landing-header__mobile-menu__links__active' : ''} href={'#'}>Home</a>
                    <a className={hash === '#how-it-works' ? 'landing-header__mobile-menu__links__active' : ''} href={'#how-it-works'}>How
                        it works</a>
                    <a className={hash === '#about-us' ? 'landing-header__mobile-menu__links__active' : ''} href={'#about-us'}>About
                        Us</a>
                    {!data && <NavLink to={routerBook.login}>Login</NavLink>}
                    <NavLink to={routerBook.registration}>Start Free
                        Challenge</NavLink>
                </div>
            </div>
        </header>
    );
};
export default withTranslation()(SecondaryHeader);
