import './style.scss';
import { withAuthUser } from '../../../../hocs/withAuthUser';
import exit from '../../../../assets/images/profile/exti.svg';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import watch from '../../../../assets/images/profile/watch.svg';
import smallWatch from '../../../../assets/images/profile/small_watch.svg';
import { connectWatch, disconnectWatch } from '../../../../services/api/watchService/watchService';
import { errorHandler, successHandler } from '../../../../components/toastify/redux/notifyOperations';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../../components/modal/redux/modalOperations';
import constModal from '../../../../components/modal/types';

const Device = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector(store => store.authState);
    const [step, setStep] = useState(data?.watch?.id ? 'list' : 'no-device');
    const [verificationCode, setVerificationCode] = useState('');

    const verificationCodeHandler = ({ target: { value } }) => {
        setVerificationCode(value);
    };

    const backClickHandler = () => {
        if (data?.watch?.id) navigate(-1);
        else {
            switch (step) {
                case 'list':
                    setStep('verification');
                    break;
                case 'verification':
                    setStep('no-device');
                    break;
                default:
                    navigate(-1);
            }
        }

    };

    const createWatchName = () => {
        if (data?.watch?.name) return data?.watch?.name
        const watchIdString = data?.watch?.id?.split('-').join('');
        if (watchIdString)
            return [watchIdString.slice(0, 4), '...', watchIdString.slice(-4)];
        return '';
    };

    const sendConnectRequest = () => {
        connectWatch(verificationCode).then(response => {
            window.location.reload();
        }).catch((error) => dispatch(errorHandler(error)));
    };

    const sendUnsubscribeRequest = () => {
        disconnectWatch().then(response => {
            window.location.reload();
        }).catch((error) => dispatch(errorHandler(error)));
    };

    const modalWindowHandler = () => {
        dispatch(openModal({
            type: constModal.DEFAULT_MODAL, data: {
                title: 'Are you sure ?',
                description: 'You will no longer be able to sync information across your devices.',
                button_cancel_text: 'Cancel',
                button_ok_text: 'Disconnect',
                callback_button_ok: sendUnsubscribeRequest
            },
        }));
    };

    const getContent = () => {
        switch (step) {
            case 'verification':
                return (
                    <div className={'device__content__verification'}>
                        <p>Enter the verification code</p>
                        <small>Please enter the verification code generated from your Smart watch in order to
                            connect</small>
                        <input maxLength={7} placeholder={'confirmation code'} value={verificationCode}
                               onChange={verificationCodeHandler} />
                        <button onClick={sendConnectRequest}>Continue</button>
                    </div>
                );
            case 'list':
                return (
                    <div className={'device__content__list'}>
                        <div className={'device__content__list__watch'}>
                            <img src={smallWatch} alt={'small_watch'} />
                            <div className={'device__content__list__watch__info'}>
                                <b>Smart watch - {createWatchName()}</b>
                                <small>Connected</small>
                            </div>
                        </div>
                        <p>You can disconnect your device to stop record your data.</p>
                        <button onClick={modalWindowHandler}>Disconnect</button>
                    </div>
                );
            default:
                return (
                    <div className={'device__content__hello'}>
                        <img src={watch} alt={'watch__image'} />
                        <b>Connect your device</b>
                        <p>Connect your device to LEVL to optimize your workouts.</p>
                        <button onClick={setStep.bind(null, 'verification')}>Connect</button>
                    </div>
                );
        }
    };

    return (
        <div className={'device'}>
            <div className='device__content'>
                <div className='device__content-back' onClick={backClickHandler}>
                    <img alt={'img'} src={exit} className='account__title-image' />
                    <p className='account__title-text'>
                        Connected device
                    </p>
                </div>
                {getContent()}
            </div>
        </div>
    );
};

export default withAuthUser(Device);
