import types from './questionTypes';

export const setQuestionList = data => ({
  type: types.SET_QUESTION_LIST,
  payload: data,
});
export const setPercentQuestion = data => ({
  type: types.SET_PERCENT_QUESTION,
  payload: data,
});
export const setActiveQuestion = data => ({
  type: types.SET_ACTIVE_QUESTION,
  payload: data,
});
export const finishLoaderQuestion = () => ({
  type: types.FINISH_LOADER_QUESTION,
});
export const startLoaderQuestion = () => ({
  type: types.START_LOADER_QUESTION,
});
export const setAnswer=(payload)=>({
  type: types.SET_ANSWER,
  payload: payload,
})