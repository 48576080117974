import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import dummy from '../../../../../assets/images/myPlan/dummy.svg';
import clock from '../../../../../assets/images/myPlan/clock.svg';
import fire from '../../../../../assets/images/myPlan/fire.svg';
import './styles.scss';
import { openModal } from '../../../../../components/modal/redux/modalOperations';
import { changeDifficulty, getWorkoutById } from '../../../../../services/api/workoutsService/workoutsService';
import { PlayerModal } from '../../../../workouts/player/playerModal';
import { fromSecondsToMinutes } from '../../../../../utils/globalUtils';
import {
    getProgress,
    updatePlanFromDay,
} from '../../../../../services/api/planService/planService';
import { useNavigate } from 'react-router-dom';
import routerBook from '../../../../../routes/routerBook';
import constModal from '../../../../../components/modal/types';
import { errorHandler } from '../../../../../components/toastify/redux/notifyOperations';
import modalType from '../../../../../components/modal/types';
import {useKeyPressCombination} from "../../../../../hooks/useKeyPressCombination";

const ActiveDay = ({
                       t,
                       setIsPlayerClosed,
                       setIsCompleted,
                       selectedDay,
                       weekData,
                       showPlayerHint,
                       showAltHint,
                       showViewHint,
                       showWeekHint,
                       hintHandler,
                       setCompletedDayId,
                       setCompletedWorkoutType,
                       setShowRatePopup,
                       setWatchDataAction,
                       progress,
                   }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [query] = useSearchParams();

    const videoRef = useRef();
    const workoutRef = useRef();
    const viewPlanRef = useRef();

    const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
    const [mainWorkout, setMainWorkout] = useState({});
    const [altWorkout, setAltWorkout] = useState({});
    const [selectedWorkout, setSelectedWorkout] = useState('main');
    const [preview, setPreview] = useState(false)
    const [isWorkoutPassed, setIsWorkoutPassed] = useState(false);

    useEffect(() => {
        if (viewPlanRef?.current && showViewHint) {
            window.document.body.style.overflow = 'hidden';
            window.scrollTo(0, viewPlanRef.current.getBoundingClientRect().y);
        } else if (workoutRef?.current && showAltHint) {
            window.document.body.style.overflow = 'hidden';
            window.scrollTo(0, workoutRef.current.getBoundingClientRect().y - 600);
        } else if (videoRef?.current && showPlayerHint) {
            window.document.body.style.overflow = 'hidden';
            window.scrollTo(0, videoRef.current.getBoundingClientRect().y);
        } else if (showWeekHint) {
            window.document.body.style.overflow = 'hidden';
        } else {
            window.document.body.style.overflow = 'auto';
        }
    }, [viewPlanRef, showViewHint, showAltHint, showPlayerHint, showWeekHint]);

    useEffect(() => {
        setCompletedWorkoutType && setCompletedWorkoutType(selectedWorkout);
    }, [setCompletedWorkoutType, selectedWorkout]);

    useEffect(() => {
        setIsPlayerClosed(!isPlayerModalOpen);

        if (!isPlayerModalOpen) {
            setPreview(false)
        }
    }, [isPlayerModalOpen, setIsPlayerClosed]);

    useEffect(() => {
        getWorkoutById(selectedDay?.workout?.id).then(response => {
            setMainWorkout(response);
        }).catch((error) => dispatch(errorHandler(error)));
    }, [selectedDay]);

    useEffect(() => {
        if (query && mainWorkout?._id && altWorkout?._id) {
            const actionType = query.get('action');
            const workoutType = query.get('workoutType');
            switch (actionType) {
                case 'playAfterRefresh':
                    if (workoutType) {
                        if (workoutType === 'main' || workoutType === 'alternative') {
                            setSelectedWorkout(workoutType);
                            setIsPlayerModalOpen(true);
                        } else navigate('/dashboard', { replace: true });
                    }
                    navigate('/dashboard', { replace: true });
                    break;
                default:
                    navigate('/dashboard', { replace: true });
                    break;
            }
        }
    }, [mainWorkout, altWorkout]);

    useEffect(() => {
        getWorkoutById(selectedDay?.altWorkout?.id).then(response => {
            setAltWorkout(response);
        }).catch((error) => dispatch(errorHandler(error)));
    }, [selectedDay]);

    const updatePlanHandler = (day, workoutType) => {
        updatePlanFromDay(day).then(() => {
            navigate('/dashboard?action=playAfterRefresh&workoutType=' + workoutType);
            window.location.reload();
        }).catch((error) => dispatch(errorHandler(error)));
    };

    const openUpdatePlanModalHandler = (day, workoutType) => {
        dispatch(openModal({
            type: constModal.BEFORE_VIDEO_MODAL,
            data: {
                currentDay: weekData.currentDay,
                selectedDay: selectedDay.day,
                _continue: () => {
                    setPreview(true)
                    setSelectedWorkout(workoutType);
                    setIsPlayerModalOpen(true);
                },
                _updatePlan: () => updatePlanHandler(selectedDay.day, workoutType),
            },
        }));
    };

    const selectWorkoutToDo = (workoutType) => {
        const askFlag = localStorage.getItem('update-plan-ask-flag');
        const askAction = localStorage.getItem('update-plan-ask-action');

        if (!selectedDay.currentDay) {
            if (weekData.currentDay < selectedDay.day) {
                if (askFlag === 'false') {
                    switch (askAction) {
                        case 'continue':
                            setPreview(true)
                            setSelectedWorkout(workoutType);
                            setIsPlayerModalOpen(true);
                            break;
                        case 'updatePlan':
                            updatePlanHandler(selectedDay.day, workoutType);
                            break;
                        default:
                            openUpdatePlanModalHandler(selectedDay.day, workoutType);
                            break;
                    }
                } else openUpdatePlanModalHandler(selectedDay.day, workoutType);
            } else {
                setSelectedWorkout(workoutType);
                setIsPlayerModalOpen(true);
            }
        } else {
            setSelectedWorkout(workoutType);
            setIsPlayerModalOpen(true);
        }
    };

    const _hintHandler = () => {
        if (showAltHint) hintHandler('altWorkout');
        else if (showPlayerHint) hintHandler('player');
        else hintHandler('viewPlan');
    };

    const onPlayerClose = () => {
        setIsWorkoutPassed(true);
    };

    return (
        <>
            {(showAltHint || showPlayerHint || showViewHint) &&
                <span className={'next_hint'} onClick={_hintHandler}>Next</span>}
            {isPlayerModalOpen && <PlayerModal
                setIsWorkoutCompleted={setIsCompleted}
                setShowRatePopup={setShowRatePopup}
                currentDay={selectedDay}
                setCompletedDayId={setCompletedDayId}
                setWatchDataAction={setWatchDataAction}
                onClose={onPlayerClose}
                preview={preview}
                closeModal={setIsPlayerModalOpen.bind(null, false)}
                progressId={progress?._id}
                id={selectedWorkout === 'main' ? mainWorkout._id : altWorkout._id}
                title={selectedWorkout === 'main' ? mainWorkout.title : altWorkout.title}
                link={selectedWorkout === 'main' ? mainWorkout.video.link : altWorkout.video.link}
                parts={selectedWorkout === 'main' ? mainWorkout.video.parts : altWorkout.video.parts}

            />}
            <div
                className={`active-day__content`}>
                <div className='active-day__content-left'>
                    <div className={`active-day__content-left__video`} data-after={t('hint_video')} ref={videoRef}>
                        <div
                            className={`active-day__content-left__video__hint${showPlayerHint ? ' hint player__hint' : ''}`} />
                        <img src={mainWorkout?.video?.thumbnail || dummy} alt='video' />
                        <button className='active-day__content-left__video-btn' type='button'
                                onClick={selectWorkoutToDo.bind(null, 'main')}
                        >
                            {t('plan_start_button')} {selectedDay.day}
                        </button>
                    </div>
                    <div className='active-day__content-left__info'>
                        <div className='active-day__content-left__info-left'>
                            <div className='active-day__content-left__info-left-title'>
                                {mainWorkout?.title}
                            </div>
                            <div className='active-day__content-left__info-left-info'>
                                <img
                                    className='active-day__content-left__info-left-info__img'
                                    src={clock}
                                    alt='clock'
                                />
                                {fromSecondsToMinutes(mainWorkout?.video?.duration)}
                            </div>
                        </div>
                        <div className='active-day__content-left__info-right'>
                            <div className='active-day__content-left__info-right-title'>{t('plan_equipment')}:</div>
                            <div className='active-day__content-left__info-right-subtitle'>
                                {mainWorkout?.equipments?.join(', ')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='active-day__content-right'>
                    <div className='active-day__content-right__workout' data-after={t('hint_workout')} ref={workoutRef}>
                        <div
                            className={`active-day__content-right__workout__hint${showAltHint ? ' hint alt__hint' : ''}`} />
                        <p className='active-day__content-right__workout__title'>{t('plan_alternative')}</p>
                        <div onClick={selectWorkoutToDo.bind(null, 'alternative')}
                             className='active-day__content-right__workout__video'
                        >
                            <img
                                className='active-day__content-right__workout__video-img'
                                src={altWorkout?.video?.thumbnail || dummy}
                                alt='video'
                            />
                        </div>
                        <div className='active-day__content-right__workout__description'>
                            <div className='active-day__content-right__workout__description-title'>
                                {altWorkout?.title}
                            </div>
                            <div className='active-day__content-right__workout__description-duration'>
                                <img
                                    className='active-day__content-right__workout__description-duration-clock'
                                    src={clock}
                                    alt='clock'
                                />
                                {fromSecondsToMinutes(altWorkout?.video?.duration)}
                            </div>
                        </div>
                        <div className='active-day__content-right__workout__equipment'>
                            <div className='active-day__content-right__workout__equipment-title'>
                                {t('plan_equipment')}:
                            </div>
                            <div className='active-day__content-right__workout__equipment__subtitle'>
                                {altWorkout?.equipments?.join(', ')}
                            </div>
                        </div>
                    </div>
                    <div className='active-day__content-right__view-plan'
                         data-after={t('hint_view_plan')}
                         ref={viewPlanRef}>
                        <button onClick={() => {
                            if (!showViewHint && !showAltHint && !showPlayerHint && !showWeekHint)
                                navigate(routerBook.viewPlan);
                        }} className='active-day__content-right__view-plan__btn'>{t('plan_view')}
                            <div
                                className={`active-day__content-right__view-plan__btn__hint${showViewHint ? ' hint view-plan__hint' : ''}`} />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(ActiveDay);
