import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { PercentCircle } from '../../../../../components/ui-core';
import dummy from '../../../../../assets/images/myPlan/dummy.svg';
import prize from '../../../../../assets/images/myPlan/prize.svg';
import './styles.scss';
import { changeDifficulty, getWorkoutById } from '../../../../../services/api/workoutsService/workoutsService';
import { fromSecondsToMinutes } from '../../../../../utils/globalUtils';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { errorHandler } from '../../../../../components/toastify/redux/notifyOperations';
import routerBook from '../../../../../routes/routerBook';
import { openModal } from '../../../../../components/modal/redux/modalOperations';
import constModal from '../../../../../components/modal/types';
import modalType from '../../../../../components/modal/types';
import { Loader } from '../../../../../components/loader/loader';
import { closeModal } from '../../../../../components/modal/redux/modalActions';
import {PlayerModal} from "../../../../workouts/player/playerModal";

const reloadTimeOut = 500;

const CompletedDay = ({
                          t,
                          selectedDay,
                          detailedDayInfo,
                          setShowRatePopup,
                          showRatePopup,
                          showStats = true,
                          completedWorkoutType,
                          setNeedToUpdate,
                          watchDataAction,
                          progress,
                          planId,
                      }) => {
    const dispatch = useDispatch();
    const [mainWorkout, setMainWorkout] = useState({});
    const [altWorkout, setAltWorkout] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
    const [selectedWorkout, setSelectedWorkout] = useState(null)
    const navigate = useNavigate();

    const getPlanType = () => {
        if (progress.length) {
            const [planInfo] = progress.filter(el => {
                return el.planId === planId;
            });

            return planInfo.type;
        }
    };

    const actionsHandler = (action) => {
        switch (action) {
            case 'INCREASE':
            case 'DECREASE':
                changeDifficulty(action)
                    .then(r => setTimeout(() => {
                        setNeedToUpdate(true);
                    }, reloadTimeOut))
                    .catch(error => dispatch(errorHandler(error)));
                break;
            case 'SHOW_INCREASE':
                dispatch(
                    openModal({
                        type: modalType.DEFAULT_MODAL,
                        data: {
                            callback_button_ok: () => changeDifficulty('INCREASE')
                                .then(r => setTimeout(() => {
                                    setNeedToUpdate(true);
                                }, reloadTimeOut))
                                .catch(error => dispatch(errorHandler(error))),
                            callback_button_cancel: () => setTimeout(() => {
                                setNeedToUpdate(true);
                            }, reloadTimeOut),
                            title: 'Would you like to increase the difficulty for future workouts?',
                            button_ok_text: 'Yes',
                            button_cancel_text: 'No',
                        },
                    }),
                );
                break;
            case 'SHOW_DECREASE':
                dispatch(
                    openModal({
                        type: modalType.DEFAULT_MODAL,
                        data: {
                            callback_button_ok: () => changeDifficulty('DECREASE')
                                .then(r => setTimeout(() => {
                                    setNeedToUpdate(true);
                                }, reloadTimeOut))
                                    .catch(error => dispatch(errorHandler(error))),
                            callback_button_cancel: () => setTimeout(() => {
                                setNeedToUpdate(true);
                            }, reloadTimeOut),
                            title: 'Would you like to decrease the difficulty for future workouts?',
                            button_ok_text: 'Yes',
                            button_cancel_text: 'No',
                        },
                    }),
                );
                break;
            default:
                dispatch(closeModal())
                setTimeout(() => {
                    setNeedToUpdate(true);
                }, reloadTimeOut)
        }
    };

    useEffect(() => {
        if (watchDataAction) {
            actionsHandler(watchDataAction)
        }
    }, [watchDataAction]);

    useEffect(() => {
        if (!showStats && showRatePopup) {
            setShowRatePopup(false);
            dispatch(openModal({
                type: constModal.RATE_MODAL,
                data: {
                    callback: actionsHandler,
                    day: selectedDay.day,
                    workoutId: completedWorkoutType === 'main' ? selectedDay?.workout?.id : selectedDay?.altWorkout?.id,
                },
            }));
        } else if (!showStats && !showRatePopup) {
            setNeedToUpdate(true);
        }
    }, [showStats]);

    useEffect(() => {
        if (showStats) {
            setIsLoading(true);
            getWorkoutById(selectedDay?.workout?.id).then(response => {
                setMainWorkout(response);
            }).catch(error => dispatch(errorHandler(error))).finally(() => {
                setIsLoading(false);
            });
        }
    }, [showStats, selectedDay]);

    useEffect(() => {
        if (showStats) {
            setIsLoading(true);
            getWorkoutById(selectedDay?.altWorkout?.id).then(response => {
                setAltWorkout(response);
            }).catch(error => dispatch(errorHandler(error))).finally(() => {
                setIsLoading(false);
            });
        }
    }, [showStats, selectedDay]);

    const percentage = useMemo(() => {
        let percent = 0;

        if (selectedDay.workout.status === 'PASSED') percent += (selectedDay.workout.percent || 0);
        if (selectedDay.altWorkout.status === 'PASSED') percent += (selectedDay.altWorkout.percent || 0);

        return percent;

    }, [selectedDay]);

    const rate = useMemo(() => {
        let rate = 0;

        if (selectedDay.workout.status === 'PASSED') rate += (detailedDayInfo?.workout?.rate || 0);
        if (selectedDay.altWorkout.status === 'PASSED') rate += (detailedDayInfo?.altWorkout?.rate || 0);

        return rate || '-';

    }, [detailedDayInfo, selectedDay]);

    const viewedTime = useMemo(() => {
        let viewedTime = 0;

        if (selectedDay.workout.status === 'PASSED') viewedTime += (detailedDayInfo?.workout?.viewedTime || 0);
        if (selectedDay.altWorkout.status === 'PASSED') viewedTime += (detailedDayInfo?.altWorkout?.viewedTime || 0);

        return fromSecondsToMinutes(viewedTime);
    }, [detailedDayInfo, mainWorkout, altWorkout]);


    const CompletedWorkout = ({ workout, status, onSelect }) => {
        if (status !== 'PASSED') return null;
        return (
            <div className='completed-day__workout-body'>
                <div onClick={onSelect} className='completed-day__workout-body-left'>
                    <img className='completed-day__workout-body-left__img' src={workout?.video?.thumbnail || dummy}
                         alt='video' />
                </div>
                <div className='completed-day__workout-body-right'>
                    <div className='completed-day__workout-body-right__title'>{workout?.title}</div>
                    <div className='completed-day__workout-body-right__content'>
                        <PercentCircle textPadding={''} height={55} width={55} percent={percentage}
                                       className={'completed-day__workout-body-right__content_status'} />
                        <p className='completed-day__workout-body-right__content-right'>{t('plan_day_completed_title')}</p>
                    </div>
                </div>
            </div>
        );
    };

    const DayStatistics = () => {
        const calories = useMemo(() => {
            let tmpCalories = 0;

            if (selectedDay.workout.status === 'PASSED') tmpCalories += (mainWorkout?.calory || 0);
            if (selectedDay.altWorkout.status === 'PASSED') tmpCalories += (altWorkout?.calory || 0);

            return tmpCalories;
        }, []);

        return (
            <div className='completed-day__info-left'>
                <div className='completed-day__info-left__item'>
                    <p className='completed-day__info-left__item-title'>{viewedTime}</p>
                    <p className='completed-day__info-left__item-subtitle'>{t('plan_day_completed_minutes')}</p>
                </div>
                <div className='completed-day__info-left__item'>
                    <p className='completed-day__info-left__item-title'>1</p>
                    <p className='completed-day__info-left__item-subtitle'>{t('plan_day_completed_workouts')}</p>
                </div>
                {/*<div className='completed-day__info-left__item'>
                    <p className='completed-day__info-left__item-title'>{calories}</p>
                    <p className='completed-day__info-left__item-subtitle'>{t('plan_day_completed_calories')}</p>
                </div>*/}
            </div>
        );
    };

    const CurrentDayContent = () => {
        return (
            <div className={'completed-day__current_day'}>
                <img src={prize} alt={'prize'} />
                <b>Well Done!</b>
                <p>You can relax or find a <Link to={'/workouts'}>Workout</Link> you want to do. Enjoy your day!</p>
            </div>
        );
    };

    useEffect(() => {
        if ((selectedDay.status === 'PASSED') && (progress?.length && getPlanType() === 'MY_PLAN') && !selectedDay?.currentDay && !watchDataAction) {
            const day = detailedDayInfo;

            if (!(day.workout.rate || day.altWorkout.rate)) {
                const [planInfo] = progress.filter(el => {
                    return el.planId === planId;
                });

                const completedDays = planInfo.days.filter(({ status }) => status === 'PASSED').sort((day1, day2) => {
                    const mainWorkoutDay1 = day1.workout;
                    const mainWorkoutDay2 = day2.workout;
                    const altWorkoutDay1 = day1.altWorkout;
                    const altWorkoutDay2 = day2.altWorkout;

                    const date1 = mainWorkoutDay1.completedDate || altWorkoutDay1.completedDate;
                    const date2 = mainWorkoutDay2.completedDate || altWorkoutDay2.completedDate;

                    return new Date(date1) - new Date(date2);
                });

                const currentDayIndex = completedDays.findIndex(day => day.day === selectedDay.day);

                let passedWorkout = null;

                if (completedDays[currentDayIndex].workout.status === 'PASSED') {
                    passedWorkout = completedDays[currentDayIndex].workout;
                } else if (completedDays[currentDayIndex].altWorkout.status === 'PASSED') {
                    passedWorkout = completedDays[currentDayIndex].altWorkout;
                }

                if (passedWorkout) {
                    dispatch(openModal({
                        type: constModal.RATE_MODAL,
                        data: {
                            preventPageReload: true,
                            callback: actionsHandler,
                            day: selectedDay.day,
                            preventReload: true,
                            workoutId: passedWorkout.id,
                        },
                    }));
                }
            }
        }
    }, [progress, selectedDay]);

    const onDayClick = (type) => {
        setSelectedWorkout('main')
        setIsPlayerModalOpen(true)
    }

    const AnotherDayContent = useCallback(() => {
        return (
            <div className='completed-day'>
                {isPlayerModalOpen && <PlayerModal
                    currentDay={selectedDay}
                    preview={true}
                    closeModal={setIsPlayerModalOpen.bind(null, false)}
                    progressId={progress?._id}
                    id={selectedWorkout === 'main' ? mainWorkout._id : altWorkout._id}
                    title={selectedWorkout === 'main' ? mainWorkout.title : altWorkout.title}
                    link={selectedWorkout === 'main' ? mainWorkout.video.link : altWorkout.video.link}
                    parts={selectedWorkout === 'main' ? mainWorkout.video.parts : altWorkout.video.parts}

                />}
                <div className='completed-day__info'>
                    <DayStatistics />
                    <button onClick={navigate.bind(null, routerBook.viewPlan)}
                            className='completed-day__info-view-plan completed-day__info-view-plan-desktop'>
                        {t('plan_view')}
                    </button>
                </div>
                <p className='completed-day__difficulty'>
                    Your estimate of difficulty :
                    <span className='completed-day__difficulty-count'>{rate}</span>
                </p>
                <div className='completed-day__workout'>
                    <p className='completed-day__workout-title'>{t('plan_day_completed_completed_workout')}</p>
                    <div className='completed-day__workout_list'>
                        <CompletedWorkout onSelect={onDayClick.bind(null, 'main')} workout={mainWorkout} status={selectedDay.workout.status} />
                        <CompletedWorkout onSelect={onDayClick.bind(null, 'alt')} workout={altWorkout} status={selectedDay.altWorkout.status} />
                    </div>
                </div>
                <button onClick={navigate.bind(null, routerBook.viewPlan)}
                        className='completed-day__info-view-plan completed-day__info-view-plan-phone'>
                    {t('plan_view')}
                </button>
            </div>
        );
    }, [selectedDay,isPlayerModalOpen, selectedWorkout, mainWorkout, altWorkout]);

    if (showStats) return <AnotherDayContent />;

    return <CurrentDayContent />;
};

export default withTranslation()(CompletedDay);
