import answerImage from '../../../../assets/images/questions/welcomeBackground.jpg'
import logo from '../../../../assets/images/header/logo.svg'
import './styles.scss';

const WelcomePage = () => {
    return (
        <div className='welcome-page'>
            <h1>Hello, welcome to</h1>
            <img src={logo} alt={'levl_method_logo'}/>
            <img src={answerImage} alt={'hello_image'}/>
            <p>Please answer a few questions so that we could get to know you better.</p>
        </div>
    );
};


export default WelcomePage;
