import { useEffect, useState } from 'react';
import { getQuestionList } from './redux/questionOperations';
import { useDispatch, useSelector } from 'react-redux';
import ConstructorQuestion from './component/constructorQuestion/constructorQuestion';
import './styles.scss';
import WelcomePage from './component/welcomePage/welcomePage';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/header/logo.svg';
import { withAuthUser } from '../../hocs/withAuthUser';

const Question = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { activeQuestion, isShowLoader } = useSelector(state => state.question);
    const [isShowWelcomePage, setIsShowWelcomePage] = useState(true);


    useEffect(() => dispatch(getQuestionList()), [dispatch]);

    useEffect(() => {
        setTimeout(() => setIsShowWelcomePage(false), 3000);
    }, [isShowWelcomePage]);

    const getContent = () => {
        if (isShowWelcomePage) return <WelcomePage />;
        if (isShowLoader) return <ConstructorQuestion data={activeQuestion} />;
        return <div className='question__container-loading'> Lading ...</div>;
    };

    return (
        <div className='question'>
            <img className={'question-logo'} src={logo} alt={'levl_logo'} />
            {getContent()}
        </div>
    );
};

export default withAuthUser(Question);
