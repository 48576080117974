import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from '../../../../components/ui-core';
import './styles.scss';
import exit from '../../../../assets/images/profile/exti.svg';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { aboutMeSchema } from './aboutMeSchema';
import { updateData } from '../../../../services/api/profileService/profileService';
import { useDispatch, useSelector } from 'react-redux';
import { errorHandler, successHandler } from '../../../../components/toastify/redux/notifyOperations';
import { withAuthUser } from '../../../../hocs/withAuthUser';

const AboutMe = ({ t }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector(store => store.authState);

    const {
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'all',
        defaultValues: {
            email: data?.email,
            name: data?.name,
            age: new Date().getFullYear() - new Date(data?.birthday).getFullYear() || '',
            height: data?.height || '',
            weight: data?.weight || '',
            gender: data?.gender || '',
        },
        resolver: yupResolver(aboutMeSchema),
    });
    const handleFormSubmit = data => {
        updateData({
            email: data.email,
            name: data.name,
            gender: data.gender,
            height: data.height,
            weight: data.weight,
        }).then(response => {
            window.location.reload();
        }).catch(error => {
            reset();
            dispatch(errorHandler(error.message));
        });
    };

    return (
        <div className='about-me'>
            <div className='about-me__content'>
                <div className='about-me__content-back' onClick={navigate.bind(null, -1)}>
                    <img alt={'img'} src={exit} className='account__title-image' />
                    <p className='account__title-text'>{t('profile_about_me_contact')}</p>
                </div>
            </div>
            <form onSubmit={handleSubmit(handleFormSubmit)} className='about-me__form'>
                <Input
                    inputChangeOptions={register('email')}
                    errors={errors?.email?.message}
                    options={{
                        labelText: 'E-Mail',
                        styleType: 'contact',
                    }}
                />
                <Input
                    inputChangeOptions={register('name')}
                    errors={errors?.name?.message}
                    options={{
                        labelText: t('profile_about_me_label_first_name'),
                        styleType: 'contact',
                        autocomplete: 'off',
                    }}
                />
                <Input
                    inputChangeOptions={register('age')}
                    errors={errors?.age?.message}
                    options={{
                        isDisabled: true,
                        labelText: 'Age',
                        styleType: 'contact',
                        autocomplete: 'off',
                    }}
                />
                <fieldset>
                    <label className='custom-input__label'>Gender</label>
                    <select style={{padding: '2px 0'}} {...register('gender')} >
                        <option value={'MALE'}>Male</option>
                        <option value={'FEMALE'}>Female</option>
                    </select>
                </fieldset>

                <Input
                    inputChangeOptions={register('height')}
                    errors={errors?.height?.message}
                    format={'##/###"'}
                    options={{
                        labelText: t('profile_about_me_label_height'),
                        styleType: 'contact',
                        autocomplete: 'off',
                    }}
                />
                <Input
                    inputChangeOptions={register('weight')}
                    errors={errors?.weight?.message}
                    options={{
                        labelText: t('profile_about_me_label_weight'),
                        styleType: 'contact',
                        autocomplete: 'off',
                    }}
                />
                <Button options={{ type: 'submit', renderType: 'black' }}>
                    {t('profile_about_me_update')}
                </Button>
            </form>
        </div>
    );
};
export default withAuthUser(withTranslation()(AboutMe));
