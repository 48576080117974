import { combineReducers } from 'redux';
import questionReducer from '../pages/question/redux/questionReducer';
import modalReducer from '../components/modal/redux/modalReducer';
import onboardingReducer from '../components/onboarding/redux/onboardingReducer';
import { notifyReducer } from '../components/toastify/redux/notifyReducer';
import profileReducer from '../pages/profile/redux/profileReducer'
import {workoutsReducer} from "../pages/workouts/redux/workoutsReducer";
import {authReducer} from "../pages/authentication/redux/authenticationReducer";
import {myPlanReducer} from "../pages/myPlan/store/reducer";

const rootReducer = combineReducers({
  authState: authReducer,
  question: questionReducer,
  modalReducer: modalReducer,
  onboardingReducer: onboardingReducer,
  notify: notifyReducer,
  profile: profileReducer,
  workouts: workoutsReducer,
  planState: myPlanReducer,
});

export default rootReducer;
