import './styles.scss';

import image from '../../../../assets/images/questions/middleQuestionBackground.jpg'

const InfoQuestion = ({data}) => {
    const {body} = data;
    const getBodyItem = ({data, type}) => {
        switch (type) {
            case 'IMAGE':
                return <img className="info-question-image" src={data || image} alt={type}/>;
            case 'TEXT':
                return <p className="info-question-text"> {data}</p>;
            default:
                return <p className="info-question-text">oops...</p>;
        }
    };
    const renderBody = () =>
        body.map((item, index) => (
            <div className="info-question__item" key={index + item.type}>
                {getBodyItem(item)}
            </div>
        ));
    return <div className="info-question">{renderBody()}</div>;
};
export default InfoQuestion;
