import { post, get, patch } from '../apiService';

export const getWeekData = () => {
    return get(`/plan/week`)
}

export const getWeeksData = () => {
    return get('/plan/weeks')
}

export const markPartAsCompleted = (day, partIndex, workoutId, completeDifficult, nextDifficult) => {
    return post('/progress/save-v2', {day, partIndex, workoutId, completeDifficult, nextDifficult})
}

export const markPartAsUncompleted = (day, partIndex, workoutId) => {
    return patch('/progress/remove-part', {day, partIndex, workoutId})
}

export const markDayAsCompleted = (day, time = 0, workoutId) => {
    return post('/progress/save', {day, time, workoutId})
}

export const updatePlanFromDay = (day) => {
    return post('/progress/change-date', {day})
}

export const getCurrentProgress = () => {
    return get('/progress/current')
}

export const getProgress = () => {
    return get('/progress/current')
}
