export const selectActiveDay = week => {
  return week.reduce((acum, item,index) => {
    if (item.date === new Date().setHours(0, 0, 0, 0)) {
      return [...acum, {...item,activeDay:index} ,];
    }
    return acum;
  }, '')[0];
};

export const normalizeData = week => {
  return week.map(item => {
    item.date = new Date(item.date).setHours(0, 0, 0, 0);
    return item;
  });
};

export const getDateNow = () => {
  return new Date().setHours(0, 0, 0, 0);
};

export const timeNormalizer = (timeString) => {
  const [minutes, seconds] = timeString.split(':')

  return `${minutes.length === 1 ? '0' : ''}${minutes}:${seconds.length === 1 ? '0' : ''}${seconds}`
}

export const fromSecondsToMinutes = (seconds) => {
  const minutes = Math.trunc(seconds / 60)
  return timeNormalizer(`${minutes}:${seconds - minutes * 60}`)
}