import React from 'react';
import './styles.scss';
import CustomSelect from '../../../../components/customSelect/CustomSelect';
import routerBook from '../../../../routes/routerBook';
import { useNavigate } from 'react-router-dom';

const OptionsFilter = ({bodyPart, setBodyPart, difficult, setDifficult, duration, setDuration}) => {
    const navigate = useNavigate()
    const valueDifficulty = [
        {value: '', label: 'Any'},
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5'},
    ];
    const valueLength = [
        {value: '0-99999999', label: 'Any'},
        {value: '5-10', label: '5-10 min'},
        {value: '10-15', label: '10-15 min'},
        {value: '15-20', label: '15-20 min'},
        {value: '20-25', label: '20-25 min'},
        {value: '25-999999999', label: '25+ min'},
    ];
    const dataFolter = [
        {value: '', text: 'Any'},
        {value: 'FULL_BODY', text: 'Full body'},
        {value: 'LOWER_BODY', text: 'Lower body'},
        {value: 'UPPER_BODY', text: 'Upper body'},
        {value: 'ABS', text: 'Abs'},
    ];
    const getItemFilter = () =>
        dataFolter.map(({value, text}, index) => (
            <li className={`options-filter__type-item ${value === bodyPart ? 'options-filter__type-active' : ''}`}
                key={value + index}
                onClick={navigate.bind(null, routerBook.filteredWorkouts.replace(':bodyPart', value))}
            >
                {text}
            </li>
        ));
    return (
        <div className="options-filter">
            <ul className="options-filter__type">{getItemFilter()}</ul>
            <div className="options-filter__difficulty">
                <p className="options-filter__difficulty-title">Difficulty:</p>
                <div className="options-filter__difficulty-input">
                    <CustomSelect
                        onChange={event => setDifficult(event.value)}
                        value={valueDifficulty}
                        defaultValue={{value: '', label: 'Any'}}
                    />
                </div>
            </div>
            <div className="options-filter__length-workout">
                <p className="options-filter__length-workout-title">Duration:</p>
                <div className="options-filter__length-workout-input">
                    <CustomSelect onChange={event => setDuration(event.value)} value={valueLength} defaultValue={valueLength[0]}/>
                </div>
            </div>
        </div>
    );
};
export default OptionsFilter;
