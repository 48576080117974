import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import routerBook from '../../../../routes/routerBook';

import arrowCompleted from '../../../../assets/images/myPlan/arrowCompleted.svg';
import './styles.scss';
import { useSelector } from 'react-redux';

import myPlanIcon from '../../../../assets/images/myPlan/my-plan.png'

const HeaderInfo = ({userData, activeDay, t, description, isCompleted}) => {
    const {data} = useSelector(store => store.authState)
    const isQuizPassed = useMemo(() => data?.quizPassed || data?.userAnswers?.length, [data])
    const navigate = useNavigate();
    const {status, date} = activeDay;
    const question = localStorage.getItem('question')
    const getAllContentPassed = () => {
        return (
            <>
                {getInfoDefault()}
                {getInfodayCompleted()}
            </>
        );
    };

    const getActiveContent = () => {
        switch (status) {
            case 'PASSED':
                return (
                    <>
                        {getAllContentPassed()}
                    </>
                );
            case 'DAY_OF':
                return (
                    <>
                        {getInfoDefault()}
                        {getInfoDayOff()}
                    </>
                );
            case 'SKIPPED':
            case 'NO_PASSED':
                return (
                    <>
                        {getInfoDefault()}
                        {!isCompleted && getInfoActive()}
                    </>
                );
            default:
                if (isQuizPassed)
                    return getInfoDefault()
                else if (localStorage['isQuestionSkipped'])
                    return getInfoSkippedQuestion()
        }
    };

    const getInfoSkippedQuestion = () => {
        return (
            <>
                {getInfoQ()}
            </>
        )
    }
    const getInfoQ = (type) => (
        <div
            className={`${type === 'mobile' ? 'my-plan__header-left__header__container-none' : 'my-plan__header-left__header__container'}`}>
            <div className="my-plan__header-left__q-header">
                <div className="my-plan__header-left__q-header__info">
                    <span className="my-plan__header-left__q-header__info-title">{t('plan_hello')},</span>
                    <span className="my-plan__header-left__q-header__info-user">{userData?.name}!</span>
                </div>
                <div className="my-plan__header-left__q-header__item">
                    <div className="off-day__content-header">
                        <div className="off-day__content-header__info">{t('answer_questions_title')}</div>
                        <div className="off-day__content-header__description" style={{maxWidth: '361px'}}>
                            {t('answer_questions_subtitle')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const getInfoDefault = (type) => (
        <div
            className={`${type === 'mobile' ? 'my-plan__header-left__header__container-none' : 'my-plan__header-left__header__container'}`}>
            {/*<img src={myPlanIcon} alt={'my-plan-icon'} className={`my-plan__header-left__header__icon`}/>*/}
            <div className="my-plan__header-left__header">
                <div className="my-plan__header-left__header__info">
                    {t('plan_hello')},<br/>
                    <span className="my-plan__header-left__header__info-user">{userData?.name}!</span>
                </div>
                {description && <p className={'my-plan__header-left__header__challenge'}>{description}</p>}
            </div>
        </div>
    );

    const getInfoActive = () => (
        <div className="active-day__content-header">
            <div className="active-day__content-header__info">Here is your workout for today.</div>
            <div
                className="active-day__content-header__description">{question !== 'done' ? t('plan_answer') : 'Find your best results with balanced workouts designed to motivate and help you achieve your goals.'}</div>
        </div>
    );

    const getInfoDayOff = () => (
        <div className="off-day__content-header">
            <div className="off-day__content-header__info">{t('plan_day_off_title')}</div>
            <div className="off-day__content-header__description">
                {t('plan_day_off_subtitle_first')}{' '}
                <span onClick={() => navigate(routerBook.workouts)}
                      className="off-day__content-header__description-workout"
                >
          {t('plan_day_off_subtitle_second')}
        </span>{' '}
                {t('plan_day_off_subtitle_third')}
            </div>
        </div>
    );

    const getInfodayCompleted = isNoBorder => (
        <div
            className={`completed-day__content-header`}
        >
            <img
                className="completed-day__content-header__img"
                src={arrowCompleted}
                alt="completed"
            />
            <div>
                <div className="completed-day__content-header__info">
                    {t('plan_day_completed_title')}
                </div>
            </div>
        </div>
    );

    return <div className="my-plan__header-left">{getActiveContent()}</div>;
};

export default withTranslation()(HeaderInfo);
