import { withTranslation } from 'react-i18next';
import { fromSecondsToMinutes } from '../../../utils/globalUtils';
import { PercentCircle } from '../../../components/ui-core';

const WeeksDay = ({ dayData, selectWorkoutToDo }) => {

    const _openModal = () => {
        selectWorkoutToDo(dayData, dayData.status === 'PASSED')
    }

    return (
        <div onClick={(dayData.title !== 'Day Off') ? _openModal : null}
             className={'view_plan__weeks_list__week__days_list__day'} key={dayData.day}>
            <img src={dayData.thumbnails} alt={'day_thumbnail'} />
            <div className='view_plan__weeks_list__week__days_list__day__info_wrapper'>
                <p className='view_plan__weeks_list__week__days_list__day__info_wrapper_title'>{dayData.title}</p>
                {dayData.status === 'PASSED' && (
                    <PercentCircle textPadding={""} height={45} width={45} percent={dayData.percent}
                                   className={'view_plan__weeks_list__week__days_list__day__info_wrapper_percent'} />
                )}
                <div className={'view_plan__weeks_list__week__days_list__day__info_wrapper__info'}>
                    {dayData.title === 'Day Off' ? <span
                            className='view_plan__weeks_list__week__days_list__day__info_wrapper__info_time_dayOff' /> :
                        <span className='view_plan__weeks_list__week__days_list__day__info_wrapper__info_time'>
                    {fromSecondsToMinutes(dayData.duration || 0)}
                        </span>}
                    <span className='view_plan__weeks_list__week__days_list__day__info_wrapper__info_day_number'>
                        day {dayData.day}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(WeeksDay);
