import React, { useCallback, useEffect, useRef, useState } from 'react';
import ownPlanImage from '../../assets/images/myPlan/skipQuestionBackground.jpg';

import { challengesList, joinToPlan } from '../../services/api/challengesService/challengesService';
import './styles.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import prev from '../../assets/images/challenges/prev.svg';
import next from '../../assets/images/challenges/next.svg';

import { Button } from '../../components/ui-core';
import routerBook from '../../routes/routerBook';
import { useNavigate } from 'react-router-dom';
import { withAuthUser } from '../../hocs/withAuthUser';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';


const Challenges = () => {
    const { data } = useSelector(store => store.authState);
    const isQuizPassed = useMemo(() => data?.quizPassed || data?.userAnswers?.length, [data]);
    const [listPlans, setListPlans] = useState(isQuizPassed ? [] : [{_id: -1, title: 'Custom Plan',
        thumbnail: ownPlanImage}]);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState();
    const navigate = useNavigate();
    const sliderRef = useRef();
    const [innerOffset, setInnerOffset] = useState(null);
    const [scrollStatus, setScrollStatus] = useState(true);

    useEffect(() => {
        setInnerOffset(Math.floor(listPlans.length / 2))
    }, [listPlans])

    useEffect(() => {
        challengesList(offset).then(({ plans, count }) => {
            const _plans = plans.map(plan => {
                if (plan.type === 'MY_PLAN') {
                    return {
                        ...plan,
                        title: 'Custom Plan',
                        thumbnail: ownPlanImage
                    }
                }

                return plan
            })
            setListPlans(itemsBefore => [...itemsBefore, ..._plans]);
            setCount(count ? count : 1);
        });
    }, [offset]);

    const getElementInArea = useCallback((docElm => {
        let _scrollEnabled = !scrollStatus
        let viewportWidth = docElm.clientWidth;

        return (event, options) => {
            let index;
            if (event && event.type === 'resize') {
                viewportWidth = docElm.clientWidth;
            }

            if (_scrollEnabled) {
                for (index = options.elements.length; index--;) {
                    let elem = options.elements[index],
                        pos = elem.getBoundingClientRect(),
                        leftPerc = pos.left / viewportWidth * 100,
                        rightPerc = pos.right / viewportWidth * 100,
                        middle = (leftPerc + rightPerc) / 2,
                        inViewport;
                    if (options.forFullSize) {
                        if (viewportWidth <= 480) {
                            inViewport = middle > 10 && middle < 90;
                        } else {
                            inViewport = middle > options.zone[1] && middle < (100 - options.zone[1]);
                        }
                    } else {
                        inViewport = middle > options.zone[1] && middle < (100 - options.zone[1]);
                    }

                    if (inViewport) {
                        setInnerOffset(index);
                        updateOffset(index);
                    }
                }
            }
        };
    }), [scrollStatus])

    useEffect(() => {
        if (sliderRef?.current?.children.length) {
            console.log(innerOffset);
            const elements = sliderRef.current.children;

            for (let index = 0; index < elements.length; index++) {
                if (index === innerOffset) {

                    elements[index].classList.toggle('challenges__content__slider__active-slide', true);

                    elements[index].scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                    });
                } else {
                    elements[index].classList.toggle('challenges__content__slider__active-slide', false);
                }
            }

            setTimeout(() => {
                setScrollStatus(false)
            }, 700)
        }
    }, [sliderRef, innerOffset]);

    const onSliderScroll = useCallback((event) => {
        getElementInArea(document.documentElement)(event, {
            elements: sliderRef?.current?.children,
            markedClass: 'challenges__content__slider__active-slide',
            forFullSize: true,
            zone: [45, 45],
        });
    }, [getElementInArea]);

    const updateOffset = (current) => {
        if (!((current + 1) % 5)) {
            setOffset(e => {
                return count > e ? e + 10 : e;
            });
        }
    };

    const _selectItem = (id) => {
        if (id === -1) {
            navigate(routerBook.question);
        }
        joinToPlan(id).then(r => {
            navigate({ pathname: routerBook.dashboard });
        });
    };

    const _more = (id) => {
        navigate({
            pathname: routerBook.challengesItem,
            search: `${id}`,
        });
    };

    const scrollLeft = () => {
        setScrollStatus(true);
        if (innerOffset >= 1) setInnerOffset(innerOffset - 1);
    };

    const scrollRight = () => {
        setScrollStatus(true);
        if (innerOffset < listPlans.length - 1) setInnerOffset(innerOffset + 1);
    };

    const getSlide = () => {
        return listPlans.map(({ title, _id, thumbnail, type }, index) => {
            return (
                <div className={'challenges__content__slider__slide'} key={index + 'challenges__slide'}>
                    <img className={'challenges__content__slider__slide_image'} src={thumbnail}
                         alt={'image challenges'} />
                    <div className={'challenges__content__slider__slide__content'}>
                        <h3 className={'challenges__content__slider__slide__content_title'}>{title}</h3>
                        <Button className={'challenges__content__slider__slide__content_join-button'}
                                options={{ renderType: 'challenges' }}
                                onClick={() => _selectItem(_id)}>{type !== 'MY_PLAN' ? 'Join Challenge' : 'Custom Plan'}</Button>
                        {type !== 'MY_PLAN' && (
                            <p onClick={() => _more(_id)}
                               className='challenges__content__slider__slide__content_more'>
                                More
                            </p>
                        )}
                    </div>
                </div>
            );
        });

    };
    return (
        <div className={'content-container'}>
            <div className={'challenges'}>
                <div className={'challenges__head'}>
                    <h1 className='challenges__head-title'>Challenges For You!</h1>
                    <p className={'challenges__head-description'}>Each of our programs balances strength training,
                        sculpting
                        techniques and body weight exercises to
                        achieve long, lean muscles that will last.</p>
                </div>
                <div className={'challenges__content'}>
                    <img onClick={scrollLeft} className={'challenges__content_arrow'} src={prev}
                         alt={'prev_button'} />
                    <div onresize={onSliderScroll} onScroll={onSliderScroll} ref={sliderRef}
                         className={'challenges__content__slider'}>
                        {getSlide()}
                    </div>
                    <img onClick={scrollRight} className={'challenges__content_arrow'} src={next}
                         alt={'next_button'} />
                </div>
            </div>
        </div>
    );
};
export default withAuthUser(Challenges);
