import { object, string, ref } from 'yup';

export const resetPasswordSchema = object().shape({
  password: string()
    .required('This field is required')
    .min(8, 'This field should be at least 8 characters'),
  confirmPassword: string()
    .required('This field is required')
    .oneOf([ref('password')], 'Passwords do not match'),
});
