import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../../components/ui-core';
import './styles.scss';
import exit from '../../../../assets/images/profile/exti.svg';
import pay from '../../../../assets/images/profile/pay.svg';
import arrow_grey from '../../../../assets/images/vectors/arrow-grey.svg';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import modalType from '../../../../components/modal/types';
import { openModal } from '../../../../components/modal/redux/modalOperations';
import { useDispatch } from 'react-redux';
import {
    getSubscribes, getUsersPaymentMethod,
    getUserSubscription, subscribe,
    unsubscribe,
} from '../../../../services/api/paymentService/paymentService';
import { errorHandler, successHandler } from '../../../../components/toastify/redux/notifyOperations';
import { withAuthUser } from '../../../../hocs/withAuthUser';

const Account = ({ t }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [subscriptions, setSubscriptions] = useState({});
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);

    useEffect(() => {
        getUserSubscription().then(setCurrentSubscription).catch(error => dispatch(errorHandler(error.message)));
    }, [dispatch]);

    useEffect(() => {
        getSubscribes().then(setSubscriptions).catch(error => dispatch(errorHandler(error.message)));
    }, [dispatch]);

    useEffect(() => {
        getUsersPaymentMethod().then(setPaymentMethods);
    }, [dispatch]);

    const _handleSubscribeButton = useCallback((priceId) => {
        subscribe({ priceId }).then(() => window.location.reload()).catch(error => dispatch(errorHandler(error.message)));
    }, [dispatch]);

    const _handleUnsubscribeButton = (id) => {
        unsubscribe(id).then((res) => window.location.reload()).catch(error => dispatch(errorHandler(error.message)));
    };

    const getEndTrialDays = (trialEndDate) => {
        if (trialEndDate * 1000 - Date.now() < 0) {
            return null
        } else {
            return Math.ceil((trialEndDate * 1000 - Date.now()) / 86400000)
        }
    }

    /* Unsubscribe with trialing message */
    const _openUnsubscribeModal = (id, status, endDate, onTrial) => {
        dispatch(
            openModal({
                type: modalType.DEFAULT_MODAL,
                data: {
                    callback_button_ok: _handleUnsubscribeButton.bind(null, id, status),
                    title: 'modal_unsubscribe_title',
                    description: !onTrial ? 'If you confirm the cancellation of your membership, you will be able to workout until ' + new Date(endDate * 1000).toLocaleString('en', {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric',
                    }) : 'Are you sure you want to unsubscribe from a future subscription? If you confirm, then after the trial period you will not have an active subscription',
                    button_ok_text: 'modal_unsubscribe_ok',
                    button_cancel_text: 'modal_unsubscribe_cancel',
                },
            }),
        );
    };

    const _openSubscribeModal = (priceId, title) => {
        dispatch(
            openModal({
                type: modalType.DEFAULT_MODAL,
                data: {
                    callback_button_ok: _handleSubscribeButton.bind(null, priceId),
                    title: title,
                    description: 'Are you sure you want to subscribe ?',
                    button_ok_text: 'modal_unsubscribe_ok',
                    button_cancel_text: 'modal_unsubscribe_cancel',
                },
            }),
        );
    };

    /*    const _openUnsubscribeModal = (id, status, endDate, onTrial) => {
            dispatch(
                openModal({
                    type: modalType.DEFAULT_MODAL,
                    data: {
                        callback_button_ok: _handleUnsubscribeButton.bind(null, id, status),
                        title: 'modal_unsubscribe_title',
                        description: 'If you confirm the cancellation of your membership, you will be able to workout until ' + new Date(endDate * 1000).toLocaleString('en', {
                            month: 'long',
                            year: 'numeric',
                            day: 'numeric',
                        }),
                        button_ok_text: 'modal_unsubscribe_ok',
                        button_cancel_text: 'modal_unsubscribe_cancel',
                    },
                }),
            );
        };*/

    const _openCardManagerWindow = (id) => {
        dispatch(openModal({
            type: modalType.CARD_MANAGER_MODAL,
            data: {
                _id: id,
                buttons: [{
                    title: 'Update',
                    onClick: () => {
                    },
                    type: 'submit',
                }, {
                    title: 'Cancel',
                    onClick: () => {
                    },
                    type: 'button',
                }],
            },
        }));
    };

    const filterSubscriptionsForDisplay = useCallback(() => {
        if (subscriptions && currentSubscription) {
            return Object.keys(subscriptions).filter(subType => {
                return !currentSubscription.filter(sub => {
                    return sub.priceId === subscriptions[subType].id;
                }).length;
            });
        }

        return Object.keys(subscriptions);
    }, [subscriptions, currentSubscription]);

    const getSubscriptionList = useCallback(() => {
        return filterSubscriptionsForDisplay().map((subType) => {
            const { id, name, price, interval, intervalCount } = subscriptions[subType];


            return (
                <div key={id} className='account__payment-plan__item'>
                    <div className='account__payment-plan__item-left'>
                        <p className='account__payment-plan__item-title'>{name}</p>
                        <p className='account__payment-plan__item-description'>
                            {` ${intervalCount} ${interval}`}
                        </p>
                    </div>

                    <div className='account__payment-plan__item-right'>
                        <p className='account__payment-plan__item-prise'>${price}</p>

                        <Button onClick={_openSubscribeModal.bind(null, id, name)}
                                className='account__payment-plan__item-subscribe-button'>
                            {t('profile_account_subscribe')}
                        </Button>
                    </div>
                </div>
            );
        });
    }, [_handleSubscribeButton, filterSubscriptionsForDisplay, t, subscriptions]);

    return (
        <div className='account'>
            <div className='account__title' onClick={navigate.bind(null, -1)}>
                <img alt={'img'} src={exit} className='account__title-image' />
                <p className='account__title-text'>{t('profile_account_account')}</p>
            </div>
            <div className='account__subscription'>
                <p className='account__subscription-title'>{t('profile_account_subscription')}</p>
                <div className='account__subscription-active'>
                    <p className='account__subscription-active-title'>{t('profile_account_your_subscription')}</p>

                    {currentSubscription ? currentSubscription?.sort((c, p) => {
                        if (c.status === 'active') return -1;
                        else return 1;
                    }).map((sub, index) => {
                        return (
                            <div className='account__subscription__container'>
                                <div className='account__subscription__container-top'>
                                    <p className='account__subscription__container-title'>{sub.name}</p>
                                    {!sub.cancelAtPeriodEnd && (
                                        <Button options={{ renderType: 'gray-blek' }}
                                                onClick={_openUnsubscribeModal.bind(null, sub.id, sub.status, sub.end)}>
                                            Unsubscribe
                                        </Button>
                                    )}
                                </div>
                                <p className='account__subscription__container-description'>
                                    {getEndTrialDays(sub.trialEnd) ? `${getEndTrialDays(sub.trialEnd)}-Day free trial, then ${(+sub.price).toFixed(2)} ${sub.currency?.toUpperCase()} Per ${sub.interval}` : ''}
                                    <br />
                                    {`Active until ${new Date(sub.end * 1000).toLocaleString('en-US', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}`}
                                </p>
                            </div>
                        )
                    }) : (
                        <p className='account__subscription__container-description'>
                            {t('profile_account_not_current_subscriptions')}
                        </p>
                    )}
                </div>
            </div>
            <div className='account__payment-plan'>
                <p className='account__payment-plan-title'>{t('profile_account_all_options')}</p>
                {getSubscriptionList()}
            </div>
            <p className='account__payment-title'>{t('profile_account_payment')}</p>
            {paymentMethods?.map(method => (
                <>
                    <div onClick={_openCardManagerWindow.bind(null, method.id)} className='account__payment'>
                        <div className='account__payment__left'>
                            <div className='account__payment__container'>
                                <img className='account__payment__container-image' src={pay} alt={'pay'} />
                                <p className='account__payment__container-number'> **** ****
                                    **** {method.lastNumber}</p>
                            </div>

                        </div>
                        <div className='account__payment__right'>
                            <img className='account__payment__container-image' src={arrow_grey} alt={'arrow'} />
                        </div>
                    </div>
                </>
            ))}
        </div>
    );

    /*return (
        <div className='account'>
            <div className='account__title' onClick={navigate.bind(null, -1)}>
                <img alt={'img'} src={exit} className='account__title-image' />
                <p className='account__title-text'>{t('profile_account_account')}</p>
            </div>
            <div className='account__subscription'>
                <p className='account__subscription-title'>{t('profile_account_subscription')}</p>
                <div className='account__subscription-active'>
                    <p className='account__subscription-active-title'>{t('profile_account_your_subscription')}</p>

                    {currentSubscription ? currentSubscription?.sort((c, p) => {
                        if (c.status === 'active') return -1;
                        else return 1;
                    }).map((sub, index) => (
                        <div className='account__subscription__container'>
                            <div className='account__subscription__container-top'>
                                <p className='account__subscription__container-title'>{sub.name}</p>
                                {!sub.cancelAtPeriodEnd && (
                                    <Button options={{ renderType: 'gray-blek' }}
                                            onClick={_openUnsubscribeModal.bind(null, sub.id, sub.status, sub.end)}>
                                        Unsubscribe
                                    </Button>
                                )}
                            </div>
                            <p className='account__subscription__container-description'>
                                {sub.status === 'trialing' ? 'Trial period until ' + new Date(1657286489 * 1000).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : ''}
                                <br/>
                                {`${sub.price} ${sub.currency?.toUpperCase()} Per ${sub.interval}`}
                            </p>
                        </div>
                    )) : (
                        <p className='account__subscription__container-description'>
                            {t('profile_account_not_current_subscriptions')}
                        </p>
                    )}
                </div>
            </div>
            <div className='account__payment-plan'>
                <p className='account__payment-plan-title'>{t('profile_account_all_options')}</p>
                {getSubscriptionList()}
            </div>
            <p className='account__payment-title'>{t('profile_account_payment')}</p>
            {paymentMethods?.map(method => (
                <>
                    <div onClick={_openCardManagerWindow.bind(null, method.id)} className='account__payment'>
                        <div className='account__payment__left'>
                            <div className='account__payment__container'>
                                <img className='account__payment__container-image' src={pay} alt={'pay'} />
                                <p className='account__payment__container-number'> **** ****
                                    **** {method.lastNumber}</p>
                            </div>

                        </div>
                        <div className='account__payment__right'>
                            <img className='account__payment__container-image' src={arrow_grey} alt={'arrow'} />
                        </div>
                    </div>
                </>
            ))}
        </div>
    );*/
};
export default withAuthUser(withTranslation()(Account));
