import {saveWorkoutsList, setWorkoutsLoadingStatus} from "./workoutsActions";
import {getWorkouts} from "../../../services/api/workoutsService/workoutsService";
import {createQueryParams} from "../../../utils/requestsUtils";

export const getWorkoutsList = (offset = 0, limit = 20, options = {}) => {
    return dispatch => {
        dispatch(setWorkoutsLoadingStatus(true))
            getWorkouts(createQueryParams({offset, limit, ...options})).then(({items, count}) => {
                dispatch(saveWorkoutsList(items, count))
            }).catch(error => console.log(error)).finally(() => {
                dispatch(setWorkoutsLoadingStatus(false))
            })
    }
}