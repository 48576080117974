import './styles.scss';
import React from 'react';
import {Controller} from 'react-hook-form';
import NumberFormat from 'react-number-format';

const HeightWeightQuestion = ({data, control}) => {
    const {answers} = data;

    const numberFormat = ({field: {onChange, name, value}}) => {
        return (
            <NumberFormat
                className="height-weight-question-input"
                onChange={onChange}
                name={name}
                value={value}
                format="###"
                placeholder="0"
            />
        )
    }

    const getAnswers = () => answers.map(({text, unit}, index) => {
        return (
            <label key={index + 'test'} data-parent={unit} className="height-weight-question-label">
                <p>{text}</p>
                <Controller control={control} name={unit} rules={{required: true}} render={numberFormat}/>
            </label>
        )
    });

    return <div className="height-weight-question">{getAnswers()}</div>;
};
export default HeightWeightQuestion;
