import s from './terms-of-services.module.scss';
import logo from '../../assets/images/header/logo.svg';
import {useEffect} from "react";

export const TermsOfService = () => {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <section className={s.wrapper}>
            <div className={s.wrapper__header}>
                <img src={logo} alt={'logo'}/>
            </div>
            <hr/>

            <div className="pageTemplateInner">
                <b>LEVL Terms of Service</b>
                <p>LAST UPDATED: March 19, 2019</p>
                <p>PLEASE READ THIS DOCUMENT CAREFULLY BEFORE ACCESSING OR USING ANY LEVL APPLICATIONS, CONTENT OR
                    WEBSITES.
                    LEVL, Inc. (“LEVL,” “we,” or “us”) provides digitally accessible fitness services and content. This
                    End User
                    License Agreement and Terms of Service (the “Agreement” or “Terms”) is a binding contract between
                    you, an
                    individual user (“you”) and LEVL and governs your use and access of levlmethod.com (the “LEVL Site”
                    or
                    “Site”) and related mobile applications (“LEVL Mobile Apps”), desktop and platform applications
                    (collectively “Applications”). The Site and Applications, along with the fitness service and content
                    made
                    available therein are referred to herein as the “LEVL Service” or “Service”). By registering as a
                    member or
                    by accessing or using the LEVL Service in any way, you accept this Agreement. You should also read
                    and
                    understand the LEVL Privacy Policy, which is incorporated by reference into and made a part of this
                    Agreement. This Agreement also includes any additional payment terms and other requirements set
                    forth on the
                    download or purchase page of marketplaces through which you may purchase or download Applications or
                    access
                    the Service. These marketplaces may have additional terms, conditions and usage rules that govern
                    your
                    access to and use of the Applications and/or the LEVL Service.</p>
                <p>Prior to using the LEVL Service, it is important for you to know and understand that by accessing
                    and/or
                    using the Service through any means, you are agreeing to accept certain liability limitations and
                    legal and
                    health disclaimers, which we further explain throughout this Agreement. In other words, your use of
                    the LEVL
                    Service is at your own risk, and we do not assume any liability, or make any warranties of any kind,
                    express
                    or implied, with respect to the Service or your access and use of the Service. If you have any
                    questions or
                    concerns regarding the terms or conditions herein, please email us at info@LEVLmethod.com.</p>
                <p>IF YOU DO NOT ACCEPT AND AGREE TO BE BOUND BY ALL OF THE TERMS OF THIS AGREEMENT, INCLUDING OUR
                    PRIVACY
                    POLICY, LIMITATIONS OF LIABILITY, WARRANTY DISCLAIMERS AND GENERAL HEALTH AND LEGAL DISCLAIMERS, DO
                    NOT
                    ACCESS OR USE THE APPLICATIONS, SITE OR THE LEVL SERVICE.</p>
                <p>We may amend this Agreement from time to time. If the changes include material changes that affect
                    your
                    rights or obligations, we will notify you of the changes by reasonable means. You acknowledge that
                    an in-app
                    message or an email to the email address provided at registration which notifies you of such changes
                    shall
                    constitute reasonable means. Your continued use of the Applications, Site or Service after we post
                    any
                    amendments to this Agreement will signify your acceptance of such amendments. If you do not agree
                    with any
                    amendment, you must discontinue your access to and use of the Service.</p>
                <p>By using the Service, you consent to receiving this Agreement in electronic form.</p>
                <b>HEALTH WARNING AND LIABILITY DISCLAIMER:</b>
                <p>YOU SHOULD CONSULT YOUR PHYSICIAN OR OTHER HEALTH CARE PRACTITIONER BEFORE STARTING THIS OR ANY OTHER
                    EXERCISE PROGRAM. THIS IS PARTICULARLY TRUE IF YOU OR YOUR FAMILY HAVE A HISTORY OF HIGH BLOOD
                    PRESSURE OR
                    HEART DISEASE, OR IF YOU HAVE EVER EXPERIENCED DISCOMFORT WHILE EXERCISING.</p>
                <p>NOTHING STATED OR POSTED ON THE LEVL SERVICE IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE
                    PRACTICE
                    OF MEDICAL OR PROFESSIONAL ADVICE OR CARE.</p>
                <p>YOUR USE OF THE LEVL SERVICE IS AT YOUR OWN RISK.</p>
                <p>PLEASE READ OUR FULL PROFESSIONAL ADVICE AND MEDICAL DISCLAIMER IN SECTION 2 BELOW BEFORE COMMENCING
                    YOUR
                    USE OF THE SERVICE.</p>
                <p>LEVL SHALL NOT BE LIABLE FOR ANY LIABILITY, OF ANY KIND, RESULTING FROM THE USE OF THE LEVL
                    SERVICE.</p>
                <b>Content:</b>
                <ol>
                    <li>Who May Use the LEVL Service</li>
                    <li>Service and Membership</li>
                    <li>Professional Advice and Medical Disclaimer</li>
                    <li>Your Representations and Warranties as a Member</li>
                    <li>General Disclaimers</li>
                    <li>Limitation of Liability</li>
                    <li>Privacy</li>
                    <li>Payments</li>
                    <li>Cancellation; Term; Account Deletion</li>
                    <li>Community Guidelines; User Content</li>
                    <li>Code of Conduct and Prohibited Activities</li>
                    <li>Indemnification</li>
                    <li>License to Use the LEVL Service</li>
                    <li>Third Party Links and Content</li>
                    <li>Intellectual Property</li>
                    <li>Copyright / DMCA Policy</li>
                    <li>General Provisions</li>
                    <li>Who May Use the LEVL Service</li>
                </ol>
                <p>AGE REQUIREMENT: You must be at least 18 years old to use the LEVL Service.</p>
                <p>NOTICE TO PARENTS AND GUARDIANS: You are responsible for monitoring and supervising your child’s use
                    of the LEVL Service. If your child is using the LEVL Service without your express consent and is
                    under 18,
                    please contact us immediately so that we can disable his or her access. If you have questions about
                    the
                    LEVL, please contact us at info@LEVLmethod.com.</p>
                <ol start="2">
                    <li>The Service</li>
                    <li>Grant of License. Subject to your compliance with the terms and conditions of this Agreement,
                        LEVL
                        grants you a limited, non-exclusive, revocable, non-sublicensable, non-transferable license, to
                        access and
                        use the most current available versions of the Site, Applications and Service for your lawful,
                        personal
                        and non-commercial use.
                    </li>
                    <li>Available Content. The Service and the content provided therein is available for users of
                        various skill
                        levels, for a variety of activities, and for varying durations. Content may not always be
                        available and is
                        subject to change at any time with or without notice and without any liability to you.
                    </li>
                    <li>Membership. While you may enjoy certain content and features without registering with LEVL, to
                        receive
                        the full benefits of the Service, you must register as a member by providing a user name,
                        password, valid
                        email address and valid credit card information. You must provide complete and accurate
                        registration
                        information to LEVL and notify us if your information changes. In creating a user name, you may
                        not use
                        someone else’s name, a name that violates any third party right, or a name that is obscene or
                        otherwise
                        objectionable.
                    </li>
                    <li>Non-commercial Use. Use of the Site and Service is for personal, non-commercial use only.
                        Members may
                        not use the Service in connection with any commercial endeavors, such as (i) advertising or
                        soliciting any
                        user to buy or sell any products or services; or (ii) for commercial purposes. Users of the Site
                        may not
                        use any information obtained from the Service to contact, advertise to, solicit, or sell to any
                        other user
                        without his or her prior explicit consent. Organizations, companies, and/or businesses may not
                        use the
                        Service or the Site for any purpose unless expressly authorized by LEVL. If you wish to inquire
                        about
                        possible commercial use, please contact us at info@LEVLmethod.com. LEVL may investigate and take
                        any
                        available legal action in response to illegal and/or unauthorized uses of the Site or the
                        Service.
                    </li>
                    <li>Account Security. You are responsible for all activity that occurs under your account, including
                        any
                        activity by unauthorized users. You must not allow others to use your account. You must
                        safeguard the
                        confidentiality of your password. If you are using a computer that others have access to, you
                        must log out
                        of your account after using the LEVL Service. If you become aware of any unauthorized access to
                        your
                        account, you must change your password and notify us immediately at info@LEVLmethod.com.
                    </li>
                    <li>LEVL may change, modify, add, remove, suspend, cancel or discontinue any aspect of it
                        subscriptions
                        including the functionality, content, and/or availability of any features of such subscriptions
                        at any
                        time in LEVL’s sole discretion.
                    </li>
                    <li>Professional Advice and Medical Disclaimer.</li>
                </ol>
                <p>YOU SHOULD CONSULT YOUR PHYSICIAN OR OTHER HEALTH CARE PROFESSIONAL BEFORE STARTING THIS OR ANY OTHER
                    EXERCISE OR NUTRITION PROGRAM TO DETERMINE IF IT IS RIGHT FOR YOUR NEEDS. THIS IS PARTICULARLY TRUE
                    IF YOU
                    (OR YOUR FAMILY) HAVE A HISTORY OF HIGH BLOOD PRESSURE OR HEART DISEASE, OR IF YOU HAVE EVER
                    EXPERIENCED
                    CHEST PAIN WHEN EXERCISING, SMOKE, HAVE HIGH CHOLESTEROL, OR HAVE A BONE OR JOINT PROBLEM THAT COULD
                    BE MADE
                    WORSE BY A CHANGE IN PHYSICAL ACTIVITY. DO NOT USE THE LEVL SERVICE IF YOUR PHYSICIAN OR HEALTH CARE
                    PROVIDER ADVISES AGAINST IT. IF YOU EXPERIENCE FAINTNESS, DIZZINESS, PAIN OR SHORTNESS OF BREATH AT
                    ANY TIME
                    WHILE EXERCISING, YOU SHOULD STOP IMMEDIATELY.</p>
                <p>THE LEVL SERVICE OFFERS HEALTH AND FITNESS INFORMATION AND IS DESIGNED FOR INFORMATIONAL PURPOSES
                    ONLY.
                    NOTHING STATED OR POSTED ON THE LEVL SITE OR AVAILABLE THROUGH ANY LEVL SERVICES IS INTENDED TO BE,
                    AND MUST
                    NOT BE TAKEN TO BE, THE PRACTICE OF MEDICAL, PROFESSIONAL OR COUNSELING CARE. FOR PURPOSES OF THIS
                    AGREEMENT, THE PRACTICE OF MEDICINE AND COUNSELING INCLUDES, WITHOUT LIMITATION, PSYCHIATRY,
                    PSYCHOLOGY,
                    PSYCHOTHERAPY, OR PROVIDING HEALTH OR NUTRITION CARE, TREATMENT, INSTRUCTIONS, DIAGNOSIS, PROGNOSIS
                    OR
                    ADVICE. YOU SHOULD NOT RELY ON ANY INFORMATION ON THE LEVL SERVICE AS A SUBSTITUTE FOR, NOR DOES IT
                    REPLACE,
                    PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. IF YOU HAVE ANY CONCERNS OR QUESTIONS ABOUT
                    YOUR
                    HEALTH, YOU SHOULD ALWAYS CONSULT A PHYSICIAN OR OTHER HEALTH-CARE PROFESSIONAL. DO NOT EVER
                    DISREGARD,
                    AVOID OR DELAY OBTAINING MEDICAL OR HEALTH RELATED ADVICE FROM YOUR HEALTH-CARE PROFESSIONAL BECAUSE
                    OF
                    SOMETHING YOU MAY HAVE READ ON THE SERVICE. THE USE OF ANY INFORMATION PROVIDED ON THE SERVICE IS
                    SOLELY AT
                    YOUR OWN RISK.</p>
                <p>IF YOU ARE IN THE UNITED STATES AND THINK YOU ARE HAVING A MEDICAL OR HEALTH EMERGENCY, CALL YOUR
                    HEALTH
                    CARE PROFESSIONAL, OR 911, IMMEDIATELY.</p>
                <p>THE LEVL SITE IS CONTINUALLY UNDER DEVELOPMENT AND LEVL MAKES NO WARRANTY OF ANY KIND, IMPLIED OR
                    EXPRESS,
                    AS TO ITS ACCURACY, COMPLETENESS OR APPROPRIATENESS FOR ANY PURPOSE. IN THAT REGARD, DEVELOPMENTS IN
                    MEDICAL
                    RESEARCH MAY IMPACT GENERAL HEALTH, FITNESS AND ADVICE. NO ASSURANCE CAN BE GIVEN THAT THE
                    INFORMATION
                    CONTAINED ON THE LEVL SITE WILL ALWAYS INCLUDE THE MOST RECENT DEVELOPMENTS WITH RESPECT TO THE
                    PARTICULAR
                    MATERIAL.</p>
                <ol start="4">
                    <li><b>Your Representations and Warranties as a Member</b></li>
                </ol>
                <p>In becoming a Member of LEVL with the intent of using the LEVL Service, you affirm that either (A)
                    all of
                    the following statements are true: (i) no physician has ever informed you that you have a heart
                    condition or
                    that you should only do physical activities recommended by a physician; (ii) you have never felt
                    chest pain
                    when engaging in physical activity; (iii) you have not experienced chest pain when not engaged in
                    physical
                    activity at any time within the past several months; (iv) you have never lost your balance because
                    of
                    dizziness and you have never lost consciousness; (v) you do not have a bone or joint problem that
                    could be
                    made worse by a change in your physical activity; (vi) your physician is not currently prescribing
                    drugs for
                    your blood pressure or heart condition; (vii) you do not have a history of high blood pressure, and
                    no one
                    in your immediate family has a history of high blood pressure or heart problems; and (viii) you do
                    not know
                    of any other reason you should not exercise, including without limitation pregnancy; or (B) your
                    physician
                    has specifically approved of your use of the LEVL Service. You further affirm that you will not
                    access the
                    Service from within the European Economic Area.</p>
                <ol start="5">
                    <li><b>General Disclaimers</b></li>
                </ol>
                <p>LEVL provides the LEVL Service on an “as is” and “as available” basis. You therefore use the LEVL
                    Service
                    at your own risk.</p>
                <p>LEVL expressly disclaims any and all warranties of any kind, whether express or implied, including,
                    but not
                    limited to the implied warranties of merchantability, fitness for a particular purpose,
                    non-infringement,
                    and any other warranty that might arise under any law. Without limiting the foregoing, LEVL makes no
                    representations or warranties:</p>
                <p>That the LEVL Service, or any particular fitness program is suitable for you;</p>
                <p>Regarding the adequacy or safety of the LEVL Service for any particular user;</p>
                <p>That the LEVL Service will meet your personal needs;</p>
                <p>That the LEVL Service will be permitted in your jurisdiction;</p>
                <p>That the LEVL Service will be uninterrupted or error-free;</p>
                <p>Concerning any content submitted by any member;</p>
                <p>Concerning any third party’s use of content that you submit;</p>
                <p>That LEVL will continue to support any particular feature of the LEVL Service; or</p>
                <p>Concerning sites and resources outside of the LEVL Service, even if linked to from the LEVL
                    Service.</p>
                <p>LEVL reserves the right to modify the LEVL Service. You are responsible for providing your own access
                    (e.g., computer, mobile device, Internet connection, etc.) to the LEVL Service. LEVL has no
                    obligation to
                    screen or monitor any content and does not guarantee that any content made available on the LEVL
                    Service
                    complies with this Agreement or is suitable for all users. LEVL shall not be responsible for loss or
                    corruption of data, and hereby waives all claims with respect to damage to Your computer system,
                    internet
                    access, download or display device. To the extent that a secondary party may have access to or view
                    LEVL
                    content on your computer or mobile device, you are solely responsible for informing such party of
                    all
                    provisions, terms, disclaimers and warnings in this Agreement. To the extent any disclaimer or
                    limitation of
                    liability in this Agreement does not apply, all applicable express, implied, and statutory
                    warranties will
                    be limited in duration to a period of thirty (30) days after the date on which you first used the
                    LEVL
                    Service, and no warranties shall apply after such period.</p>
                <ol start="6">
                    <li><b>Limitation of Liability</b></li>
                </ol>
                <p>To the fullest extent permitted by law: (i) in no event shall LEVL, nor its affiliates, be liable for
                    any
                    direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but
                    not
                    limited to damages for personal injury, death, loss of livelihood, loss of enjoyment, pain and
                    suffering,
                    emotional distress, loss of profits, loss of future earnings, goodwill, use, and/or any other
                    damages or
                    other intangible losses; and (ii) LEVL’s, and its affiliates’ total liability to you shall not
                    exceed the
                    amounts paid by you to LEVL over the twelve (12) months preceding your claim(s).</p>
                <p>IF YOU DO NOT ACCEPT THIS LIMITATION OF LIABILITY, YOU ARE NOT AUTHORIZED TO OBTAIN OR ACCESS THE
                    SERVICE,
                    SITE OR APPLICATIONS.</p>
                <ol start="7">
                    <li><b>Privacy</b></li>
                </ol>
                <p>Your privacy rights are set forth in our Privacy Policy, which forms a part of this Agreement. Please
                    review the Privacy Policy to learn about:</p>
                <p>What information we may collect about you;</p>
                <p>What we use that information for; and</p>
                <p>When and with whom we share that information.</p>
                <p>CONSENT TO EMAIL: When you become a Member, you agree and consent to receive email messages from us.
                    These
                    emails may be transactional or relationship communications relating to the Service, such as
                    administrative
                    notices and service announcements or changes, or emails containing commercial offers, promotions or
                    special
                    offers from us or third party partners.</p>
                <ol start="8">
                    <li><b>Payments</b></li>
                </ol>
                <p>Auto-renewal and billing; Subscription Fees; Free Trial Terms.</p>
                <ol>
                    <li>Subscription. The LEVL Service is a paid, auto-renewing subscription service. You will be
                        charged
                        the stated subscription amount for the subscription period upon completion of your registration
                        and
                        submission of your payment information unless your membership includes a free trial period.
                    </li>
                    <li>Auto-Renewal. You are agreeing that upon expiration of the initial subscription period, your
                        subscription will automatically renew for successive periods. You agree to automatic (recurring)
                        billing,
                        and agree to pay the charges made to your account in connection therewith. You will be billed at
                        the
                        then-current applicable subscription price plus any applicable taxes. We will process payment
                        for any
                        renewal subscriptions using the same billing cycle as your current subscription. Subscription
                        fees may
                        change at any time, to the fullest extent permitted under applicable law. Your Subscription, and
                        monthly
                        billing of your account, will continue indefinitely until cancelled by you.
                    </li>
                    <li>Free Trials. If you accepted an offer with a Free Trial, your LEVL membership begins when you
                        have
                        completed the Free Trial sign-up process (i.e., when we collect your personal and payment
                        information). At
                        the end of your Free Trial, you will begin to be billed automatically each month (about every 30
                        days)
                        until you cancel. Once we begin to bill you, cancellations take effect starting at the end of
                        your current
                        billing period, meaning there are no partial month refunds or credits. If you cancel your Free
                        Trial
                        membership at anytime in the first thirty days (i.e., during the Free Trial period), you will
                        not be
                        charged.
                    </li>
                    <li>Pricing and Special Offers. The pricing of our Services may vary periodically. We cannot
                        guarantee
                        that the price of your Subscription is the lowest available, or historically lowest or best,
                        price. You
                        will be charged in accordance with the billing terms you agreed to at the time you signed up. At
                        certain
                        times, we may offer special promotional offers (“Special Offers”) that are billed at a
                        discounted rate.
                        These offers may have a different Free Trial period length, or no Free Trial period. In these
                        cases, you
                        will begin to be billed at the time payment information is collected, or, if a Free Trial is
                        being
                        offered, at the end of the special Free Trial period. Note: in these cases, our typical Free
                        Trial will
                        not apply. These terms will always be disclosed on the payment screen at the time of sign up,
                        prior to our
                        acceptance of payment. In all instances, you will continue to be automatically billed monthly
                        after your
                        Free Trial ends (or immediately if there is no Free Trial). All offers accepted will cancel and
                        supersede
                        any previous subscription.
                    </li>
                    <li>Add-On Services. We refer to any additional, paid subscription Service we offer beyond a
                        standard
                        Membership as an “Add-On Service.” For example, our personalized Coaching service, or any other
                        Service we
                        may offer to you which provides access to special or additional Content beyond your standard
                        Membership,
                        is an Add-On Service. Each Add-On Service is a separate subscription service and is subject to
                        an
                        additional monthly fee that automatically renews just like your regular Membership. Before
                        agreeing to an
                        Add-On Service, you will be prompted with i) the type of Service being offered (e.g. Coaching);
                        and ii)
                        the monthly, recurring cost for such Add-On Service. Note: if You purchase an Add-On Service,
                        You are
                        agreeing to an additional monthly automatic (recurring) billing subscription, and agree to pay
                        the charges
                        made to your account in connection therewith, as disclosed at the time You accept the Add-On
                        Service. Your
                        Add-On Service subscription, just like Your standard Membership subscription, will continue
                        indefinitely
                        until cancelled by You. Add-On Services are billed on the same day as Your regular Membership,
                        in one
                        combined payment. You can cancel at any time by following the instructions below (“How to
                        Cancel”). Note
                        that Your Add-On Service subscription is separate from your regular Membership, although we will
                        combine
                        the charges each month when we bill You. You can cancel one or both at any time, but canceling
                        your
                        regular Membership will automatically cancel your Add-On Service. Your Add-On Service Free Trial
                        period
                        lasts only as long as your original (initial) Free Trial offer is in effect, which means that if
                        you do
                        not sign up for the Add-On Service on the same day you initially signed up for your Free Trial
                        membership,
                        your Add-On free trial will only remain in effect for the remainder of your initial Free Trial
                        period. So,
                        for example, if you signed up for your Free Trial membership on the 1st of the month, and signed
                        up for
                        your Add-On Service on the 16th, your free Add-On trial period would only last for approximately
                        two weeks
                        through the 30th of that same month (the same duration as your initial Free Trial membership).
                        Sorry, once
                        your Free Trial membership has expired, you can no longer sign up for a free Add-On Service
                        trial. In
                        other words, any new Add-On Service added after the expiration of your original Free Trial
                        membership will
                        begin to be billed immediately, pro rated for the number of days in the month remaining, and
                        will not be
                        eligible for a Free Trial.
                    </li>
                    <li>Quarterly and Annual Subscriptions. In certain circumstances, we will offer Subscriptions for a
                        longer term (for example, 3 months, 6 months or even a year). These longer-term Subscriptions
                        are charged
                        in full upon purchase, and automatically renew for the same duration as the initial Subscription
                        term you
                        selected (for example, if you initially purchased a 3 month Subscription, your Subscription will
                        automatically renew for three months; 6 month subscriptions will automatically renew for six
                        months, and
                        so on). The amount due for the next renewal term will be due and immediately payable in full and
                        charged
                        as of the first day of such renewal term. You can always cancel before your next renewal term by
                        logging
                        into your account, and following the cancellation instructions below (“How to Cancel”).
                    </li>
                    <li>Paused Subscriptions. In circumstances in which you elect to pause your subscription, your
                        account
                        will automatically reactivate following the time period that you specified at the time of
                        pausing, and we
                        will then automatically start to bill you again. You will be responsible for payment upon
                        reactivation
                        regardless of whether or not you receive a reminder notice regarding the expiration of your
                        paused status.
                        By electing to pause your subscription, you agree and authorize LEVL to bill your account
                        starting at the
                        expiration of your paused account status period.
                    </li>
                    <li>Authorization for Automatic Recurring Charges. When you sign up for our Service and provide a
                        payment method to LEVL or a third party Application, you are granting your express consent and
                        are
                        expressly authorizing us (and our designated payment processor) to automatically charge you each
                        month for
                        the Service. If you provide a payment method and our charge results in an overdraft, chargeback
                        or other
                        fee from your bank, you alone are responsible for that fee. EVEN IF YOU DO NOT USE THE
                        SUBSCRIPTION OR
                        ACCESS THE LEVL APPLICATION, YOU WILL BE RESPONSIBLE FOR ANY SUBSCRIPTION FEES UNTIL YOU CANCEL
                        YOUR
                        SUBSCRIPTION IN ACCORDANCE WITH SECTION 9 BELOW OR IT IS OTHERWISE TERMINATED.
                    </li>
                </ol>
                <p>Service Add-Ons– Auto Billing; Coaching; Subscription Fees; Free Trial; and Cancellation Changes.
                    LEVL
                    may increase its fees for any subscription effective the first day of a billing renewal by giving
                    you notice
                    of the new fees at least thirty (30) days before you are billed. If you have and do not cancel your
                    subscription, you will be deemed to have accepted the new fees.</p>
                <ol start="9">
                    <li><b>Cancellation; Term and Termination; Account Deletion</b></li>
                </ol>
                <p>HOW TO CANCEL If you are using the LEVL Service through the LEVL Site, LEVLfit.com and you do not
                    wish
                    for your account to renew automatically, or if you want to change or terminate your subscription,
                    first log
                    into your account, and then click on cancel subscription in the account section. Email cancelation
                    does
                    not constitute legal notice. Your cancellation takes effect at the start of the next billing cycle
                    (Don’t
                    worry – your cancellation effective date will be listed and you will continue to have access to your
                    account
                    through the account expiration date). So, for example, if you have a monthly subscription that began
                    on the
                    first of the month but cancel mid-month, your cancellation will take effect as of the beginning of
                    the
                    following month. Sorry– there are no refunds on LEVL subscriptions for billing periods that have
                    already
                    lapsed. Upon cancellation, you will immediately receive an email confirming your cancellation. Your
                    cancellation is not effective until your receipt of the cancellation email. If you do not receive an
                    email
                    notice for any reason, immediately contact LEVL to ensure your cancellation was properly
                    submitted.</p>
                <p>ACCOUNT DELETION: You may delete your account at any time. We reserve the right, but are under no
                    obligation to, to delete an account from the LEVL Service that remains inactive (i.e., the user
                    fails to log
                    in) for a continuous period of at least six (6) months, or immediately when payment expires, is
                    withdrawn or
                    otherwise ceases.</p>
                <p>TERMINATION FOR BREACH: LEVL may suspend, disable, or delete your account (or any part thereof) or
                    block or remove any content you submitted if LEVL determines that you have violated any provision of
                    this
                    Agreement or that your conduct or content would tend to damage LEVL’s reputation or goodwill. If
                    LEVL
                    deletes your account for the foregoing reasons, you may not re-register for the LEVL Service. LEVL
                    may block
                    your email address and Internet protocol address to prevent further registration. LEVL is not
                    required to
                    disclose, and may be prohibited by law from disclosing, the reason for the termination or suspension
                    of your
                    account.</p>
                <p>After your membership or subscription is terminated for any reason, all terms of this Agreement
                    survive
                    such termination, and continue in full force and effect, except for any terms that by their nature
                    expire or
                    are fully satisfied.</p>
                <p>EFFECT OF TERMINATION/ACCOUNT DELETION: Upon termination, all licenses granted by LEVL will
                    terminate.
                    Sections 4-5, 9, and 12-18 shall survive termination. In the event of account deletion for any
                    reason,
                    content that you submitted may no longer be available. LEVL shall not be responsible for the loss of
                    such
                    content.</p>
                <ol start="10">
                    <li><b>Community Guidelines; User Content</b></li>
                </ol>
                <p>LEVL offers you the ability to submit or post User Content through the Service, including through
                    social
                    features available within our mobile applications, provided such submission or transmission of User
                    Content
                    is in strict compliance with these terms. For the purposes of this Agreement, “User Content” means
                    video,
                    images, text, comments, audio recordings and other content, that you upload, post or transmit
                    (collectively
                    “submit”) to the Service.</p>
                <ol>
                    <li><b>Guidelines. LEVL gives you the ability to submit User Content in order to create a community
                        experience
                        where our users can encourage, support and motivate one other.</b>
                    </li>
                </ol>
                <p>You agree to follow these guidelines when submitting User Content:</p>
                <p>SUPPORT — For any support related questions or issues that require help beyond what we can offer in
                    this Agreement, please contact our Customer Support team at info@LEVLmethod.com for the fastest
                    response.</p>
                <p>R-E-S-P-E-C-T — Please be respectful and courteous to the members of this group.</p>
                <p>FEEDBACK — We welcome constructive feedback (both positive and negative) on your LEVL experience and
                    ask that you contact us directly at info@LEVLmethod.com so that we can be sure we hear your thoughts
                    on our
                    Service and strive to improve.</p>
                <p>REPORTING POSTS — If you notice any User Content that you believe to be in violation of this
                    Agreement, or spam, report it to the administrators at info@LEVLmethod.com.</p>
                <ol>
                    <li>Restrictions. You may not submit any User Content that: * Infringes any third party’s copyrights
                        or
                        other rights (e.g., trademark, privacy rights, etc.); * Contains sexually explicit content or
                        pornography
                        (provided, however, that non-sexual nudity is permitted); * Contains hateful, defamatory, or
                        discriminatory content or incites hatred against any individual or group; * Advocates harassment
                        or
                        intimidation of another person; * Exploits minors; * Depicts unlawful acts or extreme violence;
                        * Contains
                        video, audio photographs, or images of another person without his or her permission (or in the
                        case of a
                        minor, the minor’s legal guardian); * Depicts animal cruelty or extreme violence towards
                        animals; or *
                        Contains viruses, time bombs, trojan horses, cancelbots, worms or other harmful, or disruptive
                        codes,
                        components or devices.
                    </li>
                    <li>User Content Screening and Disclosure. We do not, and cannot, pre-screen or monitor all User
                        Content.
                        However, LEVL has the right (but not the obligation) to monitor your conduct and User Content
                        submission
                        on our Service and you hereby provide your irrevocable consent to such monitoring. You
                        acknowledge and
                        agree that you have no expectation of privacy concerning your submission of any User Content. We
                        have the
                        right, but not the obligation, in our sole discretion to edit, refuse to post, remove, disallow,
                        block or
                        delete any User Content. Under no circumstances will LEVL be liable for any of your User
                        Content. You
                        shall be solely responsible for any User Content submitted through your account.
                    </li>
                </ol>
                <p>We may access, preserve or disclose any of your information or User Content (including without
                    limitation
                    chat text) if we are required to do so by law, or if we believe in good faith that it is reasonably
                    necessary to (i) respond to claims asserted against us or to comply with legal process (for example,
                    subpoenas or warrants), including those issued by courts having jurisdiction over us or you; (ii)
                    enforce or
                    administer our agreements with users, such as this Agreement; (iii) for fraud prevention, risk
                    assessment,
                    investigation, customer support, providing the app services or engineering support; (iv) protect the
                    rights,
                    property or safety of LEVL, its users, or members of the public or (v) to report a crime or other
                    offensive
                    behavior.</p>
                <ol>
                    <li>Objectionable Content. LEVL may also act to remove Objectionable Content. The decision to remove
                        Objectionable Content shall be made at LEVL’s sole discretion. “Objectionable Content” includes,
                        but is
                        not limited to: 1. User Content that is unlawful, harmful, threatening, abusive, harassing,
                        tortuous,
                        defamatory, or libelous, 2. User Content that is hateful, or advocates hate crimes, harm or
                        violence
                        against a person or group, 3. User Content that may harm minors in any way; 4. User Content that
                        has the
                        goal or effect of “stalking” or otherwise harassing another 5. Private information about any
                        individual
                        such as phone numbers, addresses, Social Security numbers or any other information that is
                        invasive of
                        another’s privacy; 6. User Content that is vulgar, offensive, obscene or pornographic, 7.
                        Unsolicited or
                        unauthorized advertising, promotional materials, “junk mail,” “spam,” “chain letters,” “pyramid
                        schemes,”
                        or any other form of solicitation; 8. Material that contains software viruses or any other
                        computer code,
                        files or programs designed to interrupt, destroy or limit the functionality of any computer
                        software or
                        hardware or telecommunications equipment.
                    </li>
                    <li>Social Features. LEVL may provide opportunities for you to submit User Content through social
                        features
                        on its Site or through its mobile applications. These are intended to be a community-based venue
                        for LEVL
                        users to chat, interact, motivate and support one another.
                    </li>
                    <li>As between you and LEVL, you own all User Content that you submit to the LEVL Service, whether
                        directly
                        via the LEVL Service or indirectly (for example, via Facebook or Twitter). However, it is
                        important that
                        you understand that by posting User Content to LEVL, you are granting LEVL and its affiliates a
                        worldwide,
                        perpetual, irrevocable, non-exclusive, sub-licensable (through multiple tiers) royalty-free
                        license and
                        right to use, copy, transmit, distribute, publicly perform and display (through all media now
                        known or
                        hereafter created), and make derivative works from your User Content (including without
                        limitation, your
                        name and likeness, photographs and testimonials) for any purpose whatsoever commercial or
                        otherwise
                        without compensation to you. In addition, you waive any so-called “moral rights” or “performance
                        rights”
                        in your User Content.
                    </li>
                    <li>You further grant all users of the LEVL Service permission to view your User Content for their
                        personal,
                        non-commercial purposes. If you make suggestions to LEVL on improving or adding new features to
                        the LEVL
                        Service, LEVL shall have the right to use your suggestions without any compensation to you.
                    </li>
                    <li>For each piece of User Content that you submit, you represent and warrant that: (i) you have the
                        right
                        to submit the User Content to LEVL and grant the licenses set forth above; (ii) LEVL will not
                        need to
                        obtain licenses from any third party or pay royalties to any third party; (iii) the User Content
                        does not
                        infringe any third party’s rights, including intellectual property rights and privacy rights;
                        and (iv) the
                        User Content complies with this Agreement and all applicable laws.
                    </li>
                    <li>Code of Conduct and Prohibited Activities</li>
                </ol>
                <p>In using the LEVL Service, you must behave in a civil and respectful manner at all times. Further,
                    you will
                    not and it is strictly prohibited to:</p>
                <p>Act in a deceptive manner by, among other things, impersonating any person;</p>
                <p>Harass or stalk any other person;</p>
                <p>Harm or exploit minors;</p>
                <p>Distribute “spam”;</p>
                <p>Promote information that is false or misleading, or promote illegal activities or conduct that is
                    defamatory, libelous or otherwise objectionable;</p>
                <p>“frame” or “mirror” any part of the Service or the Site;</p>
                <p>use meta tags or code or other devices containing any reference to LEVL, the Site or the Service (or
                    any
                    trademark, trade name, service mark, logo or slogan of LEVL) to direct any person to any other
                    website for
                    any purpose;</p>
                <p>modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise
                    disassemble
                    any portion of the Service or the Site or any software used on or for the Service or the Site, or
                    cause
                    others to do so;</p>
                <p>post, use, transmit or distribute, directly or indirectly, (e.g. screen scrape) in any manner or
                    media any
                    content or information obtained from the Site or the Service other than solely in connection with
                    your use
                    of the Service in accordance with this Agreement.</p>
                <p>Collect information about others;</p>
                <p>Advertise or solicit others to purchase any product or service within the LEVL Site;</p>
                <p>Promote fraudulent schemes, multi level marketing (MLM) schemes, get rich quick schemes, online
                    gaming and
                    gambling, cash gifting, work from home businesses, or any other dubious money-making ventures;</p>
                <p>Publicize or promote commercial activities and/or sales without our prior written consent such as
                    contests,
                    sweepstakes, barter, advertising, and pyramid schemes.</p>
                <p>Participate in any activity that in any way violates any law; or</p>
                <p>Access or use the Site and Service (including any Add-on Service or application available through a
                    mobile
                    or set-top device) from the European Economic Area.</p>
                <p>LEVL reserves the right, in its sole discretion, to investigate and take appropriate legal action
                    against
                    anyone who violates this Agreement, including removing the offending communication from the Site or
                    Service
                    and terminating or suspending the membership of such violators.</p>
                <p>Your use of the Site and Service must comply with all applicable laws and regulations. You agree that
                    LEVL
                    may access, preserve and disclose your account information and Content if required to do so by law
                    or in a
                    good faith belief that such access, preservation or disclosure is reasonably necessary, such as to:
                    (i)
                    comply with legal process; (ii) enforce this Agreement; (iii) respond to claims that any Content
                    violates
                    the rights of third parties; (iv) respond to your requests for customer service or allow you to use
                    the Site
                    in the future; or (v) protect the rights, property or personal safety of the Company or any other
                    person.</p>
                <ol start="12">
                    <li><b>Indemnification</b></li>
                </ol>
                <p>You agree to indemnify, defend, and hold harmless LEVL and its affiliates, directors, officers,
                    employees,
                    and agents, from and against any liability, claims, damages, losses and costs (including reasonable
                    attorney’s fees) that: (i) arise from your activities on the LEVL Service; (ii) assert a violation
                    by you of
                    any term of this Agreement; or (iii) assert that any content you submitted to LEVL violates any law
                    or
                    infringes any personal right or third party right, including any intellectual property or privacy
                    right.
                    LEVL reserves the right to assume the exclusive defense and control of any matter otherwise subject
                    to
                    indemnification by you, in which event you will fully cooperate with LEVL in connection
                    therewith.</p>
                <ol start="13">
                    <li><b>License to Use the LEVL Service</b></li>
                </ol>
                <p>LICENSE: LEVL grants you a limited, non-exclusive license to access and use the LEVL Service for your
                    own personal, non-commercial purposes. This includes the right to view content available on the LEVL
                    Service. This license is personal to you and may not be assigned or sublicensed to anyone else.</p>
                <p>RESTRICTIONS: Except as expressly permitted by LEVL in writing, you will not reproduce, redistribute,
                    publicly display, sell, create derivative works from, decompile, reverse engineer, or disassemble
                    the LEVL
                    Service. Nor will you take any measures to interfere with or damage the LEVL Service. All rights not
                    expressly granted by LEVL are reserved.</p>
                <p>MOBILE DEVICES, APPLICATIONS AND SET-TOP BOXES/EXTERNAL DEVICES: These Terms of Service, and all of
                    the provisions herein, also govern the use of our mobile, desktop and set-top devices and related
                    applications (for example, iOS devices, Android devices, Roku, Apple TV and other such devices and
                    their
                    corresponding apps).</p>
                <p>Your use of the LEVL Service through any applications or device constitutes your agreement to be
                    bound by
                    these Terms.</p>
                <p>Any LEVL application, regardless of the manner and means in which it is downloaded (for example the
                    Apple
                    AppStore, Google Play Store, your console’s app store, etc.) is licensed, not sold, to You for use
                    only
                    under these Terms. We, the licensor, LEVL, Inc. (Application Provider) reserve all rights not
                    expressly
                    granted to You.</p>
                <p>Accordingly, if you download the LEVL Application, you will be: * installing a software program on
                    your
                    product in the form of an application; * entering into this contract with LEVL, Inc. governing your
                    use of
                    the application.</p>
                <ol start="14">
                    <li><b>Third Party Links and Content</b></li>
                </ol>
                <p>Certain links on the LEVL Sites and/or the LEVL Service may let you leave the particular LEVL Site or
                    LEVL
                    Service you are accessing in order to access a linked site (the “Linked Sites”). When you are
                    linking to a
                    third party site, it is important to know that LEVL does not control these sites, nor has LEVL
                    reviewed or
                    approved the content which appears on the linked sites. LEVL is not responsible for the legality,
                    accuracy
                    or nature of any content, advertising, products or other materials on or available from any linked
                    sites, or
                    the conduct of such linked sites. You acknowledge and agree that LEVL shall not be responsible or
                    liable,
                    directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection
                    with the
                    use of any of the links, content, goods or services available on or through the linked sites.</p>
                <p>Dealings with Third Parties.</p>
                <p>More specifically, your participation, correspondence or business dealings with any third party found
                    on or
                    through the LEVL Sites and Service (i.e., a linked site), regarding payment and delivery of specific
                    goods
                    and services, and any other terms, conditions, representations or warranties associated with such
                    dealings,
                    are solely between you and such third party. You agree that LEVL shall not be responsible or liable
                    for any
                    loss, damage, or other matters of any sort incurred as the result of such dealings.</p>
                <ol start="15">
                    <li><b>Intellectual Property</b></li>
                </ol>
                <p>You acknowledge that the LEVL Site and Services contain software, graphics, photos, videos, know-how,
                    product ideas, comments and other material (collectively, “Content”) that is protected by
                    copyrights,
                    patents, trademarks, trade secrets or other proprietary rights, and that these rights are valid and
                    protected in all forms, media and technologies existing now or hereafter developed. All
                    LEVL-generated
                    content and content developed for LEVL by its partners and licensors is copyrighted individually
                    and/or as a
                    collective work under the U.S. copyright laws; further, LEVL owns a copyright in the selection,
                    coordination, arrangement and enhancement of all Content in the LEVL Site. Subject to your
                    compliance with
                    this Agreement, and solely for so long as you are permitted by us to access and use the Services,
                    you may
                    download one copy of the application (but never the Content) to any single computer or device for
                    your
                    personal, non-commercial home use only, provided you keep intact all copyright and other proprietary
                    notices, and are in compliance with this Agreement. Unless otherwise specified, modification of the
                    Content
                    or use of the Content for any other purpose, including use of any such Content on any other website
                    or
                    networked computer environment is strictly prohibited.</p>
                <p>The LEVL name, logos and affiliated applications and technologies are the exclusive property of LEVL,
                    Inc.
                    All other trademarks appearing on the Services are trademarks of their respective owners. Our
                    partners or
                    service providers may also have additional proprietary rights in the content which they make
                    available
                    through the Services. The trade names, trademarks and service marks owned by us, whether registered
                    or
                    unregistered, may not be used in connection with any product or service that is not ours, in any
                    manner that
                    is likely to cause confusion. Nothing contained on the Services should be construed as granting, by
                    implication, estoppel or otherwise, any license or right to use any of our trade names, trademarks
                    or
                    service marks without our express prior written consent.</p>
                <p>LEVL owns and retains all proprietary rights in the Site and the Service, and in all content,
                    trademarks,
                    trade names, service marks and other intellectual property rights related thereto. You agree to not
                    copy,
                    modify, transmit, create any derivative works from, make use of, or reproduce in any way any
                    copyrighted
                    material, trademarks, trade names, service marks, or other intellectual property or proprietary
                    information
                    accessible on the Site or through the Service, without first obtaining the prior written consent of
                    the
                    Company or, if such property is not owned by the Company, the owner of such intellectual property or
                    proprietary rights. You agree to not remove, obscure or otherwise alter any proprietary notices
                    appearing on
                    any content, including copyright, trademark and other intellectual property notices.</p>
                <ol start="16">
                    <li><b>Copyright / DMCA Policy</b></li>
                </ol>
                <p>LEVL respects the intellectual property of others, and we ask our users to do the same. Each user is
                    responsible for ensuring that the materials they upload to the LEVL Site do not infringe any third
                    party
                    copyright.</p>
                <p>LEVL will promptly remove materials from the LEVL Site in accordance with the Digital Millennium
                    Copyright
                    Act (“DMCA”) if properly notified that the materials infringe a third party’s copyright. In
                    addition, LEVL
                    may, in appropriate circumstances, terminate the accounts of repeat copyright infringers.</p>
                <p>Filing a DMCA Notice to Remove Copyrighted Content-for Copyright Holders If you believe that your
                    work
                    has been copied in a way that constitutes copyright infringement, please provide us with a written
                    notice
                    containing the following information: 1. Your name, address, telephone number, and email address (if
                    any).
                    2. A description of the copyrighted work that you claim has been infringed. 3. A description of
                    where on the
                    LEVL Site the material that you claim is infringing may be found, sufficient for LEVL to locate the
                    material
                    (e.g., the URL). 4. A statement that you have a good faith belief that the use of the copyrighted
                    work is
                    not authorized by the copyright owner, its agent, or the law. 5. A statement by you UNDER PENALTY OF
                    PERJURY
                    that the information in your notice is accurate and that you are the copyright owner or authorized
                    to act on
                    the copyright owner’s behalf. 6. Your electronic or physical signature.</p>
                <p>You may submit this information via: 1. Email: info@LEVLmethod.com. 2. Offline: LEVL’s Copyright
                    Agent (see
                    contact information below)</p>
                <p>Filing a DMCA Counter-notification to Restore Removed Content for LEVL Users If you believe that your
                    material has been removed by mistake or misidentification, please provide LEVL with a written
                    counter-notification containing the following information: 1. Your name, address, and telephone
                    number. 2. A
                    description of the material that was removed and the location on the LEVL Site (e.g., the URL) where
                    it
                    previously appeared. 3. A statement UNDER PENALTY OF PERJURY that you have a good faith belief that
                    the
                    material was removed or disabled as a result of mistake or misidentification. 4. A statement that
                    you
                    consent to the jurisdiction of the Federal District Court for the judicial district in which your
                    address is
                    located, or if your address is outside of the United States, any judicial district in which LEVL may
                    be
                    found (which includes the United States District Court for the Southern District of New York), and
                    that you
                    will accept service of process from the person who filed the original DMCA notice or an agent of
                    that
                    person. 5. Your electronic or physical signature.</p>
                <p>You may submit this information via: 1. Email: info@LEVLmethod.com 2. Offline: LEVL’s Copyright Agent
                    (see
                    contact information below)</p>
                <p>Please note that we will send any complete counter-notifications we receive to the person who
                    submitted the
                    original DMCA notice. That person may elect to file a lawsuit against you for copyright
                    infringement. If we
                    do not receive notice that a lawsuit has been filed within ten (10) business days after we provide
                    notice of
                    your counter-notification, we will restore the removed materials. Until that time, your materials
                    will
                    remain removed.</p>
                <p>Warning In filing a DMCA notice or counter-notification, please make sure that you have complied with
                    all of the above requirements. If we request additional information necessary to make your DMCA
                    notice or
                    counter-notification complete, please provide that information promptly. If you fail to comply with
                    all of
                    these requirements, your DMCA notice or counter-notification may not be processed further.</p>
                <p>In addition, please make sure that all of the information you provide is accurate. UNDER SECTION
                    512(f) OF THE COPYRIGHT ACT, 17 U.S.C. § 512(f), ANY PERSON WHO KNOWINGLY MATERIALLY MISREPRESENTS
                    THAT
                    MATERIAL OR ACTIVITY IS INFRINGING OR WAS REMOVED OR DISABLED BY MISTAKE OR MISIDENTIFICATION MAY BE
                    SUBJECT
                    TO LIABILITY.</p>
                <p>LEVL may disclose any communications concerning DMCA notices or other intellectual property
                    complaints with
                    third parties, including the users who have posted the allegedly infringing material.</p>
                <p>If you have questions about the legal requirements of a DMCA notice, please contact an attorney or
                    see
                    Section 512(c)(3) of the U.S. Copyright Act, 17 U.S.C. § 512(c)(3), for more information. If you
                    have
                    questions about the legal requirements of a DMCA counter-notification, please contact an attorney or
                    see
                    Section 512(g)(3) of the U.S. Copyright Act, 17 U.S.C. § 512(g)(3), for more information.</p>
                <p>LEVL’s Copyright Agent You may send a DMCA notice, a DMCA counter-notification, or any inquiries
                    concerning intellectual property to LEVL’s Copyright Agent:</p>
                <p>LEVL, 792 Columbus Ave. Suite 800R New York, New York 10025 Attn: Legal Dept. – Copyright Agent
                    info@LEVLmethod.com</p>
                <ol start="17">
                    <li><b>General Provisions</b></li>
                </ol>
                <p>ARBITRATION AND GOVERNING LAW: The exclusive means of resolving any dispute or claim arising out of
                    or
                    relating to this Agreement (including any alleged breach thereof), the Service, or the Site shall be
                    BINDING
                    ARBITRATION administered by the American Arbitration Association. The one exception to the
                    exclusivity of
                    arbitration is that you have the right to bring an individual claim against LEVL in a small-claims
                    court of
                    competent jurisdiction. But whether you choose arbitration or small-claims court, you may not under
                    any
                    circumstances commence or maintain against LEVL or its affiliates any class action, class
                    arbitration, or
                    other representative action or proceeding.</p>
                <p>By using the Site or the Service in any manner, you agree to the above arbitration agreement. In
                    doing so,
                    YOU GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend any claims between you and LEVL or its
                    affiliates
                    (except for matters that may be taken to small-claims court). YOU ALSO GIVE UP YOUR RIGHT TO
                    PARTICIPATE IN
                    A CLASS ACTION OR OTHER CLASS PROCEEDING. Your rights will be determined by a NEUTRAL ARBITRATOR,
                    NOT A
                    JUDGE OR JURY. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant
                    any relief
                    that a court can, but you should note that arbitration proceedings are usually simpler and more
                    streamlined
                    than trials and other judicial proceedings. Decisions by the arbitrator are enforceable in court and
                    may be
                    overturned by a court only for very limited reasons.</p>
                <p>Any proceeding to enforce this arbitration agreement, including any proceeding to confirm, modify, or
                    vacate an arbitration award, may be commenced in any court of competent jurisdiction. In the event
                    that this
                    arbitration agreement is for any reason held to be unenforceable, any litigation against LEVL
                    (except for
                    small-claims court actions) may be commenced only in the federal or state courts located in New York
                    County,
                    New York. You hereby irrevocably consent to the jurisdiction of those courts for such purposes and
                    you
                    irrevocably waive any right to a trial by jury.</p>
                <p>This Agreement, and any dispute between you and LEVL, shall be governed by the laws of the state of
                    New
                    York without regard to principles of conflicts of law, provided that this arbitration agreement
                    shall be
                    governed by the Federal Arbitration Act.</p>
                <p>INTERPRETATION; SEVERABILITY; WAIVER; REMEDIES: Headings are for convenience only and shall not be
                    used to construe the terms of this Agreement. If any term of this Agreement is found invalid or
                    unenforceable by any court of competent jurisdiction, that term will be severed from this Agreement.
                    No
                    failure or delay by LEVL in exercising any right hereunder will waive any further exercise of that
                    right.
                    LEVL’s rights and remedies hereunder are cumulative and not exclusive.</p>
                <p>SUCCESSORS; ASSIGNMENT; NO THIRD PARTY BENEFICIARIES: This Agreement is binding upon and shall inure
                    to the benefit of both parties and their respective successors, heirs, executors, administrators,
                    personal
                    representatives, and permitted assigns. You may not assign this Agreement without LEVL’s prior
                    written
                    consent. No third party shall have any rights hereunder.</p>
                <p>NOTICES: You consent to receive all communications including notices, agreements, disclosures, or
                    other information from LEVL electronically. LEVL may provide all such communications by email or by
                    posting
                    them on the LEVL Service. For support-related inquiries, you may send an email
                    to info@LEVLmethod.com or the following address:</p>
                <p>LEVL. 792 Columbus Ave, Suite 800R, New York, New York 10025 Attention: Legal Department</p>
                <p>Nothing herein shall limit LEVL’s right to object to subpoenas, claims, or other demands.</p>
                <p>MODIFICATION: This Agreement may not be modified except by a revised Terms of Service posted by LEVL
                    on the LEVL Site or a written amendment signed by an authorized representative of LEVL. A revised
                    Terms of
                    Service will be effective as of the date it is posted on the LEVL Site.</p>
                <p>ENTIRE AGREEMENT: This Agreement incorporates the following documents by reference:</p>
                <b>Privacy Policy</b>
                <p>This Agreement constitutes the entire understanding between LEVL and you concerning the subject
                    matter
                    hereof and supersedes all prior agreements and understandings regarding the same.</p>
            </div>

        </section>
    )
}
