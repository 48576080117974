import React, { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import save_icon from '../../assets/images/saved/save_icon.svg';
import TrainingCartLIst from '../../components/cartTraining/trainingCartLIst';
import { withAuthUser } from '../../hocs/withAuthUser';
import { getSavedWorkouts } from '../../services/api/workoutsService/workoutsService';
import { errorHandler } from '../../components/toastify/redux/notifyOperations';

const Saved = ({ t }) => {
    const dispatch = useDispatch()
    const [workouts, setWorkouts] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [reload, setReload] = useState(true)

    useEffect(() => {
       if (reload) {
           getSavedWorkouts().then(res => {
               setWorkouts(res.items)
           }).catch(error => dispatch(errorHandler(error))).finally(() => {
               setReload(false)
               setIsLoading(false)
           })
       }
    }, [reload]);

    return (
        <div className='content-container'>
            <div className='saved'>
                <div className='saved__header'>
                    <div className='saved__header-title-container'>
                        <img src={save_icon} alt={'save_icon'} />
                        <h2>{t('plan_saved_page_title')}</h2>
                    </div>
                </div>
                <TrainingCartLIst setReload={setReload} isSavedLoading={isLoading} listItems={workouts} />
            </div>
        </div>
    );

};
export default withAuthUser(withTranslation()(Saved));
