import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {withTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigate, useParams} from 'react-router-dom';

import {Button, Input} from '../../../../components/ui-core';
import {resetPasswordSchema} from './resetPassword.schema';

import './styles.scss';
import {useDispatch, useSelector} from 'react-redux';
import routerBook from '../../../../routes/routerBook';
import {requestResetUserPassword} from "../../redux/authenticationOperations";
import { withDefaultUser } from "../../../../hocs/withDefaultUser";
import { errorHandler, successHandler } from '../../../../components/toastify/redux/notifyOperations';

const ResetPassword = ({t}) => {
    const {
        handleSubmit,
        register,
        formState: {errors, isValid},
    } = useForm({
        mode: 'onChange',
        defaultValues: {},
        resolver: yupResolver(resetPasswordSchema),
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();

    const { code } = useSelector(state => state.authState);

    useEffect(() => {
        if (!code) {
            navigate(routerBook.checkCode.replace(':id', id));
        }
    }, [id, navigate, code]);

    const handleFormSubmit = data => {
        dispatch(requestResetUserPassword({...data, id, code}, navigate, () => {
        }, ({message}) => {
            dispatch(errorHandler(message))
        }));
    };

    return (
        <div className="reset-page">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <span className="reset-page__title">{t('reset_title')}</span>
                <Input
                    inputChangeOptions={register('password')}
                    errors={errors?.password?.message}
                    options={{
                        placeholderText: `${t('reset_password_placeholder')}`,
                        placeholderIcon: 'password',
                        type: 'password',
                    }}
                />
                <Input
                    inputChangeOptions={register('confirmPassword')}
                    errors={errors?.confirmPassword?.message}
                    options={{
                        placeholderText: `${t('reset_confirm_password_placeholder')}`,
                        placeholderIcon: 'password',
                        type: 'password',
                    }}
                />
                <Button
                    disabled={!isValid}
                    options={{text: `${t('reset_button')}`, type: 'submit'}}
                />
            </form>
        </div>
    );
};
export default withDefaultUser(withTranslation()(ResetPassword));
