import image from '../../../../assets/images/paymentPage/image.svg';

export const defaultConfig = [
    {
        name: 'Aimee S.',
        city: ' New York, NY',
        comment: "Thank You!!This program is awesome!! I've tried many fitness classes and normally quit after a few weeks. The plan you created for me was the right level of difficulty - I was able to push past my normal stopping point. I love that i can now subtlety brag that I workout 5x a week!",
        image: image,
    },
    {
        name: 'Phyllis M.',
        city: ' Denver, CO',
        comment: "I love the variety of workouts, it really helps me recharge. It is nice and refreshing to sometimes see the beach when working out.",
        image: image,
    },
    {
        name: 'Joy V.',
        city: ' Los Angeles, CA',
        comment: "Once I hit my 30's it was hard for me to maintain my ideal weight. So I normally yo yo and only workout when I feel like I need to. You changed this for me. Your workouts are quick and effective so i knock them out and get on with my day. I love it.",
        image: image,
    },
    {
        name: 'Ally M.',
        city: ' Madison, WI',
        comment: "It's really cool seeing your plan adjust as you progress. You never wonder about what you should be doing next, it has that figured out for you. You just sit back and next thing you know you see your waistline shrink.",
        image: image,
    },
];
