import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import HeaderNavigationPanel from './headerNavigationPanel/headerNavigationPanel';
import Footer from '../layout/footer/footer';

import './styles.scss';
import routerBook from '../../routes/routerBook';
import { useSelector } from 'react-redux';

const Layout = () => {
    const { pathname } = useLocation();
    const { data } = useSelector(store => store.authState);
    const { id } = useParams();

    const authBackground = [routerBook.login, routerBook.registration, routerBook.forgotPassword, routerBook.resetPassword, routerBook.checkCode];

    const isAuthBackground = useMemo(() => {
        return authBackground.map(el => el.replace(':id', id)).includes(pathname);
    }, [pathname, authBackground]);

    return (
        <>
            {(!data || data?.isUserSubscribed || pathname === routerBook.question || pathname === routerBook.paymentPage) &&
                <HeaderNavigationPanel />}
            <main className={`main ${isAuthBackground ? 'auth_main' : ''}`}>
                <Outlet />
            </main>
            {(!data || data?.isUserSubscribed || pathname === routerBook.question || pathname === routerBook.paymentPage) &&
                <Footer />}
        </>
    );
};
export default Layout;
