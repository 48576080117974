import React from 'react';
import { Link } from 'react-router-dom';
import routerBook from '../../../../routes/routerBook';
import { withTranslation } from 'react-i18next';
import { withAuthUser } from '../../../../hocs/withAuthUser';
import logOut from '../../../../assets/images/profile/logOut.svg';

const AccountMenu = ({ t }) => (
    <>
        <ul className='profile__menu'>
            <li className='profile__menu-item'>
                <Link to={routerBook.aboutMe}>{t('profile_menu_about')}</Link>
            </li>
            <li className='profile__menu-item'>
                <Link to={routerBook.account}>{t('profile_menu_account')}</Link>
            </li>
            <li className='profile__menu-item'>
                <Link to={routerBook.device}>Activity Tracker</Link>
            </li>
            <li className='profile__menu-item'>
                <Link to={routerBook.contactUs}>{t('profile_menu_contact')}</Link>
            </li>
        </ul>
        <Link className={'profile__menu__button'} to={routerBook.main}>
            <p>{t('profile_logout')}</p>
            <img src={logOut} alt='logOut' />
        </Link>
    </>
);
export default withAuthUser(withTranslation()(AccountMenu));
