import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { getLocalAccessToken } from '../services/api/tokenService';
import { requestUserData } from '../pages/authentication/redux/authenticationOperations';
import routerBook from '../routes/routerBook';
import { Loader } from '../components/loader/loader';

export const withAuthUser = Component => {
    return props => {
        const location = useLocation();
        const { data, isLoading } = useSelector(store => store.authState);
        const dispatch = useDispatch();

        console.log(data)

        useEffect(() => {
            if (!data && getLocalAccessToken()) {
                dispatch(requestUserData());
            }
        }, [data]);

        const isQuizPassed = useMemo(() => {
            const _isQuizPassed = data?.quizPassed || data?.userAnswers?.length;
            if (_isQuizPassed) {
                localStorage.removeItem('isQuestionSkipped');
            }
            return _isQuizPassed;
        }, [data]);

        const isQuestionSkipped = useMemo(() => {
            return localStorage['isQuestionSkipped'] === 'true';
        }, []);

        if (data &&
            !isQuizPassed &&
            !isQuestionSkipped &&
            !data.planId &&
            (location.pathname !== routerBook.question)
        ) return <Navigate to={routerBook.question} />;

        if (data &&
            isQuizPassed &&
            (location.pathname === routerBook.question) &&
            (location.pathname !== routerBook.main)
        ) return <Navigate to={routerBook.dashboard} />;

        if (data &&
            !data.isUserSubscribed &&
            (isQuizPassed || isQuestionSkipped) &&
            (location.pathname !== routerBook.paymentPage)
        ) return <Navigate to={routerBook.paymentPage} />;

        if (data &&
            data.isUserSubscribed &&
            (location.pathname === routerBook.paymentPage)
        ) return <Navigate to={routerBook.dashboard} />;

        if (data &&
            !data.isUserSubscribed &&
            (location.pathname !== routerBook.noSubscription) &&
            (location.pathname !== routerBook.account) &&
            (isQuizPassed || isQuestionSkipped) &&
            (location.pathname !== routerBook.question) &&
            (location.pathname !== routerBook.paymentPage)
        ) return <Navigate to={routerBook.noSubscription} />;

        if (data) return <Component {...props} />;

        if (isLoading || getLocalAccessToken()) {
            const [main] = document.getElementsByClassName('main');

            if (main) main.style.zIndex = 2;
        } else {
            const [main] = document.getElementsByClassName('main');

            if (main) main.style.zIndex = 2;
        }

        if (isLoading || getLocalAccessToken()) return <Loader />;

        return <Navigate to={routerBook.main} />;
    };
};
