const routerBook = Object.freeze({
    main: '/',
    default: '*',

    // General routes
    howItWorks: '/how-it-works',
    contact: '/contact',
    privacyPolicy: '/privacy-policy',
    termsOfService: '/terms-of-service',

    // auth
    login: '/login',
    registration: '/registration',
    forgotPassword: '/forgot-password',
    checkCode: '/forgot-password/check-code/:id',
    resetPassword: '/forgot-password/reset/:id',

    //dashboard routes
    dashboard: '/dashboard',
    noSubscription: '/dashboard/no-sub',
    myPlan: '/dashboard/my-plan',
    viewPlan: '/dashboard/plan-view',
    workouts: '/dashboard/workouts',
    filteredWorkouts: '/dashboard/workouts/:bodyPart',
    workoutsInit: '/dashboard/workouts/?id',
    challengesItem: '/dashboard/challenge',
    challenges: '/dashboard/challenges',
    saved: '/dashboard/saved',
    paymentPage: '/dashboard/payment-page',
    question: '/dashboard/question',

    //dashboard profile routes
    profile: '/dashboard/profile',
    aboutMe: '/dashboard/profile/about-me',
    account: '/dashboard/profile/account',
    contactUs: '/dashboard/profile/contact-us',
    device: '/dashboard/profile/device'
});
export default routerBook;
