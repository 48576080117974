import React, {useEffect, useState} from 'react';
import OptionsFilter from './component/optionsFilter/optionsFilter';
import './styles.scss';
import TrainingCartLIst from '../../components/cartTraining/trainingCartLIst';
import cloсk from '../../assets/images/workouts/cloсk.svg';
import {withTranslation} from 'react-i18next';
import {getWorkoutsList} from "./redux/workoutsOperations";
import {useDispatch} from "react-redux";
import { withAuthUser } from "../../hocs/withAuthUser";
import { useParams } from 'react-router-dom';

const Workouts = ({t}) => {
    const dispatch = useDispatch()
    const {bodyPart} = useParams()
    const [difficulty, setDifficulty] = useState('')
    const [duration, setDuration] = useState('0-99999999')
    const [reload, setReload] = useState(false)

    useEffect(() => {
        const options = {
            bodyPart: bodyPart && bodyPart.toUpperCase(),
            difficulty,
            lengthFrom: duration?.split('-')[0],
            lengthTo: duration?.split('-')[1]
        }

        Object.keys(options).forEach(key => {
            if (!options[key]) delete options[key]
        })

        dispatch(getWorkoutsList(0, 999, options))
    }, [bodyPart, dispatch, difficulty, duration])

    useEffect(() => {
        if (reload) {
            const options = {
                bodyPart: bodyPart && bodyPart.toUpperCase(),
                difficulty,
                lengthFrom: duration?.split('-')[0],
                lengthTo: duration?.split('-')[1]
            }

            Object.keys(options).forEach(key => {
                if (!options[key]) delete options[key]
            })

            dispatch(getWorkoutsList(0, 999, options))
            setReload(false)
        }
    }, [reload])

    return (
        <div className={'content-container'}>
            <div className="workouts">
                <div className="workouts__header">
                    <div className="workouts__header-title-container">
                        <img src={cloсk} alt={''}/>
                        <h2>Workouts</h2>
                    </div>
                    <p>Find your best results with balanced workouts designed to motivate and help you achieve your goals.</p>
                </div>
                <OptionsFilter
                    difficult={difficulty}
                    setDifficult={setDifficulty}
                    bodyPart={bodyPart || ''}
                    duration={duration}
                    setDuration={setDuration}
                />
                <TrainingCartLIst setReload={setReload}/>
            </div>
        </div>
    );
};
export default withAuthUser(withTranslation()(Workouts));
