import { post, get } from '../apiService';

export const challengesList = (offset, limit = 10) => {
    return get(`/plan/for-user?offset=${offset}&limit=${limit}`);
};
export const weakChallengeList = (id) => {
    return get(`/plan/${id}/view-weeks`);
};
export const joinToPlan = (id) => {
    return post(`/plan/${id}/join`);
};
