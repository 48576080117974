import React, { useEffect, useState } from 'react';
import play from '../../assets/images/cartTraining/play.svg';
import './styles.scss';
import defaultPlaceholderVideo from '../../assets/images/cartTraining/defaultPlaceholderVideo.jpg';
import clock from '../../assets/images/cartTraining/clock.svg';
import { useDispatch, useSelector } from 'react-redux';
import { PlayerModal } from '../../pages/workouts/player/playerModal';
import { fromSecondsToMinutes } from '../../utils/globalUtils';
import { addWorkoutToSaved, removeWorkoutFromSaved } from '../../services/api/workoutsService/workoutsService';
import { errorHandler } from '../toastify/redux/notifyOperations';

const TrainingCartLIst = ({ listItems = null, isSavedLoading, setReload }) => {
    const { items, count, isLoading } = useSelector(store => store.workouts);
    const dispatch = useDispatch();

    const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
    const [selectedWorkout, setSelectedWorkout] = useState(false);

    const _handleOpenTraining = (link, title, parts) => {
        setIsPlayerModalOpen(true);
        setSelectedWorkout({
            link,
            title,
            parts,
        });
    };

    const favoriteButtonHandler = (id, isAdded = true) => {
        if (isAdded) {
            removeWorkoutFromSaved(id).then(response => {
                setReload(true)
            }).catch(error => dispatch(errorHandler(error)));
        } else {
            addWorkoutToSaved(id).then(res => {
                setReload(true)
            }).catch(error => dispatch(errorHandler(error)));
        }

    };

    const getListTraining = () => {
        if (listItems) {
            if (isSavedLoading) return <></>;

            if (listItems?.length) return listItems?.map(({ video, title, _id }) => (
                (
                    <div
                        className='cart-raining__item'
                        key={_id + 'getListTraining'}
                    >
                        <span className={'cart-raining__item__save'}
                              onClick={favoriteButtonHandler.bind(null, _id, true)}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
                                    <path style={{ fillOpacity: 1 }} strokeWidth={'3px'}
                                          d='M15 1H5a2 2 0 0 0-2 2v16l7-5 7 5V3a2 2 0 0 0-2-2z' />
                            </svg>
                        </span>
                        <div onClick={_handleOpenTraining.bind(null, video?.link, title, video?.parts)}
                             className='cart-raining__item-placeholder'>
                            <img
                                className='cart-raining__item-logo'
                                src={video?.thumbnail || defaultPlaceholderVideo}
                                alt={'logo'}
                                onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = defaultPlaceholderVideo;
                                }}
                            />
                            <img className='cart-raining__item-play' src={play} alt={'logo'} />
                        </div>
                        <div className='cart-raining__item_footer'>
                            <p className='cart-raining__item_name'>{title}</p>
                            <div className='cart-raining__item_footer-time'>
                                <div>
                                    <img src={clock} alt='clock' />
                                    <p className='cart-raining__item-time'>{fromSecondsToMinutes(video?.duration)} min</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ));

            return <span className={'cart-raining__no-found'}>Nothing found</span>;
        }

        if (isLoading) return <></>;

        if (items?.length) return items.map(({ video, title, _id, itsSavedWorkout }) => (
            (
                <div
                    className='cart-raining__item'
                    key={_id + 'getListTraining'}
                >
                        <span className={'cart-raining__item__save'}
                              onClick={favoriteButtonHandler.bind(null, _id, itsSavedWorkout)}>
                            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
                                    <path style={{
                                        fillOpacity: `${+itsSavedWorkout}`,
                                    }} strokeWidth={'3px'} d='M15 1H5a2 2 0 0 0-2 2v16l7-5 7 5V3a2 2 0 0 0-2-2z' />
                            </svg>
                        </span>
                    <div onClick={_handleOpenTraining.bind(null, video?.link, title, video?.parts)}
                         className='cart-raining__item-placeholder'>
                        <img
                            className='cart-raining__item-logo'
                            src={video?.thumbnail || defaultPlaceholderVideo}
                            alt={'logo'}
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src = defaultPlaceholderVideo;
                            }}
                        />
                        <img className='cart-raining__item-play' src={play} alt={'logo'} />
                    </div>
                    <div className='cart-raining__item_footer'>
                        <p className='cart-raining__item_name'>{title}</p>
                        <div className='cart-raining__item_footer-time'>
                            <div>
                                <img src={clock} alt='clock' />
                                <p className='cart-raining__item-time'>{fromSecondsToMinutes(video?.duration)} min</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        ));

        return <span className={'cart-raining__no-found'}>Nothing found</span>

    };

    return (
        <>
            {isPlayerModalOpen &&
                <PlayerModal preview={true} closeModal={setIsPlayerModalOpen.bind(null, false)} {...selectedWorkout} />}
            <div className='cart-raining'>{getListTraining()}</div>
        </>
    );
};

export default TrainingCartLIst;
